import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listUser';
import Datatable from '../common/datatable'
import VerPendingUserDatatable from '../common/ver-pending-user-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Swal from 'sweetalert2';

export class Ver_list_pending_user extends Component {

    constructor(props) {
        super(props);
        this.handlerState = this.handlerState.bind(this);
        this.state = {
            dataCateg: [],
            results: [],
        };
    }

    componentWillMount() {
        this.getDataUser();
    }

    handlerState(data_row,status) {
        let msg = '';
        if(status==1)
        {
            msg='¿Desea aprobar el usuario '+ data_row.original.Nombre + '?';
        }else{
            msg='¿Desea rechazar el usuario '+ data_row.original.Nombre + '?';
        }
        Swal.fire({
            title: 'Confirmar',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                let data = {
                    'id_user': data_row.original.id,
                    'status_confirm': status,
                }
                ApiService.postStateUser(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            if(status==1)
                            {
                                Swal.fire("Hecho!", "Usuario Aprobado!", "success");
                            }else{
                                Swal.fire("Hecho!", "Usuario Rechazado!", "success");
                            }
                            //toast.success("Orden Aceptada!");
                            this.setState({results: []});
                            this.getDataUser();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );
           }
         })
    }

    getDataUser(){
        var user = AuthService.getCurrentUser();
        ApiService.getPendingUsers(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    const items = res.data.map(function(row) {
                        return { 
                            id: row.id, 
                            Nombre : row.name + ' ' + row.last_name, 
                            Email : row.email,
                            Celular : row.Profile?row.Profile.cell_phonenumber:'',
                            Embajador: row.Referred && row.Referred.name?row.Referred.name + ' ' + row.Referred.last_name:'',
                        }
                     })
                this.setState({ results:items });
                this.renderResults();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerPendingUserDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={this.state.results.length<=50?this.state.results.length:50}
            pagination={true}
            class="-striped -highlight"
            handler = {this.handler}
            handlerState = {this.handlerState}
        />
    }

    componentWillReceiveProps(){
        this.getDataUser()
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Lista de Referidos Pendientes" parent="Users" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                           {/*<h5>User Details</h5>*/}
                        </div>
                        <div className="card-body">
                            <div className="clearfix"></div>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                {this.renderResults()}
                                {/*<VerPendingUserDatatable
                                    multiSelectOption={true}
                                    myData={data}
                                    pageSize={10}
                                    pagination={true}
                                    class="-striped -highlight"
                                />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Ver_list_pending_user
