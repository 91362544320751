import React from 'react';
import design from '../images/digital-product/graphic-design.png';
import ebooks from '../images/digital-product/ebooks.png';
import video from '../images/digital-product/lecture-video-recorder.jpg';
import application from '../images/digital-product/application.jpg';
import wevdev from '../images/digital-product/web-dev.jpg';

const data = [
    {
        Imagen: <img src={design} style={{width:50,height:50}}/>,
        Nombre: "Graphic Design",
        Categoría: "Electronics",
        Estado: <i className="fa fa-circle font-success f-12"/>
    },
      {
        Imagen: <img src={ebooks} style={{width:50,height:50}} />,
        Nombre: "ebooks",
        Categoría: "Electronics",
        Estado: <i className="fa fa-circle font-warning f-12"/>
    },
    {
        Imagen: <img src={video} style={{width:50,height:50}} />,
        Nombre: "Recorded lectures",
        Categoría: "Electronics",
        Estado: <i className="fa fa-circle font-success f-12"/>
    },
    {
        Imagen: <img src={application} style={{width:50,height:50}} />,
        Nombre: "Application",
        Categoría: "Electronics",
        Estado: <i className="fa fa-circle font-danger f-12"/>
    },
    {
        Imagen: <img src={wevdev} style={{width:50,height:50}} />,
        Nombre: "Websites",
        Categoría: "Electronics",
        Estado:<i className="fa fa-circle font-warning f-12"/>
    },
]

export default data;