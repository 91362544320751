import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset_user from './tabset-user';

export class Ver_create_user extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Crear Usuario" parent="Users" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <Tabset_user action={'create'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Ver_create_user;
