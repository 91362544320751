import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-products';
import VerPromotionsDatatable from '../common/ver-promotions-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export class Ver_promotions extends Component {

    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.state = {
            dataCateg: [],
            results: [],
            temp: [],
        };
    }

        
    handler(data_row) {
        this.props.history.push("/edit-product/"+data_row.original.id);
        window.location.reload();
    }

    deleteProduct(data_row) {
        //console.log(data_row)
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea eliminar '+ data_row.original.Nombre + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
      }).then((result) => {
         if(result.value){
             /*let data = myData;
             data.splice(data_row.index, 1);
             this.setState({ myData: data });
             this.deleteUser(row)*/
             var user = AuthService.getCurrentUser();
             ApiService.deleteProduct(user.token,data_row.original.id).then(
                 (res) => {
                     toast.success("Producto eliminado!");
                     this.getDataCateg();
                     //this.props.history.push("/category");
                     //window.location.reload();
                 },
                 error => {
                 const resMessage =
                     (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                     error.message ||
                     error.toString();
                 }
             )
        }
      })
     }

    onChangeFilter(e) {

        this.setState({
            filter: e.target.value
        }, function () {
            console.log('');
        });

        const val = e.target.value.toLowerCase();
        //console.log(event);
        if (this.state.temp.length != 0) {
          var columns = Object.keys(this.state.temp[0]);
          //Los botones de acciones se pintan despues
          columns.splice(columns.length);
          //columns.splice(columns.length - 1);
    
          if (!columns.length)
            return;
          const rows = this.state.temp.filter(function (d) {
            for (let i = 0; i <= columns.length; i++) {
              let column = columns[i];
              if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                return true;
              }
            }
          });
          //console.log('rows',rows)
          this.setState({
            results: rows
        }, function () {
            //console.log('results',this.state.results);
            //console.log('lenght',this.state.results.length)
            this.renderResults(this.state.results.length);
        });
          /*this.Inventarios = rows;
          let articulosAlmacenes;
          articulosAlmacenes = { 'Articulos': this.Inventarios }
          this.buildpurchaseForm(articulosAlmacenes);*/
        }



        e.preventDefault();
      }

    componentWillMount() {
        //this.getDataCateg();
        this.getDataPromotions();
        //this.getDataProduct();
    }

    /*getDataCateg(){
        var user = AuthService.getCurrentUser();
        ApiService.getCategories(user.token).then(
            (res) => {
                if(res && res.status == 'success')
                {
                    this.setState({ dataCateg:res.data });
                    this.getDataProduct();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }*/

    showStatus(results){
        // Aplicamos un fix si no devuelve una imagen 
        let url_image = `${process.env.REACT_APP_URL_FILES}/images/products/`;
		for (let i = 0; i < results.length; i++){
			if( (results[i]['status'] === 'ACTIVO') ){
				results[i]['status'] = <i className="fa fa-circle font-success f-12"/>;
			}else{
				results[i]['status'] = <i className="fa fa-circle font-danger f-12"/>;
            }
		}
		return results;
	}

    getDataPromotions(){
        var user = AuthService.getCurrentUser();
        ApiService.getPromotions(user.token).then(
            (res) => {
                const items = this.showStatus(res.data).map(function(row) {
                    return { 
                        ['#']: row.id,
                        id: row.id,
                        Nombre : row.name, 
                        Porcentaje : row.percent,
                        Estado : row.status,
                        data : row,
                    }
                 })
                /*const items = res.data.map(function(row) {
                    return { id: row.id, Nombre : row.name, Precio : row.price, Estado : row.status }
                 })*/
                this.setState({ results:items });
                this.setState({ temp:items });
                //console.log(this.state.results)
                /*console.log(res);
                this.setState({ results:res.data });*/
                this.renderResults(this.state.results.length);
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    /*getDataProduct(){
        var user = AuthService.getCurrentUser();
        ApiService.getProducts(user.token).then(
            (res) => {
                const items = this.showStatus(res.data,this.state.dataCateg).map(function(row) {
                    let price = row.price;
                    if(!price)
                    {
                        if(row.Children2)
                        {
                            price = row.Children2.price;
                        }else{
                            price = '0.00';
                        }
                    }
                    return { 
                        ['#']: row.id,
                        Imagen: row.background_url,
                        id: row.id, 
                        Nombre : row.name, 
                        Precio : price, 
                        Estado : row.status, 
                        id_category : row.id_category,
                        ['Sub-Categoría']: row.category,
                        data: row
                    }
                 })
                this.setState({ results:items });
                this.setState({ temp:items });
                //console.log(this.state.results)
                this.renderResults(this.state.results.length);
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }*/

    renderResults(length){
		return this.state.results.length === 0 
			? 'No hay datos.'
			: <VerPromotionsDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={length<=10?length:10}
            pagination={true}
            class="-striped -highlight"
            handler = {this.handler}
            deleteProduct = {this.deleteProduct}
        />
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Lista de Promociones" parent="Digital" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Lista de Promociones</h5>
                                </div>
                                <div className="card-body">
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                    <div className="col-md-6 table-filter">
                                    <Link to={`${process.env.PUBLIC_URL}/add-promotion`}  type="button" className="btn btn-secondary" data-toggle="modal" data-original-title="test" data-target="#exampleModal" style={{marginBottom: '1rem', float: 'right'}}>Añadir Promoción</Link>
                                    <input 
                                        placeholder="Escriba para filtrar todas las columnas"
                                        name="filter" 
                                        type="text" 
                                        className="form-control" 
                                        autoComplete="off"
                                        onInput={this.onChangeFilter}
                                    />
                                    </div>
                                        {this.renderResults()}
                                        {/*<VerPromotionsDatatable
                                            multiSelectOption={false}
                                            myData={data}
                                            pageSize={9}
                                            pagination={false}
                                            class="-striped -highlight"
                                        />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_promotions
