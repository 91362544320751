import React, { Component,Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../../common/dropzone'
import AuthService from "../../services/auth.service";
import ApiService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

const items = [];

export class Ver_import_pro extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        
        this.FileData = null;
        this.state = {
        }
    }

    componentWillMount() {
    }

    handleRegister(e) {
        e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0) {
            var user = AuthService.getCurrentUser();

            const formData = new FormData();
  

            console.log(this.FileData);
            if(this.FileData != null && this.idCadena!=0){
            formData.append("file", this.FileData, this.FileData.name);
            }else{

                // if(this.idCadena==0){
                //     Swal.fire('', 'Debe seleccionar una cadena.', 'error') 
                // }else if(this.FileData == null){
                //     Swal.fire('', 'Debe seleccionar un archivo excel.', 'error')
                // }
                return false

            }

            ApiService.postUserImport(user.token,formData).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        localStorage.setItem("importU", JSON.stringify(res.rowsDataReturn));
                        var cantOk = res.rowsDataReturn.rowsDataOk.length;
                        var cantError = res.rowsDataReturn.rowsDataError.length;

                        if(cantOk>0 && cantError==0){
                          toast.success("Se importaron todos los usuarios.");
                        }
                        
                        else if(cantError>0 && cantOk>0){
                          toast.success('Se logró importar '+cantOk+' usuarios'+(cantOk>1?'s':'')+' correctamente y se encontró '+cantError+' con error'+(cantError>1?'es':''));
                        }
                        
                        else if(cantError>0 && cantOk==0){
                          toast.error('No se pudo importar los usuarios.');
                        }else if(cantError==0 && cantOk==0){
                          toast.error('No se pudo importar los usuarios.');
                        }
                        
                        this.props.history.push("/import-tabla-user");
                        //window.location.reload();
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
    }
  
    onFileSelect = (event) => { 
      if (event.target.files.length > 0) {
       
      this.FileData = event.target.files[0];
      //let file = event.target.files[0];
      } else {
        this.FileData = null;
      }
    }

    render() {

        return (
            <Fragment>
                <Breadcrumb title="Importar Usuarios" parent="Digital" />
                <div className="container-fluid">
                    <Form
                        onSubmit={this.handleRegister} 
                        encType="multipart/form-data" 
                        method="POST"
                        ref={c => {this.form = c;
                    }}>
                    <div className="row product-adding">
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-header">
                                    <h5></h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation prod-block-right">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0">Importar Excel</label>
                                            <Input 
                                                name="url" 
                                                type="file" 
                                                className="form-control" 
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                onChange={(e) => this.onFileSelect(e)}
                                            />
                                            
                                            <small>Para importar los usuarios utilice este <a href="/exportar_guia_import">archivo xlsx</a> para usar esta funcionalidad. <a href="/exportar_guia_import">Descargar formato excel</a></small>
                                        </div>
                                    </div>
                                    <button 
                                        className="btn btn-primary"  
                                        disabled={this.state.loading}
                                        >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Importar</span>
                                    </button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                    />
                    </Form>
                                       
                </div>
            </Fragment>
        )
    }
}

export default Ver_import_pro
