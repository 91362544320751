import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';

// Components
import Dashboard from './components/dashboard';

// Products physical
import Category from './components/products/physical/category';
import Sub_category from './components/products/physical/sub-category';
import Product_list from './components/products/physical/product-list';
import Add_product from './components/products/physical/add-product';
import Product_detail from './components/products/physical/product-detail';

//Product Digital
import Digital_category from './components/products/digital/digital-category';
import Digital_sub_category from './components/products/digital/digital-sub-category';
import Digital_pro_list from './components/products/digital/digital-pro-list';
import Digital_add_pro from './components/products/digital/digital-add-pro';

//Verduras Product
import Ver_category from './components/ver_products/ver-category';
import Ver_sub_category from './components/ver_products/ver-sub-category';
import Ver_pro_list from './components/ver_products/ver-pro-list';
import Ver_add_pro from './components/ver_products/ver-add-pro';
import Ver_import_pro from './components/ver_products/import/ver-import-pro';
import Ver_import_tabla_pro from './components/ver_products/import/ver-import-tabla-pro';
import Ver_edit_pro from './components/ver_products/ver-edit-pro';
import Ver_list_stock from './components/ver_products/ver-list-stock';
import Ver_grammage from './components/ver_products/ver-grammage';
import Ver_banners from './components/ver_banners/ver-banners';

//Promociones
import Ver_promotions from './components/ver_promotions/ver-promotions';
import Ver_add_promotion from './components/ver_promotions/ver-add-promotion';
import Ver_edit_promotion from './components/ver_promotions/ver-edit-promotion';

//Verduras Usuarios
import Ver_create_user from './components/ver_users/ver-create-user';
import Ver_list_user from './components/ver_users/ver-list-user';
import Ver_edit_user from './components/ver_users/ver-edit-user';
import Ver_list_pending_user from './components/ver_users/ver-list-pending-user';
import Ver_log_login from './components/ver_users/ver-log-login';

//Cash Usuarios por id
import Ver_cash_user from './components/ver_users/ver-cash-user';
//Reporte de Cash por fechas
import Ver_all_cash_user from './components/ver_users/ver-all-cash-user';

//Direccion Usuarios
import Ver_address_user from './components/ver_users/ver-address-user';

import VerCreateAddress from './components/ver_users/ver-create-address';
import VerEditAddress from './components/ver_users/ver-edit-address';

//Productos hijos
import Ver_child_prod from './components/ver_products/ver-child-prod';

//Verduras Pedidos
import VerOrderList from './components/ver-orders/ver-order-list';
import VerOrderDeliveryList from './components/ver-orders/ver-order-delivery-list';

//Verduras configuraciones
import Ver_settings from './components/ver_settings/ver-settings';

//Cobranzas
import Ver_collection from './components/ver-collections/ver-collection-list';

//Cobrar Ordenes
import Ver_order_collection from './components/ver-collections/ver-order-collection';

//Verduras Horarios
import Ver_schedules from './components/ver_schedules/ver-schedules';

//Verduras Ventanas de entrega
import Ver_pickup_windows from './components/ver_pickup_windows/ver-pickup-windows';

//Sales
import Orders from './components/sales/orders';
import Transactions_sales from './components/sales/transactions-sales';
//Coupons
import ListCoupons from './components/coupons/list-coupons';
import Create_coupons from './components/coupons/create-coupons';

//Solicitud de cambios
import Ver_list_user_change from './components/ver-change-requests/ver-list-user-change';
import Ver_list_address_change from './components/ver-change-requests/ver-list-address-change';

//Pages
import ListPages from './components/pages/list-page';
import Create_page from './components/pages/create-page';
import Media from './components/media/media';
import List_menu from './components/menus/list-menu';
import Create_menu from './components/menus/create-menu';
import List_user from './components/users/list-user';
import Create_user from './components/users/create-user';
import List_vendors from './components/vendors/list-vendors';
import Create_vendors from './components/vendors/create.vendors';
import Translations from './components/localization/translations';
import Rates from './components/localization/rates';
import Taxes from './components/localization/taxes';
import Profile from './components/settings/profile';
import Reports from './components/reports/report';
import Invoice from './components/invoice';
import Datatable from './components/common/datatable';
import Login from './components/auth/login';
import VerLogin from './components/auth/ver-login';
import Charges from './components/charges'

// Reporte de Ventas por meses
import VerReportOrderList from './components/ver-reports/ver-report-order-list';
import { VerReportSkuForMonth } from './components/ver-reports/ver-report-sku-for-month';
import { VerReportSkuForMonthAndCustomer } from './components/ver-reports/ver-report-sku-for-month-customer';
import { VerReportSkuForMonthAndCustomers } from './components/ver-reports/ver-report-sku-for-month-customers';
import { VerReportCustomersList } from './components/ver-reports/ver-report-customers-list';

//import VerOrderDeliveryList from './components/ver-orders/ver-order-delivery-list';
class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        <ScrollContext>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={VerLogin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={VerLogin}
            />

            <App>
              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={Dashboard}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/category`}
                component={Category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/sub-category`}
                component={Sub_category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/product-list`}
                component={Product_list}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/product-detail`}
                component={Product_detail}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/physical/add-product`}
                component={Add_product}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/products/digital/digital-category`}
                component={Digital_category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`}
                component={Digital_sub_category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`}
                component={Digital_pro_list}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`}
                component={Digital_add_pro}
              />

              {/*Proyecto vegetales*/}
              <Route
                path={`${process.env.PUBLIC_URL}/category`}
                component={Ver_category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/sub-category`}
                component={Ver_sub_category}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/product-list`}
                component={Ver_pro_list}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/add-product`}
                component={Ver_add_pro}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/product-stock`}
                component={Ver_list_stock}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/import-user`}
                component={Ver_import_pro}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/import-tabla-user`}
                component={Ver_import_tabla_pro}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/edit-product/:id`}
                component={Ver_edit_pro}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/create-user`}
                component={Ver_create_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/list-user`}
                component={Ver_list_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/edit-user/:id`}
                component={Ver_edit_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/orders`}
                component={VerOrderList}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/orders-delivery`}
                component={VerOrderDeliveryList}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/list-pending-user`}
                component={Ver_list_pending_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pickup-windows`}
                component={Ver_pickup_windows}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/schedules`}
                component={Ver_schedules}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/cash-user/:id`}
                component={Ver_cash_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/change-user-request`}
                component={Ver_list_user_change}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/change-address-request`}
                component={Ver_list_address_change}
              />
              <Switch>
                <Route
                  path={`${process.env.PUBLIC_URL}/user/:id/address/new`}
                  component={VerCreateAddress}
                  exact
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/user/:userId/address/:addressId`}
                  component={VerEditAddress}
                  exact
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/address-user/:id`}
                  component={Ver_address_user}
                  exact
                />
              </Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/child/:id`}
                component={Ver_child_prod}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/grammage`}
                component={Ver_grammage}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/banners`}
                component={Ver_banners}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/promotions`}
                component={Ver_promotions}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/add-promotion`}
                component={Ver_add_promotion}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/edit-promotion/:id`}
                component={Ver_edit_promotion}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/settings`}
                component={Ver_settings}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/log-login`}
                component={Ver_log_login}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/collections`}
                component={Ver_collection}
              />  
              
              <Route
                path={`${process.env.PUBLIC_URL}/charges`}
                component={Charges}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/order-collections/:id`}
                component={Ver_order_collection}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/sales/orders`}
                component={Orders}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/sales/transactions`}
                component={Transactions_sales}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/coupons/list-coupons`}
                component={ListCoupons}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/coupons/create-coupons`}
                component={Create_coupons}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/pages/list-page`}
                component={ListPages}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/create-page`}
                component={Create_page}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/media`}
                component={Media}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/menus/list-menu`}
                component={List_menu}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/menus/create-menu`}
                component={Create_menu}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/users/list-user`}
                component={List_user}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/users/create-user`}
                component={Create_user}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/vendors/list_vendors`}
                component={List_vendors}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/vendors/create-vendors`}
                component={Create_vendors}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/localization/transactions`}
                component={Translations}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/localization/currency-rates`}
                component={Rates}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/localization/taxes`}
                component={Taxes}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/reports/report`}
                component={Reports}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/settings/profile`}
                component={Profile}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/invoice`}
                component={Invoice}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/data-table`}
                component={Datatable}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/monthly-sale`}
                component={VerReportOrderList}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/sku-monthly-sale`}
                component={VerReportSkuForMonth}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/sku-monthly-sale-customer`}
                component={VerReportSkuForMonthAndCustomer}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/sku-monthly-sale-customer-list`}
                component={VerReportSkuForMonthAndCustomers}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/all-cash-user/`}
                component={Ver_all_cash_user}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/customers-list`}
                component={VerReportCustomersList}
              />

            </App>
          </Switch>
        </ScrollContext>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
