import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset_user from './tabset-user';

export class Ver_edit_user extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Editar Usuario" parent="Users" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  {/*<h5> Editar Usuario</h5>*/}
                </div>
                <div className="card-body">
                  <Tabset_user
                    action={'update'}
                    id_user={this.props.match.params.id}
                    history={this.props.history}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Ver_edit_user;
