import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../common/dropzone'
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

// image import
import primera_imagen from '../../assets/images/example/primera_imagen.png'
import segunda_imagen from '../../assets/images/example/segunda_imagen.png'
import logo_producto from '../../assets/images/example/logo_producto.png'
import primera_caracteristica from '../../assets/images/example/primera_caracteristica.png'
import segunda_caracteristica from '../../assets/images/example/segunda_caracteristica.png'
import tercera_caracteristica from '../../assets/images/example/tercera_caracteristica.png'

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

const items = [];

export class VerSettings extends Component {
    selectRef = null;
    constructor(props) {
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeMontoMinimo = this.onChangeMontoMinimo.bind(this);
        this.onChangeMontoSinDelivery = this.onChangeMontoSinDelivery.bind(this);
        this.onChangeIntentosLogin = this.onChangeIntentosLogin.bind(this);
        this.onChangeNewPercentCashback = this.onChangeNewPercentCashback.bind(this);
        
        this.state = {
            minimum_amount: 0,
            maximum_amount_no_delivery : 0,
            maximum_login_attemps : 0,
            is_checked : false,
            percent_cashback : 0
        }
    }

    componentWillMount() {
        this.getdataSettings();
    }

    onChangeNewPercentCashback(e) {
      this.setState({
        percent_cashback: e.target.value
      });
    }

    onChangeMontoMinimo(e) {
        this.setState({
            minimum_amount: e.target.value
        });
      }

    onChangeMontoSinDelivery(e) {
        this.setState({
            maximum_amount_no_delivery: e.target.value
        });
      }

    onChangeIntentosLogin(e) {
        this.setState({
          maximum_login_attemps: e.target.value
        });
      }
    
    
    
 

    getdataSettings(){
        var user = AuthService.getCurrentUser();
        ApiService.getSettings(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log(res.data);
                    let data = res.data;
                    for (let i = 0; i < data.length; i++) {
                      const element = data[i];
                      let option = element.option_name;
                      let value = element.option_value;
                      if(option == 'minimum_amount')
                      {
                        this.setState({
                          minimum_amount: value
                        });
                      }

                      if(option == 'maximum_amount_no_delivery')
                      {
                        this.setState({
                          maximum_amount_no_delivery: value
                        });
                      }

                      if(option == 'maximum_login_attemps')
                      {
                        this.setState({
                          maximum_login_attemps: value
                        });
                      }

                      if(option == 'percent_cashback')
                      {
                        this.setState({
                          percent_cashback: value
                        });
                      }

                      if(option == 'is_open')
                      {
                        if(value == 'SI')
                        {
                          this.setState(
                            { 
                              is_checked: true
                            },
                            () => {});
                        }
                      }
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }




      handleUpdate(e) {
        e.preventDefault();
    
        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            this.setState({
                message: "",
                loading: true
              });
            var user = AuthService.getCurrentUser();
            let data_user = []

            let data = {}

            data = {
              'name': 'minimum_amount',
              'value': this.state.minimum_amount,
            }

            data_user.push(data)

            data = {
              'name': 'maximum_amount_no_delivery',
              'value': this.state.maximum_amount_no_delivery,
            }

            data_user.push(data)

            data = {
              'name': 'maximum_login_attemps',
              'value': this.state.maximum_login_attemps,
            }

            data_user.push(data)

            data = {
              'name': 'percent_cashback',
              'value': this.state.percent_cashback,
            }

            data_user.push(data)

            var is_open = 'SI'
            if(this.state.is_checked == false){
              is_open = 'NO';
            }

            data = {
              'name': 'is_open',
              'value': is_open,
            }

            data_user.push(data)

            let json_data = {}
            json_data['data'] = data_user;

            //console.log(json_data);

            ApiService.updateSettings(user.token,json_data).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                      Swal.fire("Hecho!", 'Configuración guardada.', "success");
                      this.setState({
                        loading: false
                      });
                        //window.location.reload();
                    }else{
                        Swal.fire("Error", res.message, "error");
                        this.setState({
                            message: "",
                            loading: false
                          });
                        return true;
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }


    render() {
        const { selectedOption, selectedSubCategOption, selectedOptionGramaje1, selectedOptionGramaje2, selectedOptionGramaje3, open_example } = this.state;
        const { selectedTemplateOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Configuración" parent="Digital" />
                <div className="container-fluid">
                    <Form
                        onSubmit={this.handleUpdate} 
                        encType="multipart/form-data" 
                        method="POST"
                        ref={c => {this.form = c;
                    }}>
                    <div className="row product-adding">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Monto Mínimo de Compra</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.minimum_amount} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeMontoMinimo}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Cantidad de intentos de login</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.maximum_login_attemps} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeIntentosLogin}
                                                validations={[required]}
                                            />
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5></h5>
                                </div>
                                <div className="card-body">
                                    <br/>
                                    <div className="digital-add needs-validation prod-block-right">

                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Monto Sin Delivery</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.maximum_amount_no_delivery} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeMontoSinDelivery}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> % de cashback</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.percent_cashback} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeNewPercentCashback}
                                                validations={[required]}
                                            />
                                        </div>

                                        <div className="form-group" style={{marginTop: '3rem'}}>
                                          <label className="d-block">
                                              <input 
                                              id="chk_open" 
                                              className="checkbox_animated"
                                              type="checkbox" 
                                              onChange={checked => this.setState({ is_checked: !this.state.is_checked })} checked={this.state.is_checked} 
                                              />
                                              ¿Hay Atención?
                                          </label>
                                        </div>
                                    </div>
                                    <button 
                                        className="btn btn-primary"  
                                        disabled={this.state.loading}
                                        >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                    />
                    </Form>                  
                </div>
            </Fragment>
        )
    }
}

export default VerSettings
