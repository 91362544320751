import axios from "axios";

const API_URL = process.env.REACT_APP_URL + "897623u9jkjdsjkdjsk/";

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "login", {
        email,
        password
      })
      .then(response => {
        //console.log(response.data.token);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  getCash(access_token){
    return axios
      .post(API_URL + "users/cash",{},{
        headers: {
          'Authorization': `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        }
      })
      .then(response => {
        return response.data;
      })
      .catch((error) => {
        console.error(error)
      })
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();