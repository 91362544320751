import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../common/dropzone'
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

// image import
import primera_imagen from '../../assets/images/example/primera_imagen.png'
import segunda_imagen from '../../assets/images/example/segunda_imagen.png'
import logo_producto from '../../assets/images/example/logo_producto.png'
import primera_caracteristica from '../../assets/images/example/primera_caracteristica.png'
import segunda_caracteristica from '../../assets/images/example/segunda_caracteristica.png'
import tercera_caracteristica from '../../assets/images/example/tercera_caracteristica.png'

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

const items = [];
var pucharse_selected = [];
export class Ver_add_promotion extends Component {
    selectRef = null;
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangePerncent = this.onChangePerncent.bind(this);
        
        this.state = {
            name: "",
            percent: "",
            errors: {},
            presentaciones: [],
        }
    }

    componentWillMount() {
        this.getDataPresentaciones();
        pucharse_selected = [];
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
      }

      onChangePerncent(e) {
        this.setState({
            percent: e.target.value
        });
      }

    getDataPresentaciones(){
        var user = AuthService.getCurrentUser();
        ApiService.getPresentaciones(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    console.log(res.data);
                    this.setState({ presentaciones: res.data });
                    this.renderResults(this.state.presentaciones.length);
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //console.log(value)
        var item_check =  JSON.parse(event.target.value);
        //console.log(item_check.id);
        if(value == true)
        {
            pucharse_selected.push(item_check);
        }else{
            for (let i = 0; i < pucharse_selected.length; i++)
            {
                if(pucharse_selected[i] == item_check)
                {
                    pucharse_selected.splice(i, 1);
                }
            }
        }
        //console.log(pucharse_selected);
      }

    renderResults(length){
		return < > {this.state.presentaciones.map((field, index) =>
            <label key={index} className="d-block">
                <input className="checkbox_animated" id="chk-ani2" type="checkbox" value={field.id} onChange={this.handleInputChange}/>
                {field.name} - {field.size}
            </label>
          )}</ >
    }


      handleRegister(e) {
        e.preventDefault();
    
        this.form.validateAll();

        if(pucharse_selected.length == 0)
        {
            Swal.fire("Debe seleccionar al menos una presentación.");
            return true;
        }
    
        if (this.checkBtn.context._errors.length === 0) {
            this.setState({
                message: "",
                loading: true
              });
            var user = AuthService.getCurrentUser();
            let data_user = {}
            data_user = {
                'name': this.state.name,
                'percent':this.state.percent,
                "products": pucharse_selected
            }

            ApiService.postPromocion(user.token,data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        toast.success("Promoción creado!");
                        this.props.history.push("/promotions");
                        //window.location.reload();
                    }else{
                        Swal.fire("Error", res.message, "error");
                        this.setState({
                            message: "",
                            loading: false
                          });
                        return true;
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

    render() {
        const { selectedOption, selectedSubCategOption, selectedOptionGramaje1, selectedOptionGramaje2, selectedOptionGramaje3, open_example } = this.state;
        const { selectedTemplateOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Añadir Promoción" parent="Digital" />
                <div className="container-fluid">
                    <Form
                        onSubmit={this.handleRegister} 
                        encType="multipart/form-data" 
                        method="POST"
                        ref={c => {this.form = c;
                    }}>
                    <div className="row product-adding">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Nombre</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.name} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangeName}
                                                validations={[required]}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Porcentaje</label>
                                            <Input 
                                                name="name" 
                                                value={this.state.percent} 
                                                type="text" 
                                                className="form-control" 
                                                onChange={this.onChangePerncent}
                                                validations={[required]}
                                            />
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5></h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation prod-block-right">
                                    <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Presentaciones</label>
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                                {this.renderResults()}
                                            </div>
                                    </div>
                                    <div className="form-terms">
                                    
                                    </div>
                                    </div>
                                    <button 
                                        className="btn btn-primary"  
                                        disabled={this.state.loading}
                                        >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                    />
                    </Form>                  
                </div>
            </Fragment>
        )
    }
}

export default Ver_add_promotion
