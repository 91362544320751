import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerAddressUserDatatable from '../common/ver-address-user-datatable';
import Modal from 'react-responsive-modal';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Moment from 'moment';
import Swal from 'sweetalert2';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

export class Ver_address_user extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeDelivery = this.onChangeDelivery.bind(this);
    this.handlerEdit = this.handlerEdit.bind(this);

    this.deleteAddress = this.deleteAddress.bind(this);


    this.state = {
      selectedCategOption: null,
      open: false,
      name: '',
      last_name: '',
      id: '',
      amount: 0,
      errors: {},
      loading: false,
      message: '',
      results: [],
      current_credit: 0,
      delivery: 0,
      id_address: null,
    };
  }

  onChangeDelivery(e) {
    this.setState({
      delivery: e.target.value,
    });
  }

  componentWillMount() {
    var id_user = this.props.match.params.id;
    this.setState({ id: id_user });
    if (id_user) {
      this.getUser(id_user);
      this.getDataDireccion(id_user);
    }
  }

  getUser(id_user) {
    var user = AuthService.getCurrentUser();
    ApiService.getUserDetails(user.token, id_user).then(
      (res) => {
        //console.log(res)
        if (res && res.status === 'success') {
          //console.log(res.data);
          this.setState({ name: res.data.name ? res.data.name : '' });
          this.setState({
            last_name: res.data.last_name ? res.data.last_name : '',
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getDataDireccion(id_user) {
    var user = AuthService.getCurrentUser();
    ApiService.getAddressUser(user.token, id_user).then(
      (res) => {
        if (res && res.status == 'success') {
          this.setState({ results: [] });
          //console.log(res.data);
          const items = res.data.map(function (row) {
            var type_address = row.type_address ? row.type_address : '';
            var address = row.address ? row.address : '';
            var number = row.number ? row.number : '';
            return {
              Id: row.id,
              Dirección: type_address + ' ' + address + ' ' + number,
              Distrito: row.district ? row.district : '',
              Interior: row.interior ? row.interior : '',
              Delivery: row.delivery ? row.delivery : '',
              data: row,
            };
          });
          this.setState({ results: items });
          //this.setState({current_credit: res.user.Internal_accounts_summary?res.user.Internal_accounts_summary.amount_cash:0});
          this.renderResults();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  clearInputs() {
    this.setState({
      delivery: '',
    });
  }

  renderResults() {
    return this.state.results.length === 0 ? (
      ''
    ) : (
      <VerAddressUserDatatable
        multiSelectOption={false}
        myData={this.state.results}
        pageSize={
          this.state.results.length <= 10 ? this.state.results.length : 10
        }
        pagination={true}
        class="-striped -highlight"
        handler={this.handler}
        handlerEdit={this.handlerEdit}
        userId={this.state.id}
        deleteAddress = {this.deleteAddress}
      />
    );
  }

  handlerEdit(data_row) {
    //console.log(data_row)
    this.setState({
      delivery: data_row.original.data.delivery
        ? data_row.original.data.delivery
        : 0,
    });

    this.setState({
      id_address: data_row.original.data.id ? data_row.original.data.id : 0,
    });

    this.setState({ open: true });
  }

  handleUpdate(e) {
    //e.preventDefault();

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (
      this.checkBtn.context._errors.length === 0 &&
      !this.state.errors.template
    ) {
      this.onCloseModal('VaryingMdo');
      var user = AuthService.getCurrentUser();
      let data_user = {
        id_user: this.state.id,
        id_address: this.state.id_address,
        delivery: this.state.delivery,
      };

      ApiService.putAddresssDeliveryUser(user.token, data_user).then(
        (res) => {
          if (res && res.status == 'success') {
            toast.success('Delivery actualizado!');
            this.getDataDireccion(this.state.id);
            this.clearInputs();
            //this.props.history.push("/category");
            window.location.reload();
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  deleteAddress(data_row) {

    
    Swal.fire({
     title: 'Confirmar',
     text: '¿Deseas eliminar este registro?',
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33', 
     confirmButtonText: 'Confirmar',
     cancelButtonText: 'Cancelar'
  }).then((result) => {
     if(result.value){
         
         var user = AuthService.getCurrentUser();
         var id_user = this.props.match.params.id;
         
         ApiService.deleteAddress(user.token,data_row.original.data.id).then(
             (res) => {
                 toast.success("Registro eliminado!");
                 this.getDataDireccion(id_user);
             },
             error => {
             const resMessage =
                 (error.response &&
                 error.response.data &&
                 error.response.data.message) ||
                 error.message ||
                 error.toString();
             }
         )
    }
  })
 }

  

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps() {
    this.getDataDireccion(this.props.match.params.id);
  }

  render() {
    const { open } = this.state;
    const { selectedTemplateOption } = this.state;
    const { selectedCategOption } = this.state;

    return (
      <Fragment>
        <Breadcrumb
          title={`Direcciones de ${
            this.state.name + ' ' + this.state.last_name
          }`}
          parent="Digital"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="btn-popup pull-right">
                    <Link
                      to={`/user/${this.props.match.params.id}/address/new`}
                      className="btn btn-secondary"
                    >
                      Create Address
                    </Link>
                  </div>
                  <div className="btn-popup pull-right">
                    {/*<button type="button" className="btn btn-secondary" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Añadir Crédito</button>*/}
                    <Modal open={open} onClose={this.onCloseModal}>
                      <div className="modal-header">
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          Actualizar costo de envío
                        </h5>
                      </div>
                      <Form
                        id="frmCateg"
                        //onSubmit={this.state.register?this.handleUpdate:this.handleUpdate}
                        encType="multipart/form-data"
                        method="POST"
                        ref={(c) => {
                          this.form = c;
                        }}
                      >
                        <div className="modal-body">
                          <div className="form-group">
                            <label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Monto :
                            </label>
                            <Input
                              name="name"
                              value={this.state.delivery}
                              type="number"
                              className="form-control"
                              onChange={this.onChangeDelivery}
                              validations={[required]}
                            />
                            
                          </div>
                        </div>
                        <div className="modal-footer">
                          <Link
                            to={`${process.env.PUBLIC_URL}/address-user/${this.state.id}`}
                            onClick={() => this.handleUpdate()}
                          >
                            <button
                              className="btn btn-primary"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Guardar</span>
                            </button>
                          </Link>
                         
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => this.onCloseModal('VaryingMdo')}
                          >
                            Cancelar
                          </button>
                        </div>
                        {this.state.message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {this.state.message}
                            </div>
                          </div>
                        )}
                        <CheckButton
                          style={{ display: 'none' }}
                          ref={(c) => {
                            this.checkBtn = c;
                          }}
                        />
                      </Form>
                    </Modal>
                  </div>
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    {this.renderResults()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

export default Ver_address_user;
