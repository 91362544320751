import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export class VerAddressUserDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      showSwal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.myData != prevState.myData) {
      this.setState({
        myData: prevProps.myData,
      });
    }
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.myData.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      myData: updatedData,
    });
    toast.success('Successfully Deleted !');
  };

  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: '#fafafa' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const data = [...this.state.myData];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({ myData: data });
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.myData[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    const { myData } = this.state;

    const columns = [];
    var handleToUpdate = this.props.handler;
    var handlerEdit = this.props.handlerEdit;
    var handlerSeeImage = this.props.handlerSeeImage;

    var deleteAddress  =   this.props.deleteAddress;


    for (var key in myData[0]) {
      let editable = this.renderEditable;
      if (key === 'Id') {
        editable = null;
      }

      if (key === 'Dirección') {
        editable = null;
      }
      if (key === 'Distrito') {
        editable = null;
      }
      if (key === 'Interior') {
        editable = null;
      }
      if (key === 'Delivery') {
        editable = null;
      }
      if (key === 'data') {
        editable = null;
      }
      if (key === 'Monto') {
        editable = null;
      }
      if (key === 'Referencia') {
        editable = null;
      }
      if (key === 'Fecha Pago') {
        editable = null;
      }
      if (key != 'data') {
        columns.push({
          Header: <b>{this.Capitalize(key.toString())}</b>,
          accessor: key,
          Cell: editable,
          style: {
            textAlign: 'center',
          },
        });
      }
    }

    if (multiSelectOption == true) {
      columns.push({
        Header: (
          <button
            className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
            onClick={(e) => {
              if (window.confirm('Are you sure you wish to delete this item?'))
                this.handleRemoveRow();
            }}
          >
            Delete
          </button>
        ),
        id: 'delete',
        accessor: (str) => 'delete',
        sortable: false,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>
            <span>
              <input
                type="checkbox"
                name={row.original.id}
                defaultChecked={this.state.checkedValues.includes(
                  row.original.id
                )}
                onChange={(e) => this.selectRow(e, row.original.id)}
              />
            </span>
          </div>
        ),
        accessor: key,
        style: {
          textAlign: 'center',
        },
      });
    } else {
      columns.push({
        Header: <b>Acción</b>,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (row) => (
          <div>
            {/* {console.log(row.original)} */}
            {row.original.data &&
            row.original.data.type &&
            row.original.data.type == 'CHARGE' ? (
              <>
                <span onClick={() => handlerEdit(row)}>
                  <i
                    className="fa fa-ban"
                    style={{
                      width: 35,
                      fontSize: 20,
                      padding: 11,
                      color: 'red',
                    }}
                  ></i>
                </span>
                {row.original.data &&
                row.original.data.voucher_image &&
                row.original.data.voucher_image != '' ? (
                  <span onClick={() => handlerSeeImage(row)}>
                    <i
                      className="fa fa-eye"
                      style={{
                        width: 35,
                        fontSize: 20,
                        padding: 11,
                        color: 'blue',
                      }}
                    ></i>
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                <Link
                  to={`#`}
                  title="Editar Costo de Envío"
                  onClick={() => handlerEdit(row)}
                >
                  <i
                    className="fa fa-money"
                    style={{
                      width: 35,
                      fontSize: 20,
                      padding: 11,
                      color: 'rgb(48 159 212)',
                    }}
                  ></i>
                </Link>
                <Link
                  to={`/user/${this.props.userId}/address/${row.original.data.id}`}
                  title="Editar dirección"
                  //onClick={() => handlerEdit(row)}
                >
                  <i
                    className="fa fa-pencil"
                    style={{
                      width: 35,
                      fontSize: 20,
                      padding: 11,
                      color: 'rgb(48 159 212)',
                    }}
                  ></i>
                </Link>
                <Link to={`#`} title = "Eliminar"
                  onClick={() => deleteAddress(row)} >
                    <i className="fa fa-trash" style={{ width: 30, fontSize: 20, padding: 11, color: '#e4566e' }}
                    ></i>
                </Link>
              </>
            )}
          </div>
        ),
        style: {
          textAlign: 'center',
        },
        sortable: false,
      });

      columns.push({
        Header: <b>Acción</b>,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (row) => (
          <div>
            <div
              onClick={() => {
                this.props.setCharge(row.original.data);
                this.props.openModal();
              }}
            >
              <i
                className="fa fa-edit"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: 'rgb(48 159 212)',
                }}
              ></i>
            </div>
          </div>
        ),
      });
    }

    return (
      <Fragment>
        <ReactTable
          data={myData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default VerAddressUserDatatable;
