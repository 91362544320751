import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

export class VerCollectionDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.myData != prevState.myData){
            this.setState({
                myData: prevProps.myData
            })
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        console.log(cellInfo);
        return (
            <div
            style={{ backgroundColor: "#fafafa", width: "70%" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    e.target.innerHTML = e.target.innerHTML.replace('<div>', ''); 
                    e.target.innerHTML = e.target.innerHTML.replace('<br>', ''); 
                    e.target.innerHTML = e.target.innerHTML.replace('</div>', ''); 
                    
                    console.log('renderEditable onBlur cellInfo', cellInfo)
                    console.log('renderEditable onBlur this.textColumn e.target.innerHTML ',this.textColumn ,e.target.innerHTML)
                    const data = [...this.state.myData];
                    console.log('renderEditable onBlur data[cellInfo.index][cellInfo.column.id] ', data[cellInfo.index][cellInfo.column.id])
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data },
                        () => {
                            console.log('setState renderEditable onKeyDown this.textColumn ', this.textColumn, data[cellInfo.index][cellInfo.column.id] )
                            this.renderResult();
                        });
                }}
                onKeyPress={e => {
                    //console.log('renderEditable onKeyPress cellInfo', cellInfo)
                    if (e.key == 'Enter') {
                        e.target.innerHTML = e.target.innerHTML.replace('<div>', ''); 
                        e.target.innerHTML = e.target.innerHTML.replace('<br>', ''); 
                        e.target.innerHTML = e.target.innerHTML.replace('</div>', ''); 
                        
                        console.log('renderEditable onKeyPress cellInfo', cellInfo)
                        console.log('renderEditable onKeyPress this.textColumn e.target.innerHTML ',this.textColumn ,e.target.innerHTML)
                        const data = [...this.state.myData];
                        console.log('renderEditable onKeyPress data[cellInfo.index][cellInfo.column.id] ', data[cellInfo.index][cellInfo.column.id])
                        data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;//e.target.innerHTML;
                        this.setState({ myData: data },
                            () => {
                                console.log('setState renderEditable onKeyPress this.textColumn ', this.textColumn, data[cellInfo.index][cellInfo.column.id] )
                                this.renderResult();    
                            });
                    }
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    deleteUser(row){
        var user = AuthService.getCurrentUser();
        ApiService.deleteUser(user.token,row.original.id).then(
        (res) => {
            toast.success("Usuario eliminado!");
            //this.props.history.push("/category");
            //window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          /*this.setState({
            loading: false,
            message: resMessage
          });*/
        }
     )
    }

    editProduct(row){
        console.log(row);
        //window.location.reload();
    }

    renderResult(){
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state
        const columns = [];
        var handlerAcept  =   this.props.handlerAcept;
        var handlerCancel  =   this.props.handlerCancel;
        var handlerDetails  =   this.props.handlerDetails;

        let i = 0;
        for (var key in myData[0]) {
            i++
            // let editable = this.renderEditable
            // editable = null
            let editable = null;
            if(key != "id_user" && key != "address" && key != 'data' && key  != "Estado Entrega" && key  != "Total Pagado")
            {
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        accessor: key,
                        Cell: editable,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
     
            // if(key == "Total Pagado"){
            //     //console.log("Total Pagado -- ", i);
            //     editable = this.renderEditable;
            //     columns.push(
            //         {
            //             Header: <b>{this.Capitalize(key.toString())}</b>,
            //             accessor: key,
            //             Cell: editable,
            //             style: {
            //                 textAlign: 'center'
            //             }
            //         });
            // }

            if(key  == "Total Pagado"){
                //console.log('key',key);
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        //accessor: key,
                        id: 'estado',
                        //accessor: str => "estado",
                        Cell: (row) => (
                            <div>
                                {row.original['Total Pagado']}
                                <span onClick={() => handlerDetails(row)}>
                                    { <a><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11,color:'green' }}></i></a> }
                                </span>
                            </div>
                        ),
                        accessor: key,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }

            if(key  == "Estado Entrega"){
                console.log('key',key);
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        //accessor: key,
                        id: 'estado',
                        //accessor: str => "estado",
                        Cell: (row) => (
                            //<i className="fa fa-circle" aria-hidden="true"></i> + row.original['Estado Entrega']

                            <div>
                                <span onClick={() => handlerDetails(row)}>
                                    {row.original['Estado Entrega'] == "POR PREPARAR"?
                                        <i className="fa fa-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'red' }}></i>
                                        : row.original['Estado Entrega'] == "LISTO PARA ENVIO"?
                                        <i className="fa fa-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'yellow' }}></i>
                                        : row.original['Estado Entrega'] == "ENTREGADO"?
                                        <i className="fa fa-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'green' }}></i>
                                        : ''
                                    }{ row.original['Estado Entrega']}
                                </span>
                            </div>
                        
                        ),
                        accessor: key,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
        }

        if (multiSelectOption == true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } else {
            columns.push(
                {
                    Header: <b>Acción</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        row.original.Estado=='PROCESADO'?
                        <div style={{ display: 'flex' }}>
                            {/*<Link
                                to={`${process.env.PUBLIC_URL}/edit-user/${row.original.id}`}
                            >
                            <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></Link>*/}
                            <span onClick={() => handlerDetails(row)}
                            ><i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(48 159 212)' }}></i></span>
                            <span onClick={() => handlerAcept(row)}
                            ><i className="fa fa-check-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></span>
                            <span onClick={() => handlerCancel(row)}
                            ><i className="fa fa-times-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(251 15 15)' }}></i></span>
                        </div>
                        :
                        <div style={{ display: 'flex' }}>
                        <Link to={`#`} onClick={() => handlerDetails(row)} title = "Ver detalle"
                        ><i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(48 159 212)' }}></i></Link>
                        <Link
                            to={`${process.env.PUBLIC_URL}/order-collections/${row.original.data.id}`} title = "Ver pagos"
                        ><i className="fa fa-money" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(222 167 11)' }}></i></Link>
                        </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )
        }

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        )
    }

    render() {
        return (
            this.renderResult()
        )
    }
}

export default VerCollectionDatatable
