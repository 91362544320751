import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/sales-transactions';
import Datatable from '../common/datatable';
import VerOrderDatatable from '../common/ver-order-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';

import Select from 'react-select';

export class VerReportOrderList extends Component {
    constructor(props) {
        super(props);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.handlerAcept = this.handlerAcept.bind(this);
        this.handlerCancel = this.handlerCancel.bind(this);
        this.handlerRemove = this.handlerRemove.bind(this);
        this.handlerDetails = this.handlerDetails.bind(this);
        this.state = {
            open: false,
            dataCateg: [],
            results: [],
            filter: '',
            temp: [],
            detailResults: [],
            client: '',
            date: '',
            hour: '',
            id_order: null,
            total_order: null,
            address: '',
            district: '',
            interior: '',
            url_google: '',
            cash: 0,
            delivery: 0,
            discount: 0,
            selectedOption: null,
            dataListCateg: [],
            dataCateg: [],
            dataOptionSelected: null,
            dataSubCateg: 0,
            dataUtility: 0,
            dataCosts: 0,
            dataDelivery: 0,
            profitUtility: 0,
            averageTicket: 0,
            numTotalOrders: 0,
            totalCustomers: 0,
            percentageActiveCustomers: 0,
            totalOrdersAverage: 0,
        };
    }

    onChangeFilter(e) {

        this.setState({
            filter: e.target.value
        }, function () {
            console.log('');
        });

        const val = e.target.value.toLowerCase();
        //console.log(event);
        if (this.state.temp.length != 0) {
          var columns = Object.keys(this.state.temp[0]);
          //Los botones de acciones se pintan despues
          columns.splice(columns.length);
          //columns.splice(columns.length - 1);
    
          if (!columns.length)
            return;
          const rows = this.state.temp.filter(function (d) {
            for (let i = 0; i <= columns.length; i++) {
              let column = columns[i];
              if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                return true;
              }
            }
          });
          //console.log('rows',rows)
          this.setState({
            results: rows
        }, function () {
            //console.log('results',this.state.results);
            //console.log('lenght',this.state.results.length)
            this.renderResults(this.state.results.length);
        });
          
        }



        e.preventDefault();
      }

    componentWillMount() {
        //this.getDataOrders();
        this.getDataCateg();
    }

    getDataOrders(){
        var user = AuthService.getCurrentUser();
        ApiService.getOrders(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('orders',res.data)
                    const items = res.data.map(function(row) {
                        var start_time = row.hour_programmed_start?row.hour_programmed_start.toString():'';
                        var end_time = row.hour_programmed_end?row.hour_programmed_end.toString():'';
                        var nameAmbassador = row.Referred && row.Referred.name ? row.Referred.name : '-';
                        var lastnameAmbassador = row.Referred && row.Referred.last_name ? row.Referred.last_name : '-';
                        var totalOrder = row.totalOrder;
                        return { 
                            Id: row.id, 
                            id_user: row.id_user,
                            Usuario : row.User.name + ' ' + row.User.last_name, 
                            Embajador : nameAmbassador + ' ' + lastnameAmbassador, 
                            Fecha: row.date_programmed,
                            Hora: start_time && end_time?start_time.slice(0, -3) + ' - '+ end_time.slice(0, -3):'',
                            Estado : row.status, 
                            address: row.address,
                            Delivery: row.delivery?row.delivery:"0.00",
                            Total : totalOrder,
                            data: row,
                        }
                     })
                this.setState({ results:items });
                this.setState({ temp:items });
                this.renderResults(this.state.results.length);
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderResults(){
		return this.state.results.length === 0 
			? 'No hay datos.'
			: <VerOrderDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={this.state.results.length <= 10? this.state.results.length:10}
            pagination={true}
            class="-striped -highlight"
            handlerAcept = {this.handlerAcept}
            handlerCancel = {this.handlerCancel}
            handlerRemove = {this.handlerRemove}
            handlerDetails = {this.handlerDetails}
        />
    }

    renderDetailResults(){
		return this.state.detailResults.length === 0 
			? ''
			: <VerDetailsOrderDatatable
            multiSelectOption={false}
            myData={this.state.detailResults}
            pageSize={this.state.detailResults.length+1}
            pagination={true}
            class="-striped -highlight"
        />
    }   

    showImage(results){
        // Aplicamos un fix si no devuelve una imagen 
        let url_image = `${process.env.REACT_APP_URL_FILES}/images/products/`;
            for (let i = 0; i < results.length; i++){
                results[i]['imagen'] = <img src={url_image + (results[i]['Product']['url']?results[i]['Product']['url']:(results[i]['Product']['logo']?results[i]['Product']['logo']:''))} style={{width:50,height:50}}/>;
            }
            return results;
        }

    handlerDetails(data_row) {
        //console.log('data_row',data_row);
        var user = AuthService.getCurrentUser();
        ApiService.getOrderDetails(user.token,data_row.original.Id).then(
            (res) => {
                if(res && res.status == 'success'){
                    const items = this.showImage(res.data.Purchases_detail).map(function(row) {
                        return { 
                          id: row.id, 
                          Imagen: row.imagen, 
                          Nombre : row.Product.name, 
                          Precio : row.price, 
                          Cantidad : row.quantity, 
                          Total : row.total,
                        }
                     })
                    
                     var type_address = data_row.original.data.type_address?data_row.original.data.type_address:'';
                     var address = data_row.original.data.address?data_row.original.data.address:'';
                     var number = data_row.original.data.number?data_row.original.data.number:'';
                     var start_time = res.data.hour_programmed_start?res.data.hour_programmed_start.toString():'';
                     var end_time = res.data.hour_programmed_end?res.data.hour_programmed_end.toString():'';

                    this.setState({ detailResults:items });
                    this.setState({ client: data_row.original.Usuario });
                    this.setState({ date: res.data.date_programmed?res.data.date_programmed:'' });
                    this.setState({ hour: start_time && end_time?start_time.slice(0, -3) + ' - ' + end_time.slice(0, -3):'' });
                    this.setState({ id_order: data_row.original.Id });
                    this.setState({ total_order: data_row.original.Total });
                    this.setState({ address: type_address+' '+address+' '+number });
                    this.setState({ cash: res.data.cash?parseInt(res.data.cash, 10):0 });
                    this.setState({ district: data_row.original.data.district?data_row.original.data.district:'' });
                    this.setState({ interior: data_row.original.data.interior?data_row.original.data.interior:'' });
                    this.setState({ url_google: data_row.original.data.url_google?data_row.original.data.url_google:'' });
                    this.setState({ delivery: res.data.delivery?res.data.delivery:"0.00" });
                    this.setState({ discount: res.data.discount?res.data.discount:"0.00" });
                    
                    this.renderDetailResults();
                    this.onOpenModal();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handlerAcept(data_row) {
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea aceptar la orden nro '+ data_row.original.Id + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                let data = {
                    'idPurchase': data_row.original.Id,
                    'id_user': data_row.original.id_user,
                }
                ApiService.postAceptPurchase(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            Swal.fire("Hecho!", "Orden Aceptada!", "success");
                            //toast.success("Orden Aceptada!");
                            this.setState({results: []});
                            this.getDataOrders();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );




           }
         })
        //this.props.history.push("/edit-user/"+data_row.original.id);
        //window.location.reload();
    }

    handlerCancel(data_row) {
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea cancelar la orden nro '+ data_row.original.Id + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                let data = {
                    'idPurchase': data_row.original.Id,
                    'id_user': data_row.original.id_user,
                }
                ApiService.postCancelPurchase(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            Swal.fire("Hecho!", "Orden Cancelada!", "success");
                            this.setState({results: []});
                            this.getDataOrders();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );




           }
         })
        //this.props.history.push("/edit-user/"+data_row.original.id);
        //window.location.reload();
    }

    handlerRemove(data_row) {
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea eliminar la orden nro '+ data_row.original.Id + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                let data = {
                    'idPurchase': data_row.original.Id,
                    'id_user': data_row.original.id_user,
                }
                ApiService.postRemovePurchase(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            Swal.fire("Hecho!", "Orden eliminada!", "success");
                            this.setState({results: []});
                            this.getDataOrders();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );




           }
         })
        
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentWillReceiveProps(){
        //this.getDataOrders()
    }

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        ApiService.getDataReportsMonthsAndYears(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    let items_parent = [];
                    //console.log(res.data);
                    this.setState({ dataListCateg: res.data });

                    for (let i = 0; i < res.data.length; i++) {
                        let array_data = {};
                        array_data['value'] = i;
                        array_data['label'] = res.data[i]['year2'] + '-' + res.data[i]['month2'];
                        items_parent.push(array_data);
                    }
                    this.setState({ dataCateg: items_parent });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handleChange = selectedOption => {
        //console.log('this.state.dataListCateg',this.state.dataListCateg);
        let array_categ = [];
        array_categ = this.state.dataListCateg;

        //console.log(`array_categ:`, array_categ);
    
        let array_sub_categ = null;
        let optionSelected = null;
        let array_delivery =  null;
        optionSelected = array_categ[selectedOption.value]['year2'] + ' - ' + array_categ[selectedOption.value]['month2'];;
        array_sub_categ = array_categ[selectedOption.value]['total'];
        array_delivery = array_categ[selectedOption.value]['delivery'];
        


        this.setState({ 
            dataOptionSelected: optionSelected,
            dataSubCateg: array_sub_categ,
            dataDelivery: array_delivery

        });

        //console.log('selected' + this.state.dataOptionSelected);
        //console.log('subcat' + this.state.dataSubCateg);


        this.setState(
          { selectedOption },
          () => console.log(`Option selected:`, this.state.selectedOption)
        );
        this.getDataOrdersFilter(array_categ[selectedOption.value]['month2'],array_categ[selectedOption.value]['year2'] )
      };
    
      getDataOrdersFilter(month, year){
        var user = AuthService.getCurrentUser();
        

        ApiService.getOrdersReportFilter(user.token,month,year).then(
            (res) => {
                if(res && res.status == 'success'){
                    this.setState({ 
                        dataUtility: res.data.totalUtility,
                        dataCosts: res.data.totalCosts,
                        profitUtility: res.data.profitUtility,
                        averageTicket: res.data.averageTicket,
                        numTotalOrders: res.data.numTotalOrders,
                        totalCustomers: res.data.totalCustomers,
                        percentageActiveCustomers: res.data.percentageActiveCustomers,
                        totalOrdersAverage: res.data.totalOrdersAverage,
                    });
                    //console.log('orders',res.data)
                    const items = res.data.data.map(function(row) {
                        var start_time = row.hour_programmed_start?row.hour_programmed_start.toString():'';
                        var end_time = row.hour_programmed_end?row.hour_programmed_end.toString():'';
                        var nameAmbassador = row.Referred && row.Referred.name ? row.Referred.name : '-';
                        var lastnameAmbassador = row.Referred && row.Referred.last_name ? row.Referred.last_name : '-';
                        var totalOrder = row.totalOrder;
                        return { 
                            Id: row.id, 
                            id_user: row.id_user,
                            Usuario : row.User.name + ' ' + row.User.last_name, 
                            Embajador : nameAmbassador + ' ' + lastnameAmbassador, 
                            Fecha: row.date_programmed,
                            Hora: start_time && end_time?start_time.slice(0, -3) + ' - '+ end_time.slice(0, -3):'',
                            Estado : row.status, 
                            address: row.address,
                            Delivery: row.delivery?row.delivery:"0.00",
                            Total : totalOrder,
                            data: row,
                        }
                     })
                this.setState({ results:items });
                this.setState({ temp:items });
                
                
                this.renderResults(this.state.results.length);
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    render() {
        const { open, selectedOption } = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Reporte de Ventas" parent="Sales" />

                <div className="collection_data_container">

                    <div className="col-xl-2 orders-today-container">
                        <span>{this.state.dataOptionSelected} </span>
                        <Select
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={this.state.dataCateg}
                        />
                    </div>
                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Ticket Promedio</label>
                        <span>{this.state.averageTicket}</span>
                        </div>
                    </div>
                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Total</label>
                        <span>{this.state.dataSubCateg}</span>
                        </div>
                    </div>

                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Utilidad ({this.state.profitUtility})</label>
                        <span>{this.state.dataUtility}</span>
                        </div>
                    </div>

                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Envíos</label>
                        <span>{this.state.dataDelivery}</span>
                        </div>
                    </div>

                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Costos</label>
                        <span>{this.state.dataCosts}</span>
                        </div>
                    </div>

                </div>

                <div className="collection_data_container">

                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label># órdenes</label>
                        <span>{this.state.numTotalOrders}</span>
                        </div>
                    </div>

                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Clientes Activos</label>
                        <span>{this.state.totalCustomers}</span>
                        </div>
                    </div>

                    <div className="col-xl-2 total-order-container">
                        <div>
                        <label>% Clientes Activos</label>
                        <span>{this.state.percentageActiveCustomers}</span>
                        </div>
                    </div>

                     <div className="col-xl-2 total-order-container">
                        <div>
                        <label>Ordenes promedio por cliente</label>
                        <span>{this.state.totalOrdersAverage}</span>
                        </div>
                    </div>
                    

                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Mensuales</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                    <div className="col-md-6 table-filter">
                                    <input 
                                        placeholder="Escriba para filtrar todas las columnas"
                                        name="filter" 
                                        type="text" 
                                        className="form-control" 
                                        autoComplete="off"
                                        onInput={this.onChangeFilter}
                                    />
                                    </div>
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={open} onClose={this.onCloseModal} >

                <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Orden n° {this.state.id_order}</h5>
                </div>
                <div className="modal-body">
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Usuario:</label>
                        {this.state.client}
                    </div>
                    
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Distrito:</label>
                        {this.state.district}
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Dirección:</label>
                        {this.state.address}
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Interior:</label>
                        {this.state.interior}
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Url Google:</label>
                        <a href={ this.state.url_google } target="blank">{this.state.url_google}</a>
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Fecha:</label>
                        {this.state.date}
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Hora:</label>
                        {this.state.hour}
                    </div>
                    {
                    this.state.cash>0?
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Crédito:</label>
                        {this.state.cash}
                    </div>
                    :''
                    }
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Delivery:</label>
                        {this.state.delivery}
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Total:</label>
                        {this.state.total_order}
                    </div>
                    <div style={{display: 'flex'}}>
                        <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Descuento:</label>
                        {this.state.discount}
                    </div>
                    <div>
                    <label style={{fontWeight: 'bold'}}>Detalle:</label>
                    {this.renderDetailResults()}
                    </div>
                </div>
                <div className="modal-footer">
                </div>
                </Modal>
            </Fragment>
        )
    }
}

export default VerReportOrderList
