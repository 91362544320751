import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

export class VerProductStockDatatable extends Component {
    constructor(props) {
        super(props)
        this.textColumn = "";
        this.state = {
            checkedValues: [],
            myData: this.props.myData
        }
    }

    componentDidUpdate(prevProps, prevState){
        //console.log('prevProps',prevProps)
        //console.log('prevState',prevState)
        if(prevProps.myData != prevState.myData){
            this.setState({
                myData: prevProps.myData
            })
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    e.target.innerHTML = e.target.innerHTML.replace('<div>', ''); 
                    e.target.innerHTML = e.target.innerHTML.replace('<br>', ''); 
                    e.target.innerHTML = e.target.innerHTML.replace('</div>', ''); 
                    
                    // console.log('renderEditable onBlur cellInfo', cellInfo)
                    // console.log('renderEditable onBlur this.textColumn e.target.innerHTML ',this.textColumn ,e.target.innerHTML)
                    const data = [...this.state.myData];
                    // console.log('renderEditable onBlur data[cellInfo.index][cellInfo.column.id] ', data[cellInfo.index][cellInfo.column.id])
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data },
                        () => {
                            // console.log('setState renderEditable onKeyDown this.textColumn ', this.textColumn, data[cellInfo.index][cellInfo.column.id] )
                            this.renderResult();
                        });
                }}
                onKeyPress={e => {
                    //console.log('renderEditable onKeyPress cellInfo', cellInfo)
                    if (e.key == 'Enter') {
                        e.target.innerHTML = e.target.innerHTML.replace('<div>', ''); 
                        e.target.innerHTML = e.target.innerHTML.replace('<br>', ''); 
                        e.target.innerHTML = e.target.innerHTML.replace('</div>', ''); 
                        
                        // console.log('renderEditable onKeyPress cellInfo', cellInfo)
                        // console.log('renderEditable onKeyPress this.textColumn e.target.innerHTML ',this.textColumn ,e.target.innerHTML)
                        const data = [...this.state.myData];
                        // console.log('renderEditable onKeyPress data[cellInfo.index][cellInfo.column.id] ', data[cellInfo.index][cellInfo.column.id])
                        data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;//e.target.innerHTML;
                        this.setState({ myData: data },
                            () => {
                                // console.log('setState renderEditable onKeyPress this.textColumn ', this.textColumn, data[cellInfo.index][cellInfo.column.id] )
                                this.renderResult();    
                            });
                    }
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /*deleteUser(row){
        var user = AuthService.getCurrentUser();
        ApiService.deleteUser(user.token,row.original.id).then(
        (res) => {
            toast.success("Usuario eliminado!");
            //this.props.history.push("/category");
            //window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
     )
    }*/

    editProduct(row){
        console.log(row);
        //window.location.reload();
    }

    renderResult(){
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state

        const columns = [];
        var deleteUser  =   this.props.deleteUser;
        for (var key in myData[0]) {

            //let editable = this.renderEditable
            let editable = null;
            if (key === "Id") {
                editable = null;
            }
            if (key === "Imagen") {
                editable = null;
            }
            if (key === "Estado") {
                editable = null;
            }
            if (key === "avtar") {
                editable = null;
            }
            if (key === "vendor") {
                editable = null;
            }
            if(key === "order_status"){
                editable = null;
            }
            if(key === "Nombre"){
                editable = null;
            }
            if(key === "Email"){
                editable = null;
            }
            if(key === "Celular"){
                editable = null;
            }
            if(key === "Embajador"){
                editable = null;
            }
            if(key === "Precio"){
                editable = null;
            }
            if(key === "Rol"){
                editable = null;
            }
            if(key === "Stock" || key === "Costo"){
                editable = this.renderEditable;
            }
            if(key != "variable_stock")
            {
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        accessor: key,
                        Cell: editable,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
        }
        
        return (<Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>)
    }

    render() {
        

        // if (multiSelectOption == true) {
        //     columns.push(
        //         {
        //             Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
        //                 onClick={
        //                     (e) => {
        //                         if (window.confirm('Are you sure you wish to delete this item?'))
        //                             this.handleRemoveRow()
        //                     }}>Delete</button>,
        //             id: 'delete',
        //             accessor: str => "delete",
        //             sortable: false,
        //             style: {
        //                 textAlign: 'center'
        //             },
        //             Cell: (row) => (
        //                 <div>
        //                     <span >
        //                         <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
        //                             onChange={e => this.selectRow(e, row.original.id)} />
        //                     </span>
        //                 </div>
        //             ),
        //             accessor: key,
        //             style: {
        //                 textAlign: 'center'
        //             }
        //         }
        //     )
        // } else {
        //     columns.push(
        //         {
        //             Header: <b>Acción</b>,
        //             id: 'delete',
        //             accessor: str => "delete",
        //             Cell: (row) => (
        //                 <div>
        //                     <span 
        //                     onClick={() => deleteUser(row)}
        //                     >
        //                         <i className="fa fa-trash" style={{ width: 30, fontSize: 20, padding: 11, color: '#e4566e' }}
        //                         ></i>
        //                     </span>
        //                     <Link
        //                         to={`${process.env.PUBLIC_URL}/edit-user/${row.original.id}`}
        //                     >
        //                     <i className="fa fa-pencil" style={{ width: 30, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></Link>
        //                     <Link
        //                         to={`${process.env.PUBLIC_URL}/cash-user/${row.original.id}`}
        //                     >
        //                     <i className="fa fa-money" style={{ width: 30, fontSize: 20, padding: 11,color:'rgb(214, 191, 29)' }}></i></Link>
        //                     <Link
        //                         to={`${process.env.PUBLIC_URL}/address-user/${row.original.id}`}
        //                     >
        //                     <i className="fa fa-map-marker" style={{ width: 30, marginLeft: '5px', fontSize: 20, padding: 11,color:'rgb(0 97 243)' }}></i></Link>
        //                 {/*<span onClick={() => handleToUpdate(row)}
        //                 ><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></span>*/}
        //             </div>
        //         ),
        //         style: {
        //             textAlign: 'center'
        //         },
        //         sortable: false
        //     }
        // )
        // }

        return (
            this.renderResult()
            // <Fragment>
            //     <ReactTable
            //         data={myData}
            //         columns={columns}
            //         defaultPageSize={pageSize}
            //         className={myClass}
            //         showPagination={pagination}
            //     />
            //     <ToastContainer />
            // </Fragment>
        )
    }
}

export default VerProductStockDatatable
