import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerCashUserDatatable from '../common/ver-cash-user-datatable';
import Modal from 'react-responsive-modal';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Moment from 'moment';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

export class Ver_cash_user extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeMount = this.onChangeMount.bind(this);
        this.state = {
            selectedCategOption: null,
            open: false,
            name: '',
            last_name: '',
            id: '',
            amount: 0,
            errors: {},
            loading: false,
            message: "",
            results: [],
            current_credit: 0,
        };
    }

    onChangeMount(e) {
        this.setState({
            mount: e.target.value
        });
      }

    componentWillMount() {
       var id_user = this.props.match.params.id;
       this.setState({id: id_user});
       if(id_user)
       {
          this.getUser(id_user);
          this.getDataCash(id_user);
       }
    }

    getUser(id_user){
      var user = AuthService.getCurrentUser();
      ApiService.getUserDetails(user.token,id_user).then(
          (res) => {
              //console.log(res)
              if(res && res.status === 'success')
              {
                  //console.log(res.data);
                  this.setState({ name: res.data.name?res.data.name:'' });
                  this.setState({ last_name: res.data.last_name?res.data.last_name:'' });
              }
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
  }
  
    getDataCash(id_user){
      var user = AuthService.getCurrentUser();
      ApiService.getCashUser(user.token,id_user).then(
          (res) => {
              if(res && res.status == 'success'){
                  console.log(res.data);
                  const items = res.data.map(function(row) {
                    return { 
                        Id: row.id, 
                        Tipo : row.operation_type,
                        Fecha : Moment(row.approval_date).format('DD-MM-YYYY'),
                        Detalle : row.detail,
                        Monto : parseInt(row.amount_cash, 10), 

                    }
                 })
                this.setState({ results:items });
                this.setState({current_credit: res.user.Internal_accounts_summary?res.user.Internal_accounts_summary.amount_cash:0});
                this.renderResults();
              }
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
  }

    clearInputs() {
      this.setState({
          amount: "",
      });
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerCashUserDatatable
                    multiSelectOption={false}
                    myData={this.state.results}
                    pageSize={this.state.results.length<=10?this.state.results.length:10}
                    pagination={true}
                    class="-striped -highlight"
                    handler = {this.handler}
                />
	}


    handleRegister(e) {
        //e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let data_user = {
              'id_user': this.state.id,
              'cash': this.state.mount,
          }
            ApiService.postCashUser(user.token,data_user).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Monto agregado!");
                  this.getDataCash(this.state.id);
                  this.clearInputs();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

      /*handleUpdate(e) {
        //e.preventDefault();
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();

        if(!this.state.selectedTemplateOption){
          this.setState({
              errors: {template : "Error"}
          }) 
        }else{
            this.setState({
                errors: {template : ""}
            }) 
        }
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let formdata = new FormData()
            formdata.append('id_category', this.state.id)
            formdata.append('name', this.state.name)
            formdata.append('color', this.state.color)
            if(this.state.file_backup != this.state.file.name)
            {
              formdata.append('background_url', this.state.file)
            }
            formdata.append('status', this.state.status)
            formdata.append('template', this.state.selectedTemplateOption.value)
            if(!this.state.selectedCategOption){
              formdata.append('id_parent', 0)
            }else{
              formdata.append('id_parent', this.state.selectedCategOption.value)
            }
            ApiService.updateCategory(user.token,formdata).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Categoría actualizada!");
                  this.getDataCateg();
                  this.clearInputs();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }*/

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

     componentWillReceiveProps(){
        this.getDataCash(this.props.match.params.id);
    }


    render() {
        const { open } = this.state;
        const { selectedTemplateOption } = this.state;
        const { selectedCategOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title={`Historial de movimientos de ${this.state.name+' '+this.state.last_name}`} parent="Digital" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Crédito actual: {parseFloat(this.state.current_credit).toFixed(0)}</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        <button type="button" className="btn btn-secondary" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Añadir Crédito</button>
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Añadir Crédito</h5>
                                            </div>
                                            <Form 
                                            id="frmCateg"
                                            //onSubmit={this.state.register?this.handleRegister:this.handleUpdate} 
                                            encType="multipart/form-data" 
                                            method="POST"
                                            ref={c => {this.form = c;
                                            }}>
                                            <div className="modal-body">
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Monto :</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.amount} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeMount}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                            </div>
                                            <div className="modal-footer">
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/cash-user/${this.state.id}`}
                                                onClick={() => this.handleRegister()}
                                            >
                                                <button 
                                                        className="btn btn-primary"  
                                                        disabled={this.state.loading}
                                                        >
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Guardar</span>
                                                </button> 
                                            </Link>
                                                {/*<button type="button" className="btn btn-primary" onClick={() => this.onCloseModal('VaryingMdo')}>Guardar</button>*/}
                                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Cancelar</button>
                                            </div>
                                            {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                                </div>
                                            </div>
                                            )}
                                            <CheckButton
                                            style={{ display: "none" }}
                                            ref={c => {
                                                this.checkBtn = c;
                                            }}
                                            />
                                            </Form>
                                        </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                        {this.renderResults()}
                                        {/*<VerCashUserDatatable
                                            multiSelectOption={false}
                                            myData={items}
                                            pageSize={5}
                                            pagination={false}
                                            class="-striped -highlight"
                                        />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_cash_user
