import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import VerSkuForMonthDatatable from '../common/ver-sku-for-month-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-responsive-modal';

import Select from 'react-select';

export class VerReportSkuForMonthAndCustomer extends Component {
    constructor(props) {
        super(props);
        this.onChangeFilter = this.onChangeFilter.bind(this);

        this.state = {
            open: false,
            dataCateg: [],
            results: [],
            filter: '',
            temp: [],
            detailResults: [],
            client: '',
            date: '',
            hour: '',
            id_order: null,
            total_order: null,
            address: '',
            district: '',
            interior: '',
            url_google: '',
            cash: 0,
            delivery: 0,
            discount: 0,
            selectedOption: null,
            dataListCateg: [],
            dataOptionSelected: null,
            dataOptionSelectedCustomer: null,
            selectedOptionCustomer: null,
            dataCategCustomer: [],
            dataListCategCustomer: [],
            optionSelectedMonth: null,
            optionSelectedYear: null,
        };
    }

    onChangeFilter(e) {

        this.setState({
            filter: e.target.value
        }, function () {
            console.log('');
        });

        const val = e.target.value.toLowerCase();
        
        if (this.state.temp.length != 0) {
          var columns = Object.keys(this.state.temp[0]);
          //Los botones de acciones se pintan despues
          columns.splice(columns.length);
          //columns.splice(columns.length - 1);
    
          if (!columns.length)
            return;
          const rows = this.state.temp.filter(function (d) {
            for (let i = 0; i <= columns.length; i++) {
              let column = columns[i];
              if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                return true;
              }
            }
          });
          //console.log('rows',rows)
          this.setState({
            results: rows
        }, function () {
            this.renderResults(this.state.results.length);
        });
          
        }

        e.preventDefault();
      }

    componentWillMount() {
        //this.getDataOrders();
        this.getDataCateg();
        this.getDataCustomer();
    }

    renderResults(){
		return this.state.results.length === 0 
			? 'No hay datos.'
			: <VerSkuForMonthDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={this.state.results.length <= 100 ? this.state.results.length:100}
            pagination={true}
            class="-striped -highlight"
            handlerAcept = {this.handlerAcept}
            handlerCancel = {this.handlerCancel}
            handlerRemove = {this.handlerRemove}
            handlerDetails = {this.handlerDetails}
        />
    }

    renderDetailResults(){
		return this.state.detailResults.length === 0 
			? ''
			: <VerDetailsOrderDatatable
            multiSelectOption={false}
            myData={this.state.detailResults}
            pageSize={this.state.detailResults.length+1}
            pagination={true}
            class="-striped -highlight"
        />
    }   

    

    componentWillReceiveProps(){
        //this.getDataOrders()
    }

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        ApiService.getDataReportsMonthsAndYearsSku(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    let items_parent = [];
                    //console.log(res.data);
                    this.setState({ dataListCateg: res.data });

                    for (let i = 0; i < res.data.length; i++) {
                        let array_data = {};
                        array_data['value'] = i;
                        array_data['label'] = res.data[i]['year2'] + '-' + res.data[i]['month2'];
                        items_parent.push(array_data);
                    }
                    this.setState({ dataCateg: items_parent });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getDataCustomer(){
        var user = AuthService.getCurrentUser();
        ApiService.getDataCustomers(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    let items_parent = [];
                    //console.log(res.data);
                    this.setState({ dataListCategCustomer: res.data });

                    for (let i = 0; i < res.data.length; i++) {
                        let array_data = {};
                        array_data['value'] = res.data[i]['id'];
                        array_data['label'] = res.data[i]['name'] + ' ' + res.data[i]['last_name'];
                        items_parent.push(array_data);
                    }
                    this.setState({ dataCustomer: items_parent });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handleChange = selectedOption => {
        let array_categ = [];
        array_categ = this.state.dataListCateg;
        let optionSelected = null;
        optionSelected = array_categ[selectedOption.value]['year2'] + ' - ' + array_categ[selectedOption.value]['month2'];
 
        this.setState({ 
            dataOptionSelected: optionSelected,
            optionSelectedMonth: array_categ[selectedOption.value]['month2'],
            optionSelectedYear: array_categ[selectedOption.value]['year2'],
        });

        this.setState(
          { selectedOption },
          () => console.log(`Option selected:`, this.state.selectedOption)
        );
        //this.getDataOrdersFilter(array_categ[selectedOption.value]['month2'],array_categ[selectedOption.value]['year2'] )
      };

    handleChangeCustomer = selectedOptionCustomer => {
    
        this.setState({ 
            dataOptionSelectedCustomer: selectedOptionCustomer,
        });

        this.setState(
            { selectedOptionCustomer },
            () => console.log(`Option selected customer:`, this.state.selectedOptionCustomer.value)
        );
        this.getDataOrdersCustomerFilter(this.state.optionSelectedMonth, this.state.optionSelectedYear, selectedOptionCustomer.value)
    };
    
   
    getDataOrdersFilter(month, year){
        var user = AuthService.getCurrentUser();
    
        ApiService.getProductsForMonthFilter(user.token,month,year).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('orders',res.data)
                    const items = res.data.products.map(function(row) {
                        
                        return { 
                            Id: row.id_product, 
                            Producto: row.name,
                            Categoría: row.category,
                            Total : row.total, 
                        }
                     })
                this.setState({ results:items });
                this.setState({ temp:items });
                
                
                this.renderResults(this.state.results.length);
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getDataOrdersCustomerFilter(month, year, id_customer){
        var user = AuthService.getCurrentUser();
    
        ApiService.getProductsForMonthCustomerFilter(user.token,month,year,id_customer).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('orders',res.data)
                    const items = res.data.products.map(function(row) {
                        
                        return { 
                            Id: row.id_product, 
                            Producto: row.name,
                            Categoría: row.category,
                            Total : row.total, 
                        }
                     })
                this.setState({ results:items });
                this.setState({ temp:items });
                this.renderResults(this.state.results.length);
                } else {
                    this.setState({ results: [] });
                    this.setState({ temp: [] });
                    this.renderResults();
                }
            },
            error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
          );
    }

    render() {
        const { open, selectedOption, selectedOptionCustomer } = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Sku por Clientes" parent="Sales" />

                <div className="collection_data_container">

                    <div className="col-xl-2 orders-today-container">
                        <Select
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={this.state.dataCateg}
                        />
                    </div>

                    <div className="col-xl-2 orders-today-container">
                        <Select
                            value={selectedOptionCustomer}
                            onChange={this.handleChangeCustomer}
                            options={this.state.dataCustomer}
                        />
                    </div>
                  
                    

                </div>


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Mensuales</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                    <div className="col-md-6 table-filter">
                                    <input 
                                        placeholder="Escriba para filtrar todas las columnas"
                                        name="filter" 
                                        type="text" 
                                        className="form-control" 
                                        autoComplete="off"
                                        onInput={this.onChangeFilter}
                                    />
                                    </div>
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment>
        )
    }
}

export default VerReportSkuForMonthAndCustomer
