import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerBannerDatatable from '../common/ver-banner-datatable';
import Modal from 'react-responsive-modal';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';

// image import
import img_grammage from '../../assets/images/example/primera_caracteristica.png'

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

export class Ver_banners extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeOrder = this.onChangeOrder.bind(this);
        this.onChangeUrl = this.onChangeUrl.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);
        this.onChangeTitleColor = this.onChangeTitleColor.bind(this);
        this.onChangeTextColor = this.onChangeTextColor.bind(this);
        this.onChangeColor2 = this.onChangeColor2.bind(this);
        this.onChangeBackground = this.onChangeBackground.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        //this.handleFile = this.handleFile.bind(this);
        this.handler = this.handler.bind(this);
        this.state = {
            selectedCategOption: null,
            open: false,
            open_example: false,
            register: true,
            id: "",
            name: "",
            color: "",
            text_color: "",
            title_color: "",
            color_2: "",
            errors: {},
            selectedTemplateOption: null,
            data_template: [
              {value: 'TIPO1',label: 'THC'},
              {value: 'TIPO2',label: 'SEEDS'},
              {value: 'TIPO3',label: 'SATIVA'},
            ],
            status: "ACTIVATED",
            background_url: "",
            file: null,
            loading: false,
            message: "",
            results: [],
            file_backup:null,
            flag_validate_image: false,
            data_categ_parent: [],
            order: "",
            url: "",
        };
    }

    handler(data_row) {
        //console.log("row_category",data_row.original);
        this.setState({ name: data_row.original.data.name });
        this.setState({ id: data_row.original.data.id });
        this.setState({ register: false });
        this.setState({ file: {name : data_row.original.data.image} });
        this.setState({ file_backup: data_row.original.data.image });
        this.setState({ status: data_row.original.data.status });
        this.setState({ order: data_row.original.data.order });
        this.setState({ url: data_row.original.data.url });

        this.setState({ open: true });
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
      }

    onChangeOrder(e) {
        this.setState({
            order: e.target.value
        });
      }

    onChangeUrl(e) {
        this.setState({
            url: e.target.value
        });
      }
    
    onChangeColor(e) {
        this.setState({
            color: e.target.value
        });
    }

    onChangeTitleColor(e) {
      this.setState({
          title_color: e.target.value
      });
    }

    onChangeTextColor(e) {
      this.setState({
          text_color: e.target.value
      });
    }

    onChangeColor2(e) {
      this.setState({
          color_2: e.target.value
      });
    }

    onChangeBackground(e) {
        this.setState({
            background_url: e.target.value
        });
    }

    onChangeStatus(e) {
        this.setState({
            status: e.target.value
        });
      }

    handleFile(e){
        let file = e.target.files[0];
        this.setState({file: e.target.files[0]}, () => {
           // console.log('state', this.state.file);
        })
        //this.setState(() => ({ file })); 
        /*this.setState({
            file: e.target.files[0]
        });*/
        //console.log(this.state.file);
    }

    handleCategChange = selectedCategOption => {
      this.setState(
        { selectedCategOption },
        //() => console.log(`Option selected:`, this.state.selectedCategOption)
        () => {
          //console.log('handleCategChange setState selectedCategOption')
        }
      );
    };

    componentWillMount() {
      //console.log('componentWillMount----')
      this.getDataBanners();
    }

    clearInputs() {
      this.setState({
          register: true,
          id: "",
          name: "",
          color: "",
          color_2: "",
          title_color: "",
          text_color: "",
          status: "ACTIVATED",
          background_url: "",
          file: null,
          loading: false,
          message: "",
          //results: [],
          file_backup:null,
          flag_validate_image: false,
          order: "",
          url: "",
      });
    }

    showStatus(results){
    // Aplicamos un fix si no devuelve una imagen 
    let url_image = `${process.env.REACT_APP_URL_FILES}images/banners/`;
		for (let i = 0; i < results.length; i++){
			if( (results[i]['status'] === 'ACTIVATED') ){
				results[i]['estado'] = <i className="fa fa-circle font-success f-12"/>;
			}else{
				results[i]['estado'] = <i className="fa fa-circle font-danger f-12"/>;
      }
      results[i]['imagen'] = results[i]['image']?<img src={url_image + results[i]['image']} style={{width:50,height:50}}/>:'';
		}
		return results;
	}

    getDataBanners(){
        var user = AuthService.getCurrentUser();
        ApiService.getDataBanners(user.token,0).then(
            (res) => {
              if(res && res.status == 'success'){
                this.setState({ results:[] });
                let itemsBanner = []
                this.showStatus(res.data).map((row,index) =>{
                  itemsBanner.push(row);
                })

                const items = itemsBanner.map(function(row) {
                  return { 
                    '#': row.id,
                    Imagen: row.imagen, 
                    Nombre : row.name, 
                    Orden : row.order, 
                    Estado: row.estado,
                    data: row
                  }
               })

              // console.log('getDataBanners getDataBanners items', items);
              this.setState({ results:items });
              this.renderResults();
              //this.componentShouldUpdate()
              }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderResults(){
    // console.log('renderResults this.state.results', this.state.results);
    // console.log('renderResults this.state.results.length', this.state.results.length);
		return this.state.results.length === 0 
			? ''
			: <VerBannerDatatable
                    multiSelectOption={false}
                    myData={this.state.results}
                    pageSize={this.state.results.length<=10?this.state.results.length:10}
                    pagination={true}
                    class="-striped -highlight"
                    handler = {this.handler}
                />
	}


    handleRegister(e) {
        //e.preventDefault();
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let formdata = new FormData()
            formdata.append('name', this.state.name)
            //formdata.append('color', this.state.color?this.state.color:'')
            //formdata.append('color_2', this.state.color_2?this.state.color_2:'')
            //formdata.append('text_color', this.state.text_color?this.state.text_color:'')
            //formdata.append('title_color', this.state.title_color?this.state.title_color:'')
            formdata.append('status', this.state.status)
            formdata.append('order', this.state.order)
            if(this.state.url && this.state.url != '')
            {
              formdata.append('url', this.state.url)
            }
            formdata.append('image', this.state.file)
            ApiService.postBanner(user.token,formdata).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Banner creado!");
                  this.getDataBanners();
                  this.clearInputs();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

      handleUpdate(e) {
        //e.preventDefault();
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let formdata = new FormData()
            formdata.append('id_banner', this.state.id)
            formdata.append('name', this.state.name)
            formdata.append('status', this.state.status)
            formdata.append('order', this.state.order)
            if(this.state.url && this.state.url != '')
            {
              formdata.append('url', this.state.url)
            }
            if(this.state.file_backup != this.state.file.name)
            {
              formdata.append('image', this.state.file)
            }
            ApiService.updateBanner(user.token,formdata).then(
            (res) => {
                if(res && res.status == 'success'){
                  this.setState({
                    loading: false
                  });
                  // console.log('updateGrammage success');
                  toast.success("Banner actualizado!");
                  // console.log('updateGrammage success toast');
                  this.clearInputs();
                  // console.log('updateGrammage success clearInputs');
                  this.getDataBanners();
                  // console.log('updateGrammage success getDataBanners');
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

    onOpenModal = () => {
        this.setState({ register: true });
        this.setState({ open: true });
    };

    onClearData = () => {
      this.clearInputs();
      this.onOpenModal();
  };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    handleTemplateChange = selectedTemplateOption => {
      this.setState(
        { selectedTemplateOption },
        () => {
          //console.log('handleTemplateChange setState selectedTemplateOption')
        }
      );
    };

    // componentWillReceiveProps(){
    //     console.log('componentWillReceiveProps----')
    //     this.getDataBanners()
    // }

    onShowExample = (img) => {
      this.setState({ open_example: true });
      console.log(img)
      this.setState({
        img_example: img
      }, function () {
          //console.log('onShowExample setState img_example');
      });
    };

    onCloseExample = () => {
      this.setState({ open_example: false });
    };


    render() {
        const { open, open_example } = this.state;
        const { selectedTemplateOption } = this.state;
        const { selectedCategOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Banners" parent="Digital" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Banners</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        <button type="button" className="btn btn-secondary" onClick={this.onClearData} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Añadir Banner</button>
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Añadir Banner</h5>
                                            </div>
                                            <Form 
                                            id="frmCateg"
                                            //onSubmit={this.state.register?this.handleRegister:this.handleUpdate} 
                                            encType="multipart/form-data" 
                                            method="POST"
                                            ref={c => {this.form = c;
                                            }}>
                                            <div className="modal-body">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="form-group" hidden="1">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Id:</label>
                                                        <Input 
                                                        name="id" 
                                                        value={this.state.id} 
                                                        type="text" 
                                                        className="form-control" 
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Nombre:</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.name} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeName}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Estado:</label>
                                                        <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label className="d-block d-block-status d-block-left">
                                                            <Input 
                                                                id="edo-ani"
                                                                name="status" 
                                                                value="ACTIVATED"
                                                                type="radio" 
                                                                className="radio_animated radio_status" 
                                                                checked={this.state.status === "ACTIVATED"}
                                                                onChange={this.onChangeStatus}
                                                                validations={[required]}
                                                            />
                                                            Activo
                                                                {/*<input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />*/}
                                                        </label>
                                                            <label className="d-block d-block-status" >
                                                            <Input 
                                                                id="edo-ani"
                                                                name="status" 
                                                                value="DESACTIVATED"
                                                                type="radio" 
                                                                className="radio_animated radio_status" 
                                                                checked={this.state.status === "DESACTIVATED"}
                                                                onChange={this.onChangeStatus}
                                                                validations={[required]}
                                                            />
                                                            Inactivo
                                                                {/*<input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />*/}
                                                        </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Orden:</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.order} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeOrder}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Url:</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.url} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeUrl}
                                                        //validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    {/*<div className="form-group">
                                                        <label className="col-form-label">Estado:</label>
                                                        <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label className="d-block d-block-status d-block-left">
                                                            <Input 
                                                                id="edo-ani"
                                                                name="status" 
                                                                value="ACTIVATED"
                                                                type="radio" 
                                                                className="radio_animated radio_status" 
                                                                checked={this.state.status === "ACTIVATED"}
                                                                onChange={this.onChangeStatus}
                                                                validations={[required]}
                                                            />
                                                            Activo
                                                        </label>
                                                            <label className="d-block d-block-status" >
                                                            <Input 
                                                                id="edo-ani"
                                                                name="status" 
                                                                value="DESACTIVATED"
                                                                type="radio" 
                                                                className="radio_animated radio_status" 
                                                                checked={this.state.status === "DESACTIVATED"}
                                                                onChange={this.onChangeStatus}
                                                                validations={[required]}
                                                            />
                                                            Inactivo
                                                        </label>
                                                        </div>
                                                    </div>*/}
                                                    {/*<div className="form-group">
                                                        <label className="col-form-label">Categoría Parent:</label>
                                                        <Select
                                                            value={selectedCategOption}
                                                            onChange={this.handleCategChange}
                                                            options={this.state.data_categ_parent}
                                                        />
                                                    </div>*/}
                                                    <div className="form-group">
                                                        <label htmlFor="message-text" className="col-form-label">Imagen: (1300 x 650)</label>
                                                        <Input 
                                                        name="background_url" 
                                                        //value={this.state.file} 
                                                        type="file" 
                                                        className="form-control" 
                                                        accept="image/*"
                                                        validations={this.state.register?[required]:[]}
                                                        //onChange={this.onChangeBackground}
                                                        //onChange={(e)=>this.handleFile(e)}
                                                        onChange={(e) => {
                                                            this.setState({file: e.target.files[0]}, () => {
                                                                //console.log('state', this.state.file);
                                                            })
                                                            }}
                                                        />
                                                        {/*<input className="form-control" id="validationCustom02" type="file" />*/}
                                                    </div>
                                                    {/*<div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de fondo 1:</label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.color} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeColor}
                                                        validations={[required]}
                                                        />
                                                    </div>*/}
                                                    {/*<div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de fondo 2:</label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.color_2} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeColor2}
                                                        />
                                                    </div>*/}
                                                    {/*<div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de Título:</label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.title_color} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeTitleColor}
                                                        validations={[required]}
                                                        />
                                                    </div>*/}
                                                    {/*<div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de Texto:</label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.text_color} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeTextColor}
                                                        validations={[required]}
                                                        />
                                                  </div>*/}                                                
                                                  </div>
                                              </div>
                                            </div>
                                            <div className="modal-footer">
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/banners`}
                                                onClick={() => this.state.register?this.handleRegister():this.handleUpdate()}
                                            >
                                                <button 
                                                        className="btn btn-primary"  
                                                        >
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Guardar</span>
                                                </button> 
                                            </Link>
                                                {/*<button type="button" className="btn btn-primary" onClick={() => this.onCloseModal('VaryingMdo')}>Guardar</button>*/}
                                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Cancelar</button>
                                            </div>
                                            {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                                </div>
                                            </div>
                                            )}
                                            <CheckButton
                                            style={{ display: "none" }}
                                            ref={c => {
                                                this.checkBtn = c;
                                            }}
                                            />
                                            </Form>
                                        </Modal>
                                        <Modal open={open_example} onClose={this.onCloseExample} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Ejemplo</h5>
                                            </div>
                                            <div className="modal-body">
                                              <div className="row">
                                                <div style={{ margin: '0 auto' }}>
                                                  <div className="form-group">
                                                  {
                                                    this.state.img_example == 'img_grammage'?
                                                    <img className="blur-up lazyloaded" src={img_grammage} alt="" />
                                                    :''
                                                  }
                                                  </div>
                                                </div>
                                              </div>
                                              </div>
                                              <div className="modal-footer" style={{ textAlign: 'center', display: 'block', fontWeight: 'bolder', fontSize: '20px' }}>
                                                {
                                                  this.state.img_example == 'img_grammage'?
                                                  <>Tamaño 300 x 300</>
                                                  :''
                                                }
                                            </div>
                                        </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                        {this.renderResults()}
                                        {/*<VerBannerDatatable
                                            multiSelectOption={false}
                                            myData={items}
                                            pageSize={5}
                                            pagination={false}
                                            class="-striped -highlight"
                                        />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_banners
