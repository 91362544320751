import {
    DollarSign,
    Tag,
    Users,
    Chrome,
    BarChart,Settings, LogIn
} from 'react-feather';

export const MENUITEMS = [
    /*{
        path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },*/
    {
        title: 'Alertas', icon: Tag, type: 'sub', active: false, children: [
            { path: '/charges', title: 'Pagos Clientes', type: 'link' },
            { path: '/change-address-request', title: 'Direcciones de usuarios', type: 'link' },
            { path: '/change-user-request', title: 'Datos de usuarios', type: 'link' },
        ]
    },
    {
        title: 'Órdenes', icon: Chrome, type: 'sub', active: false, children: [
            { path: '/orders', title: 'Listado de órdenes', type: 'link' },
            { path: '/orders-delivery', title: 'Ordenes del dia', type: 'link' },
            { path: '/collections', title: 'Cobranzas', type: 'link' },
            { path: '/all-cash-user', title: 'Créditos', type: 'link' },
        ]
    },
    {
        title: 'Gestión Comercio', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/product-stock', title: 'Gestión de Stocks', type: 'link' },
            { path: '/product-list', title: 'Listado de productos', type: 'link' },
            { path: '/add-product', title: 'Añadir Producto', type: 'link' },
            { path: '/category', title: 'Categorías', type: 'link' },
            { path: '/sub-category', title: 'Subcategorías', type: 'link' },
            { path: '/promotions', title: 'Promociones', type: 'link' },
            { path: '/banners', title: 'Banners', type: 'link' },
            { path: '/schedules', title: 'Horarios de atención', type: 'link' },
            { path: '/pickup-windows', title: 'Ventanas de entregas', type: 'link' },
            { path: '/grammage', title: 'Etiquetas', type: 'link' },
        ]
    },
    {
        title: 'Analítica', icon: BarChart, type: 'sub', active: false, children: [
            { path: '/', title: 'Variables Generales', type: 'link' },
            { path: '/sku-monthly-sale', title: 'Desempeño SKUs', type: 'link' },
            { path: '/sku-monthly-sale-customer', title: 'Desempeño Clientes', type: 'link' },
            { path: '/monthly-sale', title: 'Ventas mensuales', type: 'link' },
        ]
    },
    {
        title: 'Usuarios', icon: Users, type: 'sub', active: false, children: [
            { path: '/list-user', title: 'Lista de Usuarios', type: 'link' },
            { path: '/create-user', title: 'Añadir Usuario', type: 'link' },
            { path: '/list-pending-user', title: 'Aprobar referidos', type: 'link' },
            { path: '/import-user', title: 'Importar Usuarios', type: 'link' },
        ]
    },
    {
        title: 'Configuración', path: '/settings', icon: Settings, type: 'link', active: false
    },
    {
        title: 'Cerrar Sesión',path:'/auth/login', icon: LogIn, type: 'logout', active: false
    }
]
