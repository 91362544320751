import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

export class VerSkuForMonthDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.myData != prevState.myData){
            this.setState({
                myData: prevProps.myData
            })
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    deleteUser(row){
        var user = AuthService.getCurrentUser();
        ApiService.deleteUser(user.token,row.original.id).then(
        (res) => {
            toast.success("Usuario eliminado!");
            //this.props.history.push("/category");
            //window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          /*this.setState({
            loading: false,
            message: resMessage
          });*/
        }
     )
    }

    editProduct(row){
        console.log(row);
        //window.location.reload();
    }

    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state
        const columns = [];
        var handlerAcept  =   this.props.handlerAcept;
        var handlerCancel  =   this.props.handlerCancel;
        var handlerDetails  =   this.props.handlerDetails;
        var handlerRemove =   this.props.handlerRemove;
        for (var key in myData[0]) {

            let editable = this.renderEditable
            editable = null

            if(key != "id_user" && key != "address" && key != 'data' && key  != "Estado Entrega")
            {
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        accessor: key,
                        Cell: editable,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }

            if(key  == "Estado Entrega"){
                console.log('key',key);
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        //accessor: key,
                        id: 'estado',
                        //accessor: str => "estado",
                        Cell: (row) => (
                            //<i className="fa fa-circle" aria-hidden="true"></i> + row.original['Estado Entrega']

                            <div>
                                <span onClick={() => handlerDetails(row)}>
                                    {row.original['Estado Entrega'] == "POR PREPARAR"?
                                        <i className="fa fa-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'red' }}></i>
                                        : row.original['Estado Entrega'] == "LISTO PARA ENVIO"?
                                        <i className="fa fa-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'yellow' }}></i>
                                        : row.original['Estado Entrega'] == "ENTREGADO"?
                                        <i className="fa fa-circle" style={{ width: 35, fontSize: 20, padding: 11,color:'green' }}></i>
                                        : ''
                                    }{ row.original['Estado Entrega']}
                                </span>
                            </div>
                        
                        ),
                        accessor: key,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
        }

        if (multiSelectOption == true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } 

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        )
    }
}

export default VerSkuForMonthDatatable
