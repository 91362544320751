import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VerAddressForm from './ver-address-form';

const Ver_create_address = () => {
  const history = useHistory();
  const [token, setToken] = useState(null);

  const { id: userId } = useParams();

  useEffect(() => {
    let user = AuthService.getCurrentUser();
    setToken(user.token);
  }, []);

  const onHandlercreateAddress = async (
    values,
    { setSubmitting, setErrors }
  ) => {
    try {
      let user = AuthService.getCurrentUser();

      const response = await ApiService.addAddress(user.token, {
        ...values,
        user_id: userId,
      });

      setSubmitting(false);

      if (response.status === 422) {
        return setErrors(response.data);
      }

      toast.success(response.data.message);
      history.push(`/address-user/${userId}`);
    } catch (e) {
      setSubmitting(false);
      //console.log(e);
      toast.error(e.message);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Registrar dirección" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">{/*<h5> Crear Usuario</h5>*/}</div>
              <div className="card-body">
                <VerAddressForm
                  tokenJwt={token}
                  onHandlerSubmit={onHandlercreateAddress}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Ver_create_address;
