import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerChildProductDatatable from '../common/ver-child-product-datatable';
import Modal from 'react-responsive-modal';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Moment from 'moment';
import Swal from 'sweetalert2';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

export class Ver_child_prod extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeSize = this.onChangeSize.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.handlerEdit = this.handlerEdit.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleAddDiscount = this.handleAddDiscount.bind(this);
        this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
        this.handleChangePercent = this.handleChangePercent.bind(this);
        this.handleDeleteDiscount = this.handleDeleteDiscount.bind(this);

        this.dataArrayTemplate = [];
        this.dataArrayTemplateAux = [];

        this.state = {
            selectedCategOption: null,
            register: true,
            open: false,
            name: '',
            last_name: '',
            id: '',
            amount: 0,
            errors: {},
            loading: false,
            message: "",
            results: [],
            current_credit: 0,
            delivery: 0,
            id_product: null,
            size: '',
            price: 0,
            id_parent: '',
            check_descuento: false,
            dataDiscounts: [{
              quantity: '', percent: ''
            }],
            dataArrayTemplate: [{
              quantity: '', percent: ''
            }],
        };
        this.dataDiscounts = [];
    }

    handleCheckBoxChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
  
      this.setState({
        [name]: value
      });


      //console.log('target.checked',target.checked)
      //console.log('target.value',target.value)

      if(target.checked == true)
      {
        /*var numMayor = []
        var position = 0;
        if(this.dataDiscounts.length > 0){
          this.dataDiscounts.filter( x => {
            numMayor.push(x.value);
          })
          position = Math.max(...numMayor) + 1;
        }else{
          position = 1
        }*/
        
    
        //console.log(`handleAddDiscount-------- numMayor position:`, numMayor, position)
    
        let array_data_discount = {};
        //array_data_discount['position'] = position;
        array_data_discount['quantity'] = '';
        array_data_discount['percent'] = '';
        this.dataDiscounts.push(array_data_discount);
    
        let dataDiscounts = this.dataDiscounts.map(function(row) {
                            return { quantity: row.quantity, percent: row.percent}
                         });
    
        //console.log(`handleAddDiscount-------- dataDiscounts:`, dataDiscounts)
        this.setState(
          {
            dataDiscounts: dataDiscounts,
            //listStepTemplate: listStepTemplate,
          }
        );

          //document.getElementById("row_descuentos").style.display = "block";          
      }else{
        this.dataDiscounts = [];
        let dataDiscounts = this.dataDiscounts;
        //console.log('dataDiscounts',dataDiscounts)
        this.setState(
          {
            dataDiscounts: dataDiscounts,
            //listStepTemplate: listStepTemplate,
          }
        );
          //document.getElementById("row_descuentos").style.display = "none";
        
      }






    }

    handleAddDiscount() {

      //this.selectRef.select.clearValue();
  
      //console.log('handleAddDiscount--------------------- this.dataDiscounts' , this.dataDiscounts);
  
      //var key = ( this.dataDiscounts.length > 0 ? ( this.dataDiscounts[ (this.dataDiscounts.length-1) ]['value'] + 1) : 1)
      /*var numMayor = []
      var position = 0;
      if(this.dataDiscounts.length > 0){
        this.dataDiscounts.filter( x => {
          numMayor.push(x.value);
        })
        position = Math.max(...numMayor) + 1;
      }else{
        position = 1
      }*/
      
  
      //console.log(`handleAddDiscount-------- numMayor position:`, numMayor, position)
  
      let array_data_discount = {};
      //array_data_discount['position'] = position;
      array_data_discount['quantity'] = '';
      array_data_discount['percent'] = '';
      this.dataDiscounts.push(array_data_discount);
  
      let dataDiscounts = this.dataDiscounts.map(function(row) {
                          return { quantity: row.quantity, percent: row.percent}
                       });
  
      //console.log(`handleAddDiscount-------- dataDiscounts:`, dataDiscounts)
      this.setState(
        {
          dataDiscounts: dataDiscounts,
          //listStepTemplate: listStepTemplate,
        }
      );
    }

    handleChangeQuantity(data, index) {
      //console.log('handleChangeQuantity----------------', data, index.target.value);
  
        let dataDiscounts = this.dataDiscounts.map(function(row,n) {
          //console.log('this.dataDiscounts.map row1----------------', row, n , data);
          if(n == data){
            row.quantity = index.target.value
          }
          //console.log('this.dataDiscounts.map row2----------------', row, n , data);
          return { quantity: row.quantity, percent: row.percent}
       });
    
        this.setState(
          {
            dataDiscounts: dataDiscounts,
          },
          () => {
            this.dataDiscounts = dataDiscounts;
            //console.log(`handleChangeQuantity------- setState dataDiscounts:`, this.state)
            this.renderResultDiscounts();
          }
        );
      //}
    }

    handleChangePercent(data, index) {
      //console.log('handleChangePercent----------------', data, index.target.value);
  
        let dataDiscounts = this.dataDiscounts.map(function(row,n) {
          //console.log('this.dataDiscounts.map row1----------------', row, n , data);
          if(n == data){
            row.percent = index.target.value
          }
          //console.log('this.dataDiscounts.map row2----------------', row, n , data);
          return { quantity: row.quantity, percent: row.percent}
       });
    
        this.setState(
          {
            dataDiscounts: dataDiscounts,
          },
          () => {
            this.dataDiscounts = dataDiscounts;
            //console.log(`handleChangePercent------- setState dataDiscounts:`, this.state)
            this.renderResultDiscounts();
          }
        );
      //}
    }


    handleDeleteDiscount(index) {
      //console.log('index data',index)
      //this.selectRef.select.clearValue();
      //console.log('handleDeleteDiscount-------------- index , this.dataDiscounts' , index , this.dataDiscounts);
  
      // var listStepTemplate = [];
  
      // this.dataArrayTemplate.forEach((d, i) => {
      //   console.log('this.dataArrayTemplate.forEach' , d , i , index);
      //   if (i != index) {
      //     console.log('if this.dataArrayTemplate.forEach' , d , i , index);
      //     listStepTemplate.push(d);
      //   }
      // })
  
  
      // var dataStepsFilter = this.dataDiscounts.filter( (row,n) => {
      //   console.log('this.dataDiscounts.filter' , row , n , index);
      //   if( n != index ){
      //     return { quantity: row.quantity, percent: row.percent}
      //   }
      // } );

      this.setState(
        {
          dataDiscounts: [],
          //dataArrayTemplate: dataArrayTemplate
        },
        () => {
          this.renderResultDiscounts();

          this.dataDiscounts.splice(index,1)

          //console.log('this.dataDiscounts splice',this.dataDiscounts)
          //console.log('dataStepsFilter',dataStepsFilter)
      
          var dataDiscounts = this.dataDiscounts.map(function(row,n){
            //console.log('this.dataDiscounts.map' , row , n , index);
              return { quantity: row.quantity, percent: row.percent}
      
          } );
      
          //console.log('handleDeleteDiscount------- dataDiscounts'  , dataDiscounts );
      
          
      
          /*this.dataArrayTemplate.forEach((d, i) => {
            let position = (this.dataArrayTemplate[i]['step'] &&  this.dataArrayTemplate[i]['step'].value ?  this.dataArrayTemplate[i]['step'].value : 0 )
            let datafind = dataDiscounts.find(x => x.value == position )
            //console.log('handleDeleteDiscount-------- position datafind this.dataArrayTemplate[i]["step"] dataDiscounts', position, datafind, this.dataArrayTemplate[i]['step'] , dataDiscounts);
            this.dataArrayTemplate[i]['step'] = (datafind ? this.dataArrayTemplate[i]['step'] : {}) ;
          })
          //if(sameData == true && data != null){
          var dataArrayTemplate = this.dataArrayTemplate;*/
      
          this.setState(
            {
              dataDiscounts: dataDiscounts,
              //dataArrayTemplate: dataArrayTemplate
            },
            () => {
              this.dataDiscounts = dataDiscounts;
              //this.selectRef.select.clearValue();
              //this.renderResultDiscounts();
              //console.log(`setState------ handleDeleteDiscount dataDiscounts:`, this.state.dataDiscounts)
              //this.renderResult();
              this.renderResultDiscounts();
            }
          );
        }
      );

      

      //this.renderResultDiscounts();
  
    }

    onChangeSize(e) {
        this.setState({
            size: e.target.value
        });
      }

    onChangePrice(e) {
        this.setState({
            price: e.target.value
        });
      }

    componentWillMount() {
       var id_product = this.props.match.params.id;
       this.setState({id: id_product});
       if(id_product)
       {
          this.product(id_product);
          this.getDataChild(id_product);
       }
    }

    product(id_product){
      var user = AuthService.getCurrentUser();
      ApiService.getProductDetails(user.token,id_product).then(
          (res) => {
              //console.log(res)
              if(res && res.status === 'success')
              {
                  //console.log(res.data);
                  this.setState({ name: res.data.name?res.data.name:'' });
                  //this.setState({ last_name: res.data.last_name?res.data.last_name:'' });
              }
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
  }
  
    getDataChild(id_product){
      var user = AuthService.getCurrentUser();
      ApiService.getChildProd(user.token,id_product).then(
          (res) => {
              if(res && res.status == 'success'){
                this.setState({ results:[] });
                  //console.log(res.data);
                  const items = res.data.map(function(row) {
                    return { 
                        Id: row.id, 
                        Producto : row.name?row.name:'',
                        Medida : row.size?row.size:'',
                        Precio : row.price?row.price:'',
                        data : row,
                    }
                 })
                this.setState({ results:items });
                //this.setState({current_credit: res.user.Internal_accounts_summary?res.user.Internal_accounts_summary.amount_cash:0});
                this.renderResults();
              }
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
  }

    clearInputs() {
      this.setState({
          size: "",
          price: "",
          register: true,
          check_descuento: false,
      });
      this.dataDiscounts = [];
        let dataDiscounts = this.dataDiscounts;
        //console.log('dataDiscounts',dataDiscounts)
        this.setState(
          {
            dataDiscounts: dataDiscounts,
            //listStepTemplate: listStepTemplate,
          }
      );
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerChildProductDatatable
                    multiSelectOption={false}
                    myData={this.state.results}
                    pageSize={this.state.results.length<=10?this.state.results.length:10}
                    pagination={true}
                    class="-striped -highlight"
                    handler = {this.handler}
                    handlerEdit = {this.handlerEdit}
                />
	}

  handlerEdit(data_row) {
    //console.log(data_row)
    /*let data_descuentos = data_row.original.data.Discounts;
    //console.log('data_descuentos',data_descuentos)

    let dataDiscounts = [];

    if(data_descuentos.length > 0)
    {
      for (let i = 0; i < data_descuentos.length; i++) {
        let array_data = {};
        array_data['quantity'] = data_descuentos[i]['quantity'];
        array_data['percent'] = data_descuentos[i]['percent'];
        //array_data['key'] = res.listStepTemplate[i]['id'];
        dataDiscounts.push(array_data);
      }
    }*/

    //this.dataDiscounts = dataDiscounts;
    
    /*this.setState({
      dataDiscounts: dataDiscounts
    });*/

    this.setState({
      id_parent: data_row.original.data.id_parent?data_row.original.data.id_parent:''
    });

    this.setState({
      size: data_row.original.data.size?data_row.original.data.size:''
    });

    this.setState({
      price: data_row.original.data.price?data_row.original.data.price:0
    });

    this.setState({
      id_product: data_row.original.data.id?data_row.original.data.id:0
    });

    /*this.setState({
      check_descuento: data_row.original.data.flag_discount == '1'?true:false
    });*/

    this.setState({ register: false });

    this.setState({ open: true });


    //this.renderResultDiscounts();
    /*if(data_descuentos.length > 0)
    {
      try {
        document.getElementById("row_descuentos").style.display = "block";         
      } catch (error) {
        
      }
    }*/

  }


    handleUpdate(e) {
        //e.preventDefault();

        let array_data_template = this.dataArrayTemplate;

        //console.log('array_data_template',array_data_template);
        //console.log('this.state.dataDiscounts',this.state.dataDiscounts);
        //console.log('this.dataDiscounts',this.dataDiscounts);
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();

        /*let array_insert_discount = {};
        array_insert_discount['quantity'] = '';
        array_insert_discount['percent'] = '';

        let flag_check_discounts = 1;
        let dataDiscount = this.dataDiscounts.map(function(row) {
                      if(!row.quantity || !row.percent)
                      {
                        flag_check_discounts = 0;
                      }
                      return { quantity: row.quantity * 1, percent: row.percent * 1}
        });

        //console.log('flag_check_discounts',flag_check_discounts)
        if(this.state.check_descuento)
        {
              if(dataDiscount.length == 0 || flag_check_discounts == 0)
              {
                Swal.fire("Aviso!", "Ingrese valores para el descuento.", "error");
                return true;
              }
        }*/
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let data_user = {}

            //console.log('dataDiscount',dataDiscount)
            data_user = {
              'id_product_child': this.state.id_product,
              'id_product': this.state.id,
              'size':this.state.size,
              'price': this.state.price,
              //'flag_discount': '0',
            }
            /*if(this.state.check_descuento)
            {
              data_user = {
                'id_product_child': this.state.id_product,
                'id_product': this.state.id,
                'size':this.state.size,
                'price': this.state.price,
                'flag_discount': '1',
                'data_discount': JSON.stringify( dataDiscount ),
              }
            }else{
              data_user = {
                'id_product_child': this.state.id_product,
                'id_product': this.state.id,
                'size':this.state.size,
                'price': this.state.price,
                'flag_discount': '0',
              }
            }*/
            

          //console.log('data_user',data_user)

            ApiService.putChildProd(user.token,data_user).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Presentación actualizado!");
                  this.getDataChild(this.state.id);
                  this.clearInputs();
                  //this.props.history.push("/category");
                  window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

      handleRegister(e) {
        //e.preventDefault();
        let array_data_template = this.dataArrayTemplate;

        
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();

        /*let array_insert_discount = {};
        array_insert_discount['quantity'] = '';
        array_insert_discount['percent'] = '';

        let flag_check_discounts = 1;
        let dataDiscount = this.dataDiscounts.map(function(row) {
                      if(!row.quantity || !row.percent)
                      {
                        flag_check_discounts = 0;
                      }
                      return { quantity: row.quantity * 1, percent: row.percent * 1}
        });

        //console.log('flag_check_discounts',flag_check_discounts)
        if(this.state.check_descuento)
        {
              if(dataDiscount.length == 0 || flag_check_discounts == 0)
              {
                Swal.fire("Aviso!", "Ingrese valores para el descuento.", "error");
                return true;
              }
        }*/
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let data_user = {}

            data_user = {
              'id_product': this.state.id,
              'size':this.state.size,
              'price': this.state.price,
            }

            /*if(this.state.check_descuento)
            {
              data_user = {
                'id_product': this.state.id,
                'size':this.state.size,
                'price': this.state.price,
                'flag_discount': '1',
                'data_discount': JSON.stringify( dataDiscount ),
              }
            }else{
              data_user = {
                'id_product': this.state.id,
                'size':this.state.size,
                'price': this.state.price,
                'flag_discount': '0',
              }
            }*/            

          //console.log('data_user',data_user)

            ApiService.postChildProd(user.token,data_user).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Presentación registrado!");
                  this.getDataChild(this.state.id);
                  this.clearInputs();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

      /*handleUpdate(e) {
        //e.preventDefault();
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();

        if(!this.state.selectedTemplateOption){
          this.setState({
              errors: {template : "Error"}
          }) 
        }else{
            this.setState({
                errors: {template : ""}
            }) 
        }
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let formdata = new FormData()
            formdata.append('id_category', this.state.id)
            formdata.append('name', this.state.name)
            formdata.append('color', this.state.color)
            if(this.state.file_backup != this.state.file.name)
            {
              formdata.append('background_url', this.state.file)
            }
            formdata.append('status', this.state.status)
            formdata.append('template', this.state.selectedTemplateOption.value)
            if(!this.state.selectedCategOption){
              formdata.append('id_parent', 0)
            }else{
              formdata.append('id_parent', this.state.selectedCategOption.value)
            }
            ApiService.updateCategory(user.token,formdata).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Categoría actualizada!");
                  this.getDataCateg();
                  this.clearInputs();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }*/

    onOpenModal = () => {
        this.setState({ register: true });
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

     componentWillReceiveProps(){
        this.getDataChild(this.props.match.params.id);
    }

    onClearData = () => {
      this.clearInputs();
      this.onOpenModal();
  };



  renderResultDiscounts(){
    return < > {this.state.dataDiscounts.map((field, index) =>
     
      <div key={index} className="mb-2">
        {/* <h5>{(index + 1) + '. ' + field['name']}</h5> */}
        <div className="form-group mb-0 mt-0">


          <div className="input-group">
            <div className="row">
              <div className="col-md-2" style={{ paddingRight: '0px', marginRight: '1px'}}>
                <span className="input-group-text" id="basic-addon2">{(index + 1)}.</span>
              </div>
              <div className="col-md-3" style={{ paddingRight: '0px', paddingLeft: '0px', marginRight: '1px'}}>
                <Input
                  //style={{ width:"60%" }}
                  name="name"
                  type="number"
                  min="1"
                  max="100"
                  className="form-control"
                  onChange={this.handleChangeQuantity.bind(this, index)}
                  value={field.quantity}
                  key={index}
                  //validations={[required]}
                />
              </div>
              <div className="col-md-3" style={{ paddingRight: '0px', paddingLeft: '0px', marginRight: '1px'}}>
                <Input
                  //style={{ width:"40%" }}
                  name="name"
                  type="number"
                  min="1"
                  max="100"
                  className="form-control"
                  onChange={this.handleChangePercent.bind(this, index)}
                  value={field.percent}
                  key={index}
                  //validations={[required]}
                />
              </div>
              
              <div className="col-md-2" style={{ paddingRight: '0px', paddingLeft: '0px'}}>
                <button
                  style={{ padding:"0" }}
                  type="button"
                  className="btn btn-primary mt-0"
                  onClick={() => this.handleDeleteDiscount(index)}
                  style={{ color: 'white' }}
                >
                  <svg className="ch-option-SvgIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" style={{ width: '12px', fill:'currentcolor' }}><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    )}</ >
  }


    render() {
        const { open } = this.state;
        const { selectedTemplateOption } = this.state;
        const { selectedCategOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title={`Presentaciones de ${this.state.name}`} parent="Digital" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        <button type="button" className="btn btn-secondary" onClick={this.onClearData} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Añadir Presentaciones</button>
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{this.state.register?'Añadir Presentaciones':'Actualizar'}</h5>
                                            </div>
                                            <Form 
                                            id="frmCateg"
                                            //onSubmit={this.state.register?this.handleUpdate:this.handleUpdate} 
                                            encType="multipart/form-data" 
                                            method="POST"
                                            ref={c => {this.form = c;
                                            }}>
                                            <div className="modal-body">
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Medida :</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.size} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeSize}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Precio :</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.price} 
                                                        type="number" 
                                                        className="form-control" 
                                                        onChange={this.onChangePrice}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    {/*<div className="form-group">
                                                    <label>
                                                      Incluir en sistema de descuentos:
                                                      <Input
                                                        name="check_descuento"
                                                        type="checkbox"
                                                        checked={this.state.check_descuento}
                                                        onChange={this.handleCheckBoxChange} />
                                                    </label>
                                                    </div>*/}
                                                    {/*
                                                      this.state.dataDiscounts.length > 0?
                                                      <div className="form-group" id="row_descuentos">


                                                      <div className="mb-2">
                                                        <div className="form-group mb-0 mt-0">
                                                          <div className="input-group">
                                                            <div className="row">
                                                              <div className="col-md-6" style={{ paddingRight: '0px', marginRight: '1px', marginLeft: '45px'}}>
                                                                <label>Cantidad</label>
                                                              </div>
                                                              <div className="col-md-2" style={{ paddingRight: '0px', marginRight: '1px', paddingLeft: '0px', }}>
                                                                <label>Porcentaje</label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
  
  
                                                      </div>
                                                      :''*/
                                                    }
  
  
  
                                                        { /*this.renderResultDiscounts() */}


                                                        {/*this.state.check_descuento?
                                                          <button
                                                          type="button"
                                                            className="btn btn-primary mt-2"
                                                            onClick={() => this.handleAddDiscount()}
                                                          >
                                                          {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                          )}
                                                          <span>Agregar</span>
                                                          </button>:''*/}
                                                      
                                            </div>
                                            <div className="modal-footer">
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/child/${this.state.id}`}
                                                onClick={() => this.state.register?this.handleRegister():this.handleUpdate()}
                                            >
                                                <button 
                                                        className="btn btn-primary"  
                                                        disabled={this.state.loading}
                                                        >
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Guardar</span>
                                                </button> 
                                            </Link>
                                                {/*<button type="button" className="btn btn-primary" onClick={() => this.onCloseModal('VaryingMdo')}>Guardar</button>*/}
                                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Cancelar</button>
                                            </div>
                                            {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                                </div>
                                            </div>
                                            )}
                                            <CheckButton
                                            style={{ display: "none" }}
                                            ref={c => {
                                                this.checkBtn = c;
                                            }}
                                            />
                                            </Form>
                                        </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_child_prod
