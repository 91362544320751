import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
// import data from '../../assets/data/sales-transactions';
// import Datatable from '../common/datatable';
import VerCollectionDatatable from '../common/ver-collection-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
//import { ToastContainer, toast } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
// import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Moment from 'moment';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import { roundNumber } from '../roundNumber';
registerLocale('es', es);


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

export class VerCollectionList extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handlerAcept = this.handlerAcept.bind(this);
    this.handlerCancel = this.handlerCancel.bind(this);
    this.handlerDetails = this.handlerDetails.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeReference = this.onChangeReference.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.state = {
      openModal: false,
      dataCateg: [],
      results: [],
      detailResults: [],
      client: '',
      state: '',
      date: '',
      hour: '',
      id_order: null,
      total_order: null,
      address: '',
      district: '',
      interior: '',
      url_google: '',
      cash: 0,
      delivery: 0,
      total_payment: 0,
      pending_payment: 0,
      temp: [],
      total: 0,
      totalFechas: [],
      totalUsuarios: [],
      errors: {},
      meses: [
        { id: 1, nombre: 'ENERO' },
        { id: 2, nombre: 'FEBRERO' },
        { id: 3, nombre: 'MARZO' },
        { id: 4, nombre: 'ABRIL' },
        { id: 5, nombre: 'MAYO' },
        { id: 6, nombre: 'JUNIO' },
        { id: 7, nombre: 'JULIO' },
        { id: 8, nombre: 'AGOSTO' },
        { id: 9, nombre: 'SEPTIEMBRE' },
        { id: 10, nombre: 'OCTUBRE' },
        { id: 11, nombre: 'NOVIEMBRE' },
        { id: 12, nombre: 'DICIEMBRE' },
      ],
      reference: '',
      value: 0,
      id_address: null,
      paymentDate: new Date(),
      file_image: null,
      voucher_image: '',
    };
  }

  handleChange(date) {
    this.setState({
      paymentDate: date,
    });
  }

  componentWillMount() {
    this.getDataCollections();
  }

  onChangeReference(e) {
    this.setState({
      reference: e.target.value,
    });
  }

  onChangeValue(e) {
    this.setState({
      value: e.target.value,
    });
  }

  onChangeFilter(e) {
    this.setState(
      {
        filter: e.target.value,
      },
      function () {
        console.log('');
      }
    );

    const val = e.target.value.toLowerCase();
    //console.log(event);
    if (this.state.temp.length != 0) {
      var columns = Object.keys(this.state.temp[0]);
      //Los botones de acciones se pintan despues
      columns.splice(columns.length);
      //columns.splice(columns.length - 1);

      if (!columns.length) return;
      const rows = this.state.temp.filter(function (d) {
        for (let i = 0; i <= columns.length; i++) {
          let column = columns[i];
          if (
            d[column] &&
            d[column].toString().toLowerCase().indexOf(val) > -1
          ) {
            return true;
          }
        }
      });
      //console.log('rows',rows)
      this.setState(
        {
          results: rows,
        },
        function () {
          //console.log('results',this.state.results);
          //console.log('lenght',this.state.results.length)
          this.renderResults(this.state.results.length);
        }
      );
      /*this.Inventarios = rows;
          let articulosAlmacenes;
          articulosAlmacenes = { 'Articulos': this.Inventarios }
          this.buildpurchaseForm(articulosAlmacenes);*/
    }

    e.preventDefault();
  }

  getDataCollections() {
    var user = AuthService.getCurrentUser();
    ApiService.getPurchaseCharge(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('orders',res.data)
          const items = res.data.map(function (row) {
            return {
              Id: row.id,
              id_user: row.id_user,
              Usuario: row.User.name + ' ' + row.User.last_name,
              Embajador: row.Referred && row.Referred.name?row.Referred.name + ' ' + row.Referred.last_name:'',
              Fecha: row.date_programmed,
              'Estado Cobranza': row.status_charge,
              Delivery: row.delivery,
              Total: row.totalOrder,
              Cash: row.cash,
              'Total Pagado': row.payment_amount
                ? parseFloat(row.payment_amount).toFixed(2)
                : 0,
              'Total Pendiente': row.pending_amount
                ? parseFloat(row.pending_amount).toFixed(2)
                : 0,
              data: row,
            };
          });

           //let total = res.totalAmount.total;
           let total = roundNumber(res.allamount);
          let totalFechas = res.totalFechas;
          let totalUsuarios = res.totalUsuarios;
          this.setState({ results: items });
          this.setState({ temp: items });
          this.setState({ total: total });
          this.setState({ totalFechas: totalFechas });
          this.setState({ totalUsuarios: totalUsuarios });
          this.renderResults();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleUpdate(e) {
    //e.preventDefault();

    //console.log('handleUpdate--------------')
    //return true;
    if (this.state.value == 0) {
      Swal.fire('Error', 'Debe ingresar un valor mayor a 0.', 'error');
      return true;
    }

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (
      this.checkBtn.context._errors.length === 0 &&
      !this.state.errors.template
    ) {
      this.onCloseModal('VaryingMdo');
      var user = AuthService.getCurrentUser();
      let select_date = Moment(this.state.paymentDate).format('YYYY-MM-DD');
      

      let formdata = new FormData();
      formdata.append('id_purchase', this.state.id_order);
      formdata.append('date_payment', select_date);
      formdata.append('reference', this.state.reference);
      formdata.append('value', this.state.value);
      formdata.append('voucher_image', this.state.file_image);

      // console.log(data_user);
      // return true;

      ApiService.postPurchaseCharge(user.token, formdata).then(
        (res) => {
          // console.log(res);

          if (res && res.status == 'success') {
            // console.log('---if');
            toast.success('Pago registrado!');
            this.getDataCollections();
            //this.getDataPurchaseCharge(this.state.id);
            this.clearInputs();
           
          } else {
            // console.log('---else');
            Swal.fire('Aviso!', res.message, 'error');
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            openModal: false,
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        //openModal: false,
        loading: false,
      });
      return false;
    }
  }

  clearInputs() {
    this.setState({
      //delivery: "",
      reference: '',
      value: '',
      file_image: null,
      //paymentDate: "",
      loading: false,
      message: '',
    });
  }

  renderResults() {
    return this.state.results.length === 0 ? (
      ''
    ) : (
      <VerCollectionDatatable
        multiSelectOption={false}
        myData={this.state.results}
        pageSize={
          this.state.results.length <= 100 ? this.state.results.length : 100
        }
        pagination={true}
        class="-striped -highlight"
        handlerAcept={this.handlerAcept}
        handlerCancel={this.handlerCancel}
        handlerDetails={this.handlerDetails}
      />
    );
  }

  renderDetailResults() {
    return this.state.detailResults.length === 0 ? (
      ''
    ) : (
      <VerDetailsOrderDatatable
        multiSelectOption={false}
        myData={this.state.detailResults}
        pageSize={this.state.detailResults.length + 1}
        pagination={true}
        class="-striped -highlight"
      />
    );
  }

  showImage(results) {
    // Aplicamos un fix si no devuelve una imagen
    let url_image = `${process.env.REACT_APP_URL_FILES}/images/products/`;
    for (let i = 0; i < results.length; i++) {
      results[i]['imagen'] = (
        <img
          src={url_image + results[i]['Product']['url']}
          style={{ width: 50, height: 50 }}
        />
      );
    }
    return results;
  }

  handlerDetails(data_row) {
    //console.log('data_row',data_row);
    var user = AuthService.getCurrentUser();
    ApiService.getOrderDetails(user.token, data_row.original.Id).then(
      (res) => {
        if (res && res.status == 'success') {
          const items = this.showImage(res.data.Purchases_detail).map(function (
            row
          ) {
            return {
              id: row.id,
              Imagen: row.imagen,
              Nombre: row.Product.name,
              Precio: row.price,
              Cantidad: row.quantity,
              Total: row.total,
            };
          });

          var type_address = data_row.original.data.type_address
            ? data_row.original.data.type_address
            : '';
          var address = data_row.original.data.address
            ? data_row.original.data.address
            : '';
          var number = data_row.original.data.number
            ? data_row.original.data.number
            : '';
          var start_time = res.data.hour_programmed_start
            ? res.data.hour_programmed_start.toString()
            : '';
          var end_time = res.data.hour_programmed_end
            ? res.data.hour_programmed_end.toString()
            : '';

          this.setState({ detailResults: items });
          this.setState({ client: data_row.original.Usuario });
          this.setState({
            date: res.data.date_programmed ? res.data.date_programmed : '',
          });
          this.setState({
            hour:
              start_time && end_time
                ? start_time.slice(0, -3) + ' - ' + end_time.slice(0, -3)
                : '',
          });
          this.setState({ id_order: data_row.original.Id });
          this.setState({ total_order: data_row.original.Total });
          this.setState({
            address: type_address + ' ' + address + ' ' + number,
          });
          this.setState({
            cash: res.data.cash ? parseInt(res.data.cash, 10) : 0,
          });
          this.setState({
            district: data_row.original.data.district
              ? data_row.original.data.district
              : '',
          });
          this.setState({
            interior: data_row.original.data.interior
              ? data_row.original.data.interior
              : '',
          });
          this.setState({
            url_google: data_row.original.data.url_google
              ? data_row.original.data.url_google
              : '',
          });
          this.setState({
            delivery: res.data.delivery ? res.data.delivery : '0.00',
          });
          this.setState({
            state: data_row.original.data.status_charge
              ? data_row.original.data.status_charge
              : '',
          });
          this.setState({
            pending_payment: data_row.original.data.pending_amount
              ? data_row.original.data.pending_amount
              : '0.00',
          });
          this.setState({
            total_payment: data_row.original.data.payment_amount
              ? data_row.original.data.payment_amount
              : '0.00',
          });

          this.renderDetailResults();
          this.onOpenModal();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handlerAcept(data_row) {
    Swal.fire({
      title: 'Confirmar',
      text: '¿Desea aceptar la orden nro ' + data_row.original.Id + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var user = AuthService.getCurrentUser();
        let data = {
          idPurchase: data_row.original.Id,
          id_user: data_row.original.id_user,
        };
        ApiService.postAceptPurchase(user.token, data).then(
          (res) => {
            if (res && res.status == 'success') {
              Swal.fire('Hecho!', 'Orden Aceptada!', 'success');
              //toast.success("Orden Aceptada!");
              this.setState({ results: [] });
              this.getDataCollections();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    });
    //this.props.history.push("/edit-user/"+data_row.original.id);
    //window.location.reload();
  }

  handlerCancel(data_row) {
    Swal.fire({
      title: 'Confirmar',
      text: '¿Desea cancelar la orden nro ' + data_row.original.Id + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var user = AuthService.getCurrentUser();
        let data = {
          idPurchase: data_row.original.Id,
          id_user: data_row.original.id_user,
        };
        ApiService.postCancelPurchase(user.token, data).then(
          (res) => {
            if (res && res.status == 'success') {
              Swal.fire('Hecho!', 'Orden Cancelada!', 'success');
              this.setState({ results: [] });
              this.getDataCollections();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    });
    //this.props.history.push("/edit-user/"+data_row.original.id);
    //window.location.reload();
  }

  onOpenModal = () => {
    this.setState({ openModal: true });
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  componentWillReceiveProps() {
    this.getDataCollections();
  }

  render() {
    const { openModal } = this.state;
    return (
      <Fragment>
        <Breadcrumb title="Cobranzas" parent="Sales" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Pedidos por cobrar</h5>
                </div>
                <div className="card-body">
                  <div className="collection_data_container">
                    
                    <div className="col-xl-3">
                      <div className="collection_total">
                        <label>TOTAL POR COBRAR</label>
                        <label className="collection_amount">
                          S/ {this.state.total}
                        </label>
                        <div className="collection_month">
                          {this.state.totalFechas.length === 0
                            ? '-'
                            : this.state.totalFechas.map((item, index) => (
                                <div key={index}>
                                  <label>
                                    {
                                      this.state.meses.find(
                                        (x) =>
                                          x.id ==
                                          Moment(item.fecha).format('MM')
                                      ).nombre
                                    }
                                    :
                                  </label>
                                  <label>S/ {item.total}</label>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <div className="collection_total">
                        <label>PRINCIPALES DEUDORES</label>
                        <div className="collection_client">
                          {this.state.totalUsuarios.length === 0
                            ? '-'
                            : this.state.totalUsuarios.map((item, index) => (
                                <div key={index}>
                                  <label>
                                    {index +
                                      1 +
                                      '.- ' +
                                      item.user.name +
                                      ' ' +
                                      item.user.last_name}
                                    :
                                  </label>
                                  <label>S/{item.total}</label>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 table-filter">
                    <input
                      placeholder="Escriba para filtrar todas las columnas"
                      name="filter"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      onInput={this.onChangeFilter}
                    />
                  </div>

                  <div id="batchDelete" className="transactions">
                    {this.renderResults()}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal open={openModal} onClose={this.onCloseModal}>
          <div className="modal-header">
            <h5 className="modal-title f-w-600" id="exampleModalLabel2">
              Orden n° {this.state.id_order}
            </h5>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                     
                      <div className="clearfix">
                        
                      </div>
                      <div id="basicScenario" className="product-physical">
                        <div style={{ marginBottom: '15px' }}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Cliente:
                                  </label>
                                  {this.state.client}
                                </div>
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Total:
                                  </label>
                                  {this.state.total_order}
                                </div>
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Delivery:
                                  </label>
                                  {this.state.delivery}
                                </div>
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Cash:
                                  </label>
                                  {this.state.cash}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Estado:
                                  </label>
                                  {this.state.state}
                                </div>
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Total Pagado:
                                  </label>
                                  {this.state.total_payment}
                                </div>
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      marginRight: '5px',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Total Pendiente:
                                  </label>
                                  {this.state.pending_payment}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Form
                          id="frmCateg"
                          //onSubmit={this.handleUpdate}
                          encType="multipart/form-data"
                          method="POST"
                          ref={(c) => {
                            this.form = c;
                          }}
                        >
                          <div className="form-group row">
                            <div
                              className="col-md-5"
                              style={{ display: 'grid' }}
                            >
                              <label>Referencia</label>
                              <Input
                                name="name"
                                value={this.state.reference}
                                type="text"
                                className="form-control"
                                onChange={this.onChangeReference}
                                validations={[required]}
                                autoFocus
                              />
                            </div>
                            <div
                              className="col-md-5"
                              style={{ display: 'grid' }}
                            >
                              <label>Valor</label>
                              <Input
                                name="name"
                                value={this.state.value}
                                type="number"
                                className="form-control"
                                onChange={this.onChangeValue}
                                validations={[required]}
                                min="1"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div
                              className="col-md-5"
                              style={{ display: 'grid' }}
                            >
                              <label>Voucher</label>
                              <Input
                                name="url"
                                //value={this.state.file}
                                type="file"
                                className="form-control"
                                accept="image/*"
                                //validations={[required]}
                                //onChange={this.onChangeBackground}
                                //onChange={(e)=>this.handleFile(e)}
                                onChange={(e) => {
                                  this.setState(
                                    { file_image: e.target.files[0] },
                                    () => {
                                      //console.log('state', this.state.file);
                                    }
                                  );
                                }}
                              />
                            </div>
                            <br />
                            <div
                              className="col-md-3"
                              style={{ display: 'grid' }}
                            >
                              <label>Fecha de Pago</label>
                              <DatePicker
                                selected={this.state.paymentDate}
                                onChange={this.handleChange}
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <div
                              className="col-md-3"
                              style={{ display: 'grid' }}
                            >
                              <a
                                //to={``}
                                className="btn btn-primary"
                                onClick={() => this.handleUpdate()}
                                style={{
                                  height: 'fit-content',
                                  marginTop: '2rem',
                                }}
                              >
                                {this.state.loading && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Guardar</span>
                              </a>
                            </div>
                          </div>

                          {this.state.message && (
                            <div className="form-group">
                              <div className="alert alert-danger" role="alert">
                                {this.state.message}
                              </div>
                            </div>
                          )}
                          <CheckButton
                            style={{ display: 'none' }}
                            ref={(c) => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>
                        {/* {this.renderResults()} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer"></div>
        </Modal>
      </Fragment>
    );
  }
}

export default VerCollectionList;
