import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/sales-transactions';
import Datatable from '../common/datatable';
import VerSkuForMonthDatatable from '../common/ver-sku-for-month-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';

import Select from 'react-select';
import { CSVLink } from 'react-csv';



export class VerReportSkuForMonth extends Component {
    constructor(props) {
        super(props);
        this.onChangeFilter = this.onChangeFilter.bind(this);

        this.state = {
            open: false,
            dataCateg: [],
            results: [],
            filter: '',
            temp: [],
            detailResults: [],
            client: '',
            date: '',
            hour: '',
            id_order: null,
            total_order: null,
            address: '',
            district: '',
            interior: '',
            url_google: '',
            cash: 0,
            delivery: 0,
            discount: 0,
            selectedOption: null,
            dataListCateg: [],
            dataCateg: [],
            dataOptionSelected: null
        };
    }

    onChangeFilter(e) {

        this.setState({
            filter: e.target.value
        }, function () {
            console.log('');
        });

        const val = e.target.value.toLowerCase();
        //console.log(event);
        if (this.state.temp.length != 0) {
          var columns = Object.keys(this.state.temp[0]);
          //Los botones de acciones se pintan despues
          columns.splice(columns.length);
          //columns.splice(columns.length - 1);
    
          if (!columns.length)
            return;
          const rows = this.state.temp.filter(function (d) {
            for (let i = 0; i <= columns.length; i++) {
              let column = columns[i];
              if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                return true;
              }
            }
          });
          //console.log('rows',rows)
          this.setState({
            results: rows
        }, function () {
            //console.log('results',this.state.results);
            //console.log('lenght',this.state.results.length)
            this.renderResults(this.state.results.length);
        });
          
        }



        e.preventDefault();
      }

    componentWillMount() {
        //this.getDataOrders();
        this.getDataCateg();
    }

    renderResults(){
		return this.state.results.length === 0 
			? 'No hay datos.'
			: <VerSkuForMonthDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={this.state.results.length <= 100 ? this.state.results.length:100}
            pagination={true}
            class="-striped -highlight"
            handlerAcept = {this.handlerAcept}
            handlerCancel = {this.handlerCancel}
            handlerRemove = {this.handlerRemove}
            handlerDetails = {this.handlerDetails}
        />
    }

    renderDetailResults(){
		return this.state.detailResults.length === 0 
			? ''
			: <VerDetailsOrderDatatable
            multiSelectOption={false}
            myData={this.state.detailResults}
            pageSize={this.state.detailResults.length+1}
            pagination={true}
            class="-striped -highlight"
        />
    }   

    

    componentWillReceiveProps(){
        //this.getDataOrders()
    }

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        ApiService.getDataReportsMonthsAndYearsSku(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    let items_parent = [];
                    //console.log(res.data);
                    this.setState({ dataListCateg: res.data });

                    for (let i = 0; i < res.data.length; i++) {
                        let array_data = {};
                        array_data['value'] = i;
                        array_data['label'] = res.data[i]['year2'] + '-' + res.data[i]['month2'];
                        items_parent.push(array_data);
                    }
                    this.setState({ dataCateg: items_parent });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handleChange = selectedOption => {
        //console.log('this.state.dataListCateg',this.state.dataListCateg);
        let array_categ = [];
        array_categ = this.state.dataListCateg;
        let optionSelected = null;
        optionSelected = array_categ[selectedOption.value]['year2'] + ' - ' + array_categ[selectedOption.value]['month2'];;
 
        
        this.setState({ 
            dataOptionSelected: optionSelected,
        });

        this.setState(
          { selectedOption },
          () => console.log(`Option selected:`, this.state.selectedOption)
        );
        this.getDataOrdersFilter(array_categ[selectedOption.value]['month2'],array_categ[selectedOption.value]['year2'] )
      };
    
      getDataOrdersFilter(month, year){
        var user = AuthService.getCurrentUser();
    
        ApiService.getProductsReport(user.token,month,year).then(
            (res) => {
                if (res && res.status === 'success') {
                    const combinedReports = res.data.combinedReports;
                    const headersTable = res.data.headersTable;
                    const rows = [];
                  
                    // Extract headers from the headersTable
                    const headers = [
                      { label: 'Id', key: 'Id' },
                      { label: 'Producto', key: 'Producto' },
                      { label: 'Categoría', key: 'Categoría' },
                      { label: 'Subcategoría', key: 'Subcategoría' },
                      // You can dynamically add more headers based on headersTable
                      ...headersTable.map((header) => ({ label: header, key: header })),
                    ];

                    for (const idProduct in combinedReports) {
                      if (combinedReports.hasOwnProperty(idProduct)) {
                        const dataRows = combinedReports[idProduct];
                        const latestData = dataRows[dataRows.length - 1]; // Obtiene el último conjunto de datos
                  
                        const row = {
                          Id: idProduct,
                          Producto: latestData.name,
                          Categoría: latestData.category,
                          Subcategoría: latestData.subcategory,
                        };
                  
                        // Agrega los valores de headersTable a la fila
                        headersTable.forEach(header => {
                          row[header] = ''; // Inicializa todas las fechas como vacías
                        });
                  
                        // Llena las fechas disponibles con los valores correspondientes
                        dataRows.forEach(data => {
                          const dateKey = `${data.year_report}-${data.month_report}`;
                          if (row.hasOwnProperty(dateKey)) {
                            row[dateKey] = parseFloat(data.total).toFixed(2);
                          }
                        });
                  
                        rows.push(row);
                        
                      }
                    }
                  
                    this.setState({ results: rows });
                    this.setState({ temp: rows });
                    this.setState({ headers }); // Set the headers in the state
                  } else {
                    this.setState({ results: [] });
                    this.setState({ temp: [] });
                  }
                  
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    render() {
        const { open, selectedOption, filteredItems = [] } = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Sku por meses" parent="Sales" />

                <div className="collection_data_container">

                    <div className="col-xl-2 orders-today-container">
                        <Select
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={this.state.dataCateg}
                        />
                    </div>

                    <div className="col-xl-2 orders-today-container">
                      <CSVLink
                        className="btn btn-primary"
                        data={this.state.results}
                        headers={this.state.headers}
                        filename={'reportte.csv'}
                      >
                        Exportar
                      </CSVLink>
                    </div>
                    

                </div>


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Mensuales</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                    <div className="col-md-6 table-filter">
                                    <input 
                                        placeholder="Escriba para filtrar todas las columnas"
                                        name="filter" 
                                        type="text" 
                                        className="form-control" 
                                        autoComplete="off"
                                        onInput={this.onChangeFilter}
                                    />
                                    </div>
                                    <div className="col-md-6">
                                    
                                    </div>
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              
            </Fragment>
        )
    }
}

export default VerReportSkuForMonth
