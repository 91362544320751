import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
// import data from '../../assets/data/ver-category';
import VerScheduleDatatable from '../common/ver-schedules-datatable';
import VerPickupWindowsDatatable from '../common/ver-pickup-datatable';
import Modal from 'react-responsive-modal';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Link } from 'react-router-dom';
// import Select from 'react-select';
// import { TimePicker } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import 'antd/dist/antd.css';
import TimePicker2 from 'react-times';
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };


export class Ver_pickup_windows extends Component {
    constructor(props) {
        super(props);
        this.onChangeStartTime = this.onChangeStartTime.bind(this);
        this.onChangeEndTime = this.onChangeEndTime.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);
        this.onChangeBackground = this.onChangeBackground.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.handlerWindows = this.handlerWindows.bind(this);
        this.handlerEditPickup = this.handlerEditPickup.bind(this);
        this.handlerDeletePickup = this.handlerDeletePickup.bind(this);
        this.handlerStatusPickup = this.handlerStatusPickup.bind(this);
        this.handleNewPickup = this.handleNewPickup.bind(this);
        //this.handleFile = this.handleFile.bind(this);
        this.handler = this.handler.bind(this);
        this.onChangeWindowStatus = this.onChangeWindowStatus.bind(this);
        this.state = {
            open: false,
            openPickupModal: false,
            register: true,
            id_pickup: "",
            id_schedule: "",
            errors: {},
            loading: false,
            message: "",
            results: [],
            day: "",
            file_backup:null,
            flag_validate_image: false,
            start_time: moment().format('HH:mm'),
            end_time: moment().format('HH:mm'),
            pickup_windows_results: [],
            start_hour: "",
            start_minute: "",
            end_hour: "",
            end_minute: "",
            window_status: "",
        };
    }

    handler(data_row) {
        //console.log("row_category",data_row.original.Plantilla);
        this.setState({ name: data_row.original.Nombre });
        this.setState({ color: data_row.original.Color });
        this.setState({ id: data_row.original.id });
        this.setState({ status: data_row.original.status });
        this.setState({ register: false });
        this.setState({ file: {name : data_row.original.background_url} });
        this.setState({ file_backup: data_row.original.background_url });
        for (let i = 0; i < this.state.data_template.length; i++){
          if(this.state.data_template[i]['value'] === data_row.original.Plantilla){
              this.handleTemplateChange(this.state.data_template[i])
          }
        }
        this.setState({ open: true });
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
      }
    
    onChangeColor(e) {
        this.setState({
            color: e.target.value
        });
    }

    onChangeBackground(e) {
        this.setState({
            background_url: e.target.value
        });
    }

    onChangeStatus(e) {
        this.setState({
            status: e.target.value
        });
      }
    
    onChangeWindowStatus(e) {
      this.setState({
          window_status: e.target.value
      });
    }


    handleFile(e){
        this.setState({file: e.target.files[0]}, () => {
           // console.log('state', this.state.file);
        })
        //this.setState(() => ({ file })); 
        /*this.setState({
            file: e.target.files[0]
        });*/
        //console.log(this.state.file);
    }

    componentWillMount() {
        this.getDataSchedules();
    }

    clearInputs() {
      this.setState({
          register: true,
          id: "",
          name: "",
          color: "",
          status: "ACTIVATED",
          background_url: "",
          file: null,
          loading: false,
          message: "",
          results: [],
          file_backup:null,
          flag_validate_image: false,
          window_status: ""
      });
    }

    showStatus(results){
		for (let i = 0; i < results.length; i++){
      switch(results[i].day){
        case 1: results[i]['dia'] = 'Lunes';break;
        case 2: results[i]['dia'] = 'Martes';break;
        case 3: results[i]['dia'] = 'Miercoles';break;
        case 4: results[i]['dia'] = 'Jueves';break;
        case 5: results[i]['dia'] = 'Viernes';break;
        case 6: results[i]['dia'] = 'Sábado';break;
        case 7: results[i]['dia'] = 'Domingo';break;
		  }
    }
    return results;
  }

    getDataSchedules(){
        var user = AuthService.getCurrentUser();
        ApiService.getSchedules(user.token).then(
            (res) => {
              if(res && res.status == 'success'){
                //console.log(res.data);
                const items = this.showStatus(res.data).map(function(row) {
                  return { 
                    id: row.id, 
                    N: row.day,
                    Día : row.dia, 
                  }
               })
              /*const items = res.data.map(function(row) {
                  return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
               })*/
              this.setState({ results:items });
              this.renderResults();
              //this.componentShouldUpdate()
              }
            },
            error => {
            }
          );
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerScheduleDatatable
                    multiSelectOption={false}
                    myData={this.state.results}
                    pageSize={this.state.results.length<=10?this.state.results.length:10}
                    pagination={true}
                    class="-striped -highlight"
                    handler = {this.handler}
                    handlerWindows = {this.handlerWindows}

                />
	}


    handleRegister(e) {
        //e.preventDefault();
        this.setState({
          message: "",
          loading: true
        });
    


        if(this.state.start_time > this.state.end_time)
        {
          //console.log('ERROR');
          Swal.fire("Error", "La hora de inicio no puede ser mayor a la hora fin.", "error");
          return true;
        }
    
        //if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal();
            this.onClosePickupModal();
            var user = AuthService.getCurrentUser();

            let data_pickup = {
              'id_schedule': this.state.id_schedule,
              'start_time': this.state.start_time,
              'end_time': this.state.end_time,
              'window_status': 'ACTIVATED',
          }

            ApiService.postPickupWindow(user.token,data_pickup).then(
            (res) => {
                if(res && res.status == 'success'){
                  Swal.fire("Hecho!", "Ventana de entrega creada!", "success");
                }else{
                  Swal.fire("Error", res.message, "error");
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        /*} else {
          this.setState({
            loading: false
          });
        }*/
      }

      handleUpdate(e) {
        //e.preventDefault();
        /*console.log(this.state.id_pickup);
        console.log(this.state.start_time);
        console.log(this.state.start_time);*/
        this.setState({
          message: "",
          loading: true
        });
        
        if(this.state.start_time > this.state.end_time)
        {
          //console.log('ERROR');
          Swal.fire("Error", "La hora de inicio no puede ser mayor a la hora fin.", "error");
          return true;
        }
    
        //if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal();
            this.onClosePickupModal();
            var user = AuthService.getCurrentUser();

            let data_pickup = {
              'id_pickup_windows': this.state.id_pickup,
              'start_time': this.state.start_time,
              'end_time': this.state.end_time,
              'window_status': this.state.window_status,
          }

            ApiService.putPickupWindow(user.token,data_pickup).then(
            (res) => {
                if(res && res.status == 'success'){
                  Swal.fire("Hecho!", "Ventana de entrega editada!", "success");
                }else{
                    Swal.fire("Error", res.message, "error");
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
      }

      handlerWindows(data_row) {
                //console.log(data_row);
                this.setState({day: data_row.original.Día})
                var user = AuthService.getCurrentUser();
                ApiService.getPickupWindow(user.token,data_row.original.id).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            //console.log(res.data)
                            const items = res.data.map(function(row,index) {
                              var start_time = row.start_time?row.start_time.toString():'';
                              var end_time = row.end_time?row.end_time.toString():'';
                              
                                return { 
                                  id: row.id, 
                                  id_schedule: row.id_schedule, 
                                  N: index+1, 
                                  Hora_Inicio: start_time.slice(0, -3), 
                                  Hora_Fin : end_time.slice(0, -3), 
                                  Estado : row.window_status,
                                }
                             })
                            this.setState({ pickup_windows_results: items });
                            this.setState({ id_schedule: data_row.original.id });
                            this.renderPickupResults();
                            this.onOpenPickupModal();
                        }
                    },
                    error => {
                    }
                  );
      }  


      renderPickupResults(){
        return this.state.pickup_windows_results.length === 0 
          ? ''
          : <VerPickupWindowsDatatable
                multiSelectOption={false}
                myData={this.state.pickup_windows_results}
                pageSize={this.state.pickup_windows_results.length+1}
                pagination={true}
                class="-striped -highlight"
                handlerEditPickup = {this.handlerEditPickup}
                handlerDeletePickup = {this.handlerDeletePickup}
                handlerStatusPickup = {this.handlerStatusPickup}
            />
        }  

    handlerEditPickup(data_row) {
       //console.log(data_row);
       var start_time = (data_row.original.Hora_Inicio).split(":");
       this.setState({ start_hour: start_time[0] });
       this.setState({ start_minute: start_time[1] });
       var end_time = (data_row.original.Hora_Fin).split(":");
       this.setState({ end_hour: end_time[0] });
       this.setState({ end_minute: end_time[1] });
       this.setState({ id_pickup: data_row.original.id });
       //this.setState({ id_schedule: data_row.original.id_schedule });
       this.setState({ start_time: data_row.original.Hora_Inicio });
       this.setState({ end_time: data_row.original.Hora_Fin });
       this.setState({ window_status: data_row.original.window_status });
       
       this.setState({ register: false });
       this.onOpenModal();
    } 

    handleNewPickup() {
      this.setState({ id_pickup: "" });
      this.setState({ register: true });
      this.onOpenModal();
   } 
    
    handlerDeletePickup(data_row) {
      //console.log(data_row);
      Swal.fire({
        title: 'Confirmar',
        text: '¿Desea eliminar la ventana de entrega?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', 
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
     }).then((result) => {
        if(result.value){
            this.onCloseModal();
            this.onClosePickupModal();
            var user = AuthService.getCurrentUser();

            ApiService.deletePickupWindow(user.token,data_row.original.id).then(
                (res) => {
                    if(res && res.status == 'success'){
                        Swal.fire("Hecho!", "Ventana de entrega eliminada!", "success");
                    }
                },
                error => {
                }
              );
       }
     })
   } 

   handlerStatusPickup(data_row) {
    //console.log(data_row);
    Swal.fire({
      title: 'Confirmar',
      text: '¿Desea actualizar la ventana de entrega?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33', 
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
   }).then((result) => {
      if(result.value){
          this.onCloseModal();
          this.onClosePickupModal();
          var user = AuthService.getCurrentUser();

          ApiService.updateStatusPickupWindow(user.token,data_row.original.id).then(
              (res) => {
                  if(res && res.status == 'success'){
                      Swal.fire("Hecho!", "Ventana de entrega actualizada!", "success");
                  }
              },
              error => {
              }
            );
     }
   })
 }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onOpenPickupModal = () => {
      this.setState({ openPickupModal: true });
    };

    onClosePickupModal = () => {
        this.setState({ openPickupModal: false });
    };

    handleTemplateChange = selectedTemplateOption => {
      this.setState(
        { selectedTemplateOption },
        () => console.log(`Option selected:`, this.state.selectedTemplateOption)
      );
    };


    onChangeStartTime(options) {

      this.setState({ start_hour: options.hour, start_minute: options.minute });
      var timeString = options.hour + ':' + options.minute;
      //console.log(timeString);
      this.setState({start_time: timeString});
  
    }

    onChangeEndTime(options) {
      
      this.setState({ end_hour: options.hour, end_minute: options.minute });
      var timeString = options.hour + ':' + options.minute;
      
      this.setState({end_time: timeString});
      
    }
   
    onFocusChange(focusStatue) {
      // do something
    }

    render() {
        const { open,openPickupModal } = this.state;
        // const { selectedTemplateOption } = this.state;
        // const format = 'HH:mm';
        return (
            <Fragment>
                <Breadcrumb title="Ventanas de Entrega" parent="Digital" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Ventanas de Entrega</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{this.state.register?'Añadir Ventana de Entrega':'Editar Ventana de Entrega'}</h5>
                                            </div>
                                            <Form 
                                            id="frmCateg"
                                            encType="multipart/form-data" 
                                            method="POST"
                                            ref={c => {this.form = c;
                                            }}>
                                            <div className="modal-body" style={{ width: '20.5rem' }}>
                                                    <div className="form-group" hidden="1">
                                                        <Input 
                                                        name="id_schedule" 
                                                        value={this.state.id_schedule} 
                                                        type="text" 
                                                        className="form-control" 
                                                        />
                                                        <Input 
                                                        name="id_pickup" 
                                                        value={this.state.id_pickup} 
                                                        type="text" 
                                                        className="form-control" 
                                                        />
                                                    </div>
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                      <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Hora Inicio :</label><br></br>
                                                        <TimePicker2
                                                          onFocusChange={this.onFocusChange.bind(this)}
                                                          onTimeChange={this.onChangeStartTime}
                                                          time={this.state.start_hour && this.state.start_minute ? `${this.state.start_hour}:${this.state.start_minute}` : null}
                                                        />
                                                    </div>
                                                      </div>
                                                      <div className="col-md-12">
                                                        <div className="form-group">
                                                          <label htmlFor="recipient-name" className="col-form-label" >Hora Fin :</label><br></br>
                                                          <TimePicker2
                                                            onFocusChange={this.onFocusChange.bind(this)}
                                                            onTimeChange={this.onChangeEndTime}
                                                            time={this.state.end_hour && this.state.end_minute ? `${this.state.end_hour}:${this.state.end_minute}` : null}
                                                          />
                                                        </div>
                                                      </div>
                                                     
                                                    </div>
                                                    
                                                    
                                            </div>
                                            <div className="modal-footer">
                                            <span
                                                onClick={() => this.state.register?this.handleRegister():this.handleUpdate()}
                                            >
                                                <span 
                                                        className="btn btn-primary"  
                                                        disabled={this.state.loading}
                                                        >
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Guardar</span>
                                                </span> 
                                            </span>
                                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Cancelar</button>
                                            </div>
                                            {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                                </div>
                                            </div>
                                            )}
                                            <CheckButton
                                            style={{ display: "none" }}
                                            ref={c => {
                                                this.checkBtn = c;
                                            }}
                                            />
                                            </Form>
                                        </Modal>

                                      <Modal open={openPickupModal} onClose={this.onClosePickupModal} >
                                        <div className="modal-header">
                                        <h5 className="modal-title f-w-600" id="exampleModalLabel2">Ventanas de entrega - {this.state.day}</h5>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                            <button style={{marginBottom: '1rem'}} type="button" className="btn btn-secondary" onClick={this.handleNewPickup} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Añadir</button>
                                            {this.renderPickupResults()}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                        </div>
                                      </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                        {this.renderResults()}
              
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_pickup_windows
