import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerSubCategDatatable from '../common/ver-sub-categ-datatable';
import Modal from 'react-responsive-modal';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

// image import
import color_fondo1 from '../../assets/images/example/color_fondo1.png'
import color_fondo2 from '../../assets/images/example/color_fondo2.png'
import color_nombre from '../../assets/images/example/color_nombre.png'
import color_texto from '../../assets/images/example/color_texto.png'
import imagen_categoria from '../../assets/images/example/imagen_categoria.png'
import fondo_sub_categoria from '../../assets/images/example/fondo_sub_categoria.png'

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

export class Ver_sub_category extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);
        this.onChangeTitleColor = this.onChangeTitleColor.bind(this);
        this.onChangeTextColor = this.onChangeTextColor.bind(this);
        this.onChangeColor2 = this.onChangeColor2.bind(this);
        this.onChangeOrder = this.onChangeOrder.bind(this);
        this.onChangeBackground = this.onChangeBackground.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        //this.handleFile = this.handleFile.bind(this);
        this.handler = this.handler.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.state = {
            selectedCategOption: null,
            open: false,
            open_example: false,
            register: true,
            id: "",
            name: "",
            color: "",
            text_color: "",
            title_color: "",
            color_2: "",
            errors: {},
            selectedTemplateOption: null,
            data_template: [
              {value: 'TEMPLATE1',label: 'Plantilla 1'},
              {value: 'TEMPLATE2',label: 'Plantilla 2'},
              {value: 'TEMPLATE3',label: 'Plantilla 3'},
            ],
            status: "ACTIVATED",
            background_url: "",
            file: null,
            loading: false,
            message: "",
            results: [],
            file_backup:null,
            flag_validate_image: false,
            data_categ_parent: [],
            temp: [],
            img_example: "",
            back_image: null,
            back_image_backup:null,
            order: null,
        };
    }

    handler(data_row) {
        //console.log("row_category",data_row.original);
        this.setState({ name: data_row.original.data.name });
        this.setState({ color: data_row.original.data.color });
        this.setState({ color_2: data_row.original.data.color_2 });
        this.setState({ text_color: data_row.original.data.text_color });
        this.setState({ title_color: data_row.original.data.title_color });
        this.setState({ order: data_row.original.data.order });
        this.setState({ id: data_row.original.data.id });
        this.setState({ status: data_row.original.data.status });
        this.setState({ register: false });
        this.setState({ file: {name : data_row.original.data.background_url} });
        this.setState({ file_backup: data_row.original.data.background_url });
        this.setState({ back_image: {name : data_row.original.data.back_image} });
        this.setState({ back_image_backup: data_row.original.data.back_image });
        for (let i = 0; i < this.state.data_template.length; i++){
          if(this.state.data_template[i]['value'] === data_row.original.data.template){
              this.handleTemplateChange(this.state.data_template[i])
          }
        }

        for (let i = 0; i < this.state.data_categ_parent.length; i++){
          if(this.state.data_categ_parent[i]['value'] === data_row.original.id_parent){
              this.handleCategChange(this.state.data_categ_parent[i])
          }
        }
        this.setState({ open: true });
    }

    deleteCategory(data_row) {
      //console.log(data_row)
      Swal.fire({
          title: 'Confirmar',
          text: '¿Desea eliminar '+ data_row.original.Nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33', 
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
    }).then((result) => {
       if(result.value){
           /*let data = myData;
           data.splice(data_row.index, 1);
           this.setState({ myData: data });
           this.deleteUser(row)*/
           var user = AuthService.getCurrentUser();
           ApiService.deleteCategory(user.token,data_row.original.id).then(
               (res) => {
                   toast.success("Categoría eliminada!");
                   this.getDataCateg();
                   //this.props.history.push("/category");
                   //window.location.reload();
               },
               error => {
               const resMessage =
                   (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                   error.message ||
                   error.toString();
               }
           )
      }
    })
   }


    onChangeFilter(e) {

      this.setState({
          filter: e.target.value
      }, function () {
          console.log('');
      });

      const val = e.target.value.toLowerCase();
      //console.log(event);
      if (this.state.temp.length != 0) {
        var columns = Object.keys(this.state.temp[0]);
        //Los botones de acciones se pintan despues
        columns.splice(columns.length);
        //columns.splice(columns.length - 1);
  
        if (!columns.length)
          return;
        const rows = this.state.temp.filter(function (d) {
          for (let i = 0; i <= columns.length; i++) {
            let column = columns[i];
            if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
              return true;
            }
          }
        });
        //console.log('rows',rows)
        this.setState({
          results: rows
      }, function () {
          //console.log('results',this.state.results);
          //console.log('lenght',this.state.results.length)
          this.renderResults(this.state.results.length);
      });
        /*this.Inventarios = rows;
        let articulosAlmacenes;
        articulosAlmacenes = { 'Articulos': this.Inventarios }
        this.buildpurchaseForm(articulosAlmacenes);*/
      }



      e.preventDefault();
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
      }
    
    onChangeColor(e) {
        this.setState({
            color: e.target.value
        });
    }

    onChangeTitleColor(e) {
      this.setState({
          title_color: e.target.value
      });
    }

    onChangeTextColor(e) {
      this.setState({
          text_color: e.target.value
      });
    }

    onChangeColor2(e) {
      this.setState({
          color_2: e.target.value
      });
    }

    onChangeOrder(e) {
      this.setState({
          order: e.target.value
      });
    }

    onChangeBackground(e) {
        this.setState({
            background_url: e.target.value
        });
    }

    onChangeStatus(e) {
        this.setState({
            status: e.target.value
        });
      }

    handleFile(e){
        let file = e.target.files[0];
        this.setState({file: e.target.files[0]}, () => {
           // console.log('state', this.state.file);
        })
        //this.setState(() => ({ file })); 
        /*this.setState({
            file: e.target.files[0]
        });*/
        //console.log(this.state.file);
    }

    handleCategChange = selectedCategOption => {
      this.setState(
        { selectedCategOption },
        () => console.log('')
      );
    };

    componentWillMount() {
        this.getDataCateg();
    }

    clearInputs() {
      this.setState({
          register: true,
          id: "",
          name: "",
          color: "",
          color_2: "",
          title_color: "",
          text_color: "",
          status: "ACTIVATED",
          background_url: "",
          file: null,
          loading: false,
          message: "",
          //results: [],
          file_backup:null,
          flag_validate_image: false,
          back_image: null,
          back_image_backup: null,
      });
    }

    showStatus(results){
    // Aplicamos un fix si no devuelve una imagen 
    let url_image = `${process.env.REACT_APP_URL_FILES}/images/categories/`;
		for (let i = 0; i < results.length; i++){
			if( (results[i]['status'] === 'ACTIVATED') ){
				results[i]['estado'] = <i className="fa fa-circle font-success f-12"/>;
			}else{
				results[i]['estado'] = <i className="fa fa-circle font-danger f-12"/>;
      }
      results[i]['imagen'] = results[i]['background_url']?<img src={url_image + results[i]['background_url']} style={{width:50,height:50}}/>:results[i]['back_image']?<img src={url_image + results[i]['back_image']} style={{width:50,height:50}}/>:'';
		}
		return results;
	}

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        ApiService.getCategories(user.token).then(
            (res) => {
              if(res && res.status == 'success'){
                let itemsCategories = []
                this.showStatus(res.data).map((row,index) =>{
                  if(row.id_parent != 0){
                      itemsCategories.push(row);
                  }
                })

                const items = itemsCategories.map(function(row) {
                  return { 
                    '#': row.id,
                    Imagen: row.imagen, 
                    id: row.id, 
                    Nombre : row.name, 
                    //Color : row.color, 
                    Estado : row.estado, 
                    status : row.status,
                    background_url : row.background_url,
                    //Plantilla: row.template,
                    Categoría: row.Parent.name,
                    id_parent: row.id_parent,
                    data: row
                  }
               })

              let items_parent = [];
              //let array_data = {};
              //array_data['value'] = 0;
              //array_data['label'] = 'Parent';
              //items_parent.push(array_data);
              for (let i = 0; i < res.data.length; i++) {
                if(res.data[i]['id_parent'] == 0){
                  let array_data = {};
                  array_data['value'] = res.data[i]['id'];
                  array_data['label'] = res.data[i]['name'];
                  items_parent.push(array_data);
                }
              }

              this.setState({ data_categ_parent: items_parent });

              this.setState({ results:items });
              this.setState({ temp:items });
              this.renderResults(this.state.results.length);
              //this.componentShouldUpdate()
              }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderResults(length){
		return this.state.results.length === 0 
			? 'No hay datos.'
			: <VerSubCategDatatable
                    multiSelectOption={false}
                    myData={this.state.results}
                    pageSize={length<=100?length:100}
                    pagination={true}
                    class="-striped -highlight"
                    handler = {this.handler}
                    deleteCategory = {this.deleteCategory}
                />
	}


    handleRegister(e) {
        //e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();

        /*if(!this.state.selectedTemplateOption){
          this.setState({
              errors: {template : "Error"}
          }) 
        }else{
            this.setState({
                errors: {template : ""}
            }) 
        }*/
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let formdata = new FormData()
            formdata.append('name', this.state.name)
            formdata.append('color', this.state.color)
            formdata.append('color_2', this.state.color_2)
            formdata.append('order', this.state.order)
            formdata.append('text_color', this.state.text_color)
            formdata.append('title_color', this.state.title_color)
            formdata.append('background_url', this.state.file)
            formdata.append('back_image', this.state.back_image)
            formdata.append('status', this.state.status)
            //formdata.append('template', this.state.selectedTemplateOption.value)
            //formdata.append('template', '')
            if(!this.state.selectedCategOption){
              formdata.append('id_parent', 0)
            }else{
              formdata.append('id_parent', this.state.selectedCategOption.value)
            }
            ApiService.postCategory(user.token,formdata).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Categoría creada!");
                  this.clearInputs();
                  this.getDataCateg();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

      handleUpdate(e) {
        //e.preventDefault();
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let formdata = new FormData()
            formdata.append('id_category', this.state.id)
            formdata.append('name', this.state.name)
            formdata.append('color', this.state.color)
            formdata.append('color_2', this.state.color_2)
            formdata.append('order', this.state.order)
            formdata.append('text_color', this.state.text_color)
            formdata.append('title_color', this.state.title_color)
            if(this.state.file_backup !== this.state.file.name)
            {
              formdata.append('background_url', this.state.file)
            }
            if(this.state.back_image_backup !== this.state.back_image.name)
            {
              formdata.append('back_image', this.state.back_image)
            }
            formdata.append('status', this.state.status)
            //formdata.append('template', this.state.selectedTemplateOption.value)
            formdata.append('template', '')
            if(!this.state.selectedCategOption){
              formdata.append('id_parent', 0)
            }else{
              formdata.append('id_parent', this.state.selectedCategOption.value)
            }
            ApiService.updateCategory(user.token,formdata).then(
            (res) => {
                if(res && res.status === 'success'){
                  toast.success("Categoría actualizada!");
                  this.clearInputs();
                  this.getDataCateg();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

    onOpenModal = () => {
        this.setState({ register: true });
        this.setState({ open: true });
    };

    onClearData = () => {
      this.clearInputs();
      this.onOpenModal();
    };

    onShowExample = (img) => {
      this.setState({ open_example: true });
      console.log(img)
      this.setState({
        img_example: img
      }, function () {
          console.log('');
      });
    };

    onCloseExample = () => {
      this.setState({ open_example: false });
    };


    onCloseModal = () => {
        this.setState({ open: false });
    };

    handleTemplateChange = selectedTemplateOption => {
      this.setState(
        { selectedTemplateOption },
        () => console.log('')
      );
    };

    componentWillReceiveProps(){
        this.getDataCateg()
    }


    render() {
        const { open, open_example } = this.state;
        const { selectedTemplateOption } = this.state;
        const { selectedCategOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Sub-Categorías" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Sub - Categorías</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        <button type="button" className="btn btn-secondary" onClick={this.onClearData} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Añadir Sub-Categoría</button>
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Añadir Sub-Categoría</h5>
                                            </div>
                                            <Form 
                                            id="frmCateg"
                                            //onSubmit={this.state.register?this.handleRegister:this.handleUpdate} 
                                            encType="multipart/form-data" 
                                            method="POST"
                                            ref={c => {this.form = c;
                                            }}>
                                            <div className="modal-body">
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="form-group" hidden="1">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Id:</label>
                                                        <Input 
                                                        name="id" 
                                                        value={this.state.id} 
                                                        type="text" 
                                                        className="form-control" 
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Nombre:</label>
                                                        <Input 
                                                        name="name" 
                                                        value={this.state.name} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeName}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de botones, etiquetas y fondo 1: <span onClick={(event) => this.onShowExample( 'color_fondo1')}><b>(?)</b></span></label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.color} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeColor}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de fondo 2 (Degradado): <span onClick={(event) => this.onShowExample( 'color_fondo2')}><b>(?)</b></span></label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.color_2} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeColor2}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de Nombre: <span onClick={(event) => this.onShowExample('color_nombre')}><b>(?)</b></span></label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.title_color} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeTitleColor}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Color de Texto (Etiquetas - Botones): <span onClick={(event) => this.onShowExample('color_texto')}><b>(?)</b></span></label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.text_color} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeTextColor}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>                                                
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Estado:</label>
                                                        <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label className="d-block d-block-status d-block-left">
                                                            <Input 
                                                                id="edo-ani"
                                                                name="status" 
                                                                value="ACTIVATED"
                                                                type="radio" 
                                                                className="radio_animated radio_status" 
                                                                checked={this.state.status === "ACTIVATED"}
                                                                onChange={this.onChangeStatus}
                                                                validations={[required]}
                                                            />
                                                            Activo
                                                                {/*<input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />*/}
                                                        </label>
                                                            <label className="d-block d-block-status" >
                                                            <Input 
                                                                id="edo-ani"
                                                                name="status" 
                                                                value="DESACTIVATED"
                                                                type="radio" 
                                                                className="radio_animated radio_status" 
                                                                checked={this.state.status === "DESACTIVATED"}
                                                                onChange={this.onChangeStatus}
                                                                validations={[required]}
                                                            />
                                                            Inactivo
                                                                {/*<input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />*/}
                                                        </label>
                                                        </div>
                                                    </div>
                                                    {/*<div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Plantilla:</label>
                                                        <Select
                                                            value={selectedTemplateOption}
                                                            onChange={this.handleTemplateChange}
                                                            options={this.state.data_template}
                                                        />
                                                        {
                                                        this.state.errors.template && !this.state.selectedTemplateOption?
                                                        <div className="alert alert-danger" role="alert" >Este campo es obligatorio!</div>
                                                        :''}
                                                        </div>*/}
                                                    <div className="form-group">
                                                        <label className="col-form-label">Categoría:</label>
                                                        {/*<select className="custom-select" required="">
                                                            <option value="">--Selecciona uno--</option>
                                                            {this.renderSelect()}
                                                        </select>*/}
                                                        <Select
                                                            value={selectedCategOption}
                                                            onChange={this.handleCategChange}
                                                            options={this.state.data_categ_parent}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name" className="col-form-label" >Orden:</label>
                                                        <Input 
                                                        name="color" 
                                                        value={this.state.order} 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={this.onChangeOrder}
                                                        validations={[required]}
                                                        />
                                                        {/*<input type="text" className="form-control" />*/}
                                                    </div>   
                                                    <div className="form-group">
                                                        <label htmlFor="message-text" className="col-form-label">Imagen (100 x 100): <span onClick={(event) => this.onShowExample('imagen_categoria')}><b>(?)</b></span></label>
                                                        <Input 
                                                        name="background_url" 
                                                        //value={this.state.file} 
                                                        type="file" 
                                                        className="form-control" 
                                                        accept="image/*"
                                                        validations={this.state.register?[required]:[]}
                                                        //onChange={this.onChangeBackground}
                                                        //onChange={(e)=>this.handleFile(e)}
                                                        onChange={(e) => {
                                                            this.setState({file: e.target.files[0]}, () => {
                                                                //console.log('state', this.state.file);
                                                            })
                                                            }}
                                                        />
                                                        {/*<input className="form-control" id="validationCustom02" type="file" />*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="message-text" className="col-form-label">Imagen de Fondo (280 x 280): <span onClick={(event) => this.onShowExample('fondo_sub_categoria')}><b>(?)</b></span></label>
                                                        <Input 
                                                        name="back_image" 
                                                        //value={this.state.file} 
                                                        type="file" 
                                                        className="form-control" 
                                                        accept="image/*"
                                                        validations={this.state.register?[required]:[]}
                                                        //onChange={this.onChangeBackground}
                                                        //onChange={(e)=>this.handleFile(e)}
                                                        onChange={(e) => {
                                                            this.setState({back_image: e.target.files[0]}, () => {
                                                                //console.log('state', this.state.file);
                                                            })
                                                            }}
                                                        />
                                                        {/*<input className="form-control" id="validationCustom02" type="file" />*/}
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                            <div className="modal-footer">
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/sub-category`}
                                                onClick={() => this.state.register?this.handleRegister():this.handleUpdate()}
                                            >
                                                <button 
                                                        className="btn btn-primary"  
                                                        disabled={this.state.loading}
                                                        >
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Guardar</span>
                                                </button> 
                                            </Link>
                                                {/*<button type="button" className="btn btn-primary" onClick={() => this.onCloseModal('VaryingMdo')}>Guardar</button>*/}
                                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal('VaryingMdo')}>Cancelar</button>
                                            </div>
                                            {this.state.message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                                </div>
                                            </div>
                                            )}
                                            <CheckButton
                                            style={{ display: "none" }}
                                            ref={c => {
                                                this.checkBtn = c;
                                            }}
                                            />
                                            </Form>
                                        </Modal>
                                        <Modal open={open_example} onClose={this.onCloseExample} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Ejemplo</h5>
                                            </div>
                                            <div className="modal-body">
                                              <div className="row">
                                                <div style={{ margin: '0 auto' }}>
                                                  <div className="form-group">
                                                  {
                                                    this.state.img_example === 'color_fondo1'?
                                                    <img className="blur-up lazyloaded" src={color_fondo1} alt="" />
                                                    :this.state.img_example === 'color_fondo2'?
                                                    <img className="blur-up lazyloaded" src={color_fondo2} alt="" />
                                                    :this.state.img_example === 'color_nombre'?
                                                    <img className="blur-up lazyloaded" src={color_nombre} alt="" />
                                                    :this.state.img_example === 'color_texto'?
                                                    <img className="blur-up lazyloaded" src={color_texto} alt="" />
                                                    :this.state.img_example === 'imagen_categoria'?
                                                    <img className="blur-up lazyloaded" src={imagen_categoria} alt="" />
                                                    :<img className="blur-up lazyloaded" src={fondo_sub_categoria} alt="" />
                                                  }
                                                  </div>
                                                </div>
                                              </div>
                                              </div>
                                              <div className="modal-footer" style={{ textAlign: 'center', display: 'block', fontWeight: 'bolder', fontSize: '20px' }}>
                                                {
                                                  this.state.img_example === 'imagen_categoria'?
                                                  <>Tamaño 100 x 100</>
                                                  :this.state.img_example === 'fondo_sub_categoria'?
                                                  <>Tamaño 280 x 280</>
                                                  :''
                                                }
                                            </div>
                                        </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">
                                      <div className="col-md-6 table-filter">
                                      <input 
                                          placeholder="Escriba para filtrar todas las columnas"
                                          name="filter" 
                                          type="text" 
                                          className="form-control" 
                                          autoComplete="off"
                                          onInput={this.onChangeFilter}
                                      />
                                      </div>                                      
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_sub_category
