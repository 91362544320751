import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export class VerGrammageDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData,
            showSwal: false,
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.myData != prevState.myData){
            this.setState({
                myData: prevProps.myData
            })
        }
    }


    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    deleteGrammage(row){
        var user = AuthService.getCurrentUser();
        ApiService.deleteGrammage(user.token,row.original.id).then(
        (res) => {
            toast.success("Gramaje eliminado!");
            //this.props.history.push("/category");
            //window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          /*this.setState({
            loading: false,
            message: resMessage
          });*/
        }
     )
    }

    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state

        const columns = [];
        var handleToUpdate  =   this.props.handler;
        for (var key in myData[0]) {

            let editable = this.renderEditable
            if (key === "Imagen") {
                editable = null;
            }
            if (key === "Estado") {
                editable = null;
            }
            if (key === "avtar") {
                editable = null;
            }
            if (key === "vendor") {
                editable = null;
            }
            if(key === "order_status"){
                editable = null;
            }
            if(key === "Nombre"){
                editable = null;
            }
            if(key === "Color"){
                editable = null;
            }
            if(key === "Plantilla"){
                editable = null;
            }

            if(key === "Categoría"){
                editable = null;
            }

            if(key === "Tipo"){
                editable = null;
            }

            if(key === "#"){
                editable = null;
            }

            if(key != "id" && key != "status" && key != "background_url" && key != 'id_parent' && key != 'data')
            {
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        accessor: key,
                        Cell: editable,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
            
        }

        if (multiSelectOption == true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } else {
            columns.push(
                {
                    Header: <b>Acción</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div>
                            <Link to={`#`} title="Eliminar" onClick={() => {
                                Swal.fire({
                                    title: 'Confirmar',
                                    text: '¿Desea eliminar '+ row.original.Nombre + '?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33', 
                                    confirmButtonText: 'Confirmar',
                                    cancelButtonText: 'Cancelar'
                                 }).then((result) => {
                                    if(result.value){
                                        let data = myData;
                                        data.splice(row.index, 1);
                                        this.setState({ myData: data });
                                        this.deleteGrammage(row)
                                   }
                                 })
                                /*if (window.confirm('Are you sure you wish to delete this item?')) {
                                    //console.log(row.original.id)
                                    let data = myData;
                                    data.splice(row.index, 1);
                                    this.setState({ myData: data });
                                    this.deleteGrammage(row)
                                    //toast.success("Categoría eliminada!")
                                }*/
                            }}>
                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }}
                                ></i>
                            </Link>
                            {/*<SweetAlert
                                show={this.state.showSwal}
                                title="Demo"
                                text={"¿Desea eliminar " + row.original.Nombre + "?"}
                                showCancelButton= "true"
                                confirmButtonColor= "#3085d6"
                                cancelButtonColor= "#d33"
                                confirmButtonText = 'Confirmar'
                                cancelButtonText = 'Cancelar'
                                onConfirm={() => 
                                    {
                                        let data = myData;
                                        data.splice(row.index, 1);
                                        this.setState({ myData: data });
                                        this.deleteGrammage(row)
                                        this.setState({ showSwal: false })
                                    }
                                }
                            />*/}

                        <Link to={`#`} title="Editar" onClick={() => handleToUpdate(row)}
                        ><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></Link>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )
        }

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        )
    }
}

export default VerGrammageDatatable
