import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerCashUserDatatable from '../common/ver-cash-user-datatable';
import Modal from 'react-responsive-modal';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

export class Ver_all_cash_user extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeMount = this.onChangeMount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            selectedCategOption: null,
            open: false,
            name: '',
            last_name: '',
            id: '',
            amount: 0,
            errors: {},
            loading: false,
            message: "",
            results: [],
            current_credit: 0,
            paymentDateFormat: "",
        };
    }

    handleChange(date) {
      this.setState({
        paymentDate: date,
        paymentDateFormat: Moment(date).format('DD-MM-YYYY')
      });
    }

   
    onChangeMount(e) {
        this.setState({
            mount: e.target.value
        });
      }

    componentWillMount() {
       
      this.getDataCash();
       
    }

    
  
    getDataCash(){

      var user = AuthService.getCurrentUser();
      let data_user = {
        'day': this.state.paymentDate,
      }
      
      ApiService.getReportCashUser(user.token,data_user).then(
          (res) => {
              if(res && res.status == 'success'){
                  //console.log(res.data);
                  const items = res.data.map(function(row) {
                    return { 
                        Id: row.id, 
                        Usuario: row.User.full_name,
                        Tipo : row.operation_type,
                        Fecha : Moment(row.approval_date).format('DD-MM-YYYY'),
                        Detalle : row.detail,
                        Monto : parseInt(row.amount_cash, 10), 

                    }
                 })
                this.setState({ results:items });
                /*
                this.setState({ 
                  current_credit: row.amount_cash ? row.amount_cash : 0
                });
                */
                this.renderResults();
              }
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
  }

    clearInputs() {
      this.setState({
          amount: "",
      });
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerCashUserDatatable
                    multiSelectOption={false}
                    myData={this.state.results}
                    pageSize={this.state.results.length<=10?this.state.results.length:10}
                    pagination={true}
                    class="-striped -highlight"
                    handler = {this.handler}
                />
	}


    handleRegister(e) {
        //e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.template) {
            this.onCloseModal('VaryingMdo');
            var user = AuthService.getCurrentUser();
            let data_user = {
              'id_user': this.state.id,
              'cash': this.state.mount,
          }
            ApiService.postCashUser(user.token,data_user).then(
            (res) => {
                if(res && res.status == 'success'){
                  toast.success("Monto agregado!");
                  this.getDataCash(this.state.id);
                  this.clearInputs();
                  //this.props.history.push("/category");
                  //window.location.reload();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

      

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

     componentWillReceiveProps(){
        this.getDataCash(this.props.match.params.id);
    }


    render() {
        const { open } = this.state;
        const { selectedTemplateOption } = this.state;
        const { selectedCategOption } = this.state;

        return (
            <Fragment>
                <Breadcrumb title={`Historial de movimientos de ${this.state.paymentDateFormat}`} parent="Digital" /> 
                {/* <!-- Container-fluid starts--> */}
                
                <div className="container-fluid">
                <div className='row'>
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body">
                          <div className="form-group row">
                            
                            <label class="col-xl-1 col-md-4">Fecha: </label>

                            <div className="col-xl-2" style={{ display: 'grid' }}>
                            <DatePicker
                                selected={this.state.paymentDate}
                                onChange={this.handleChange}
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            <div className="col-xl-3 ml-3" style={{ display: 'grid' }}>
                            <button
                              style={{ height: 'fit-content' }}
                              className="btn btn-primary"
                              //disabled={this.state.loading}
                              onClick={() => this.getDataCash()}
                            >
                            Filtrar
                            </button>
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                
                                <div className="card-body">
                                    
                                    <div id="basicScenario" className="product-physical">
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
            </Fragment>
        )
    }
}

export default Ver_all_cash_user;
