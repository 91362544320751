import React, { useMemo, useState } from "react";
import ReactTable from "react-table";
import FilterComponent from "./Filter.component";

const Table = ({ data, columns, filterText, setFilterText, filteredItems }) => {
  //const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  return <ReactTable columns={columns} data={data} />;
};

export default Table;
