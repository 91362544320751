import React, { Component,Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../../common/dropzone'
import AuthService from "../../services/auth.service";
import ApiService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

const items = [];

export class Ver_import_tabla_pro extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        
        this.importU = {};
        this.FileData = null;
        this.state = {
        }
    }

    componentWillMount() {
      
      this.importU = JSON.parse(localStorage.getItem('importU'));
      console.log('componentWillMount tabla this.importU', this.importU);
    }

    handleRegister(e) {
        e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0) {
            var user = AuthService.getCurrentUser();

            const formData = new FormData();
  

            console.log(this.FileData);
            if(this.FileData != null && this.idCadena!=0){
            formData.append("file", this.FileData, this.FileData.name);
            }else{

                // if(this.idCadena==0){
                //     Swal.fire('', 'Debe seleccionar una cadena.', 'error') 
                // }else if(this.FileData == null){
                //     Swal.fire('', 'Debe seleccionar un archivo excel.', 'error')
                // }
                return false

            }

            ApiService.postUserImport(user.token,formData).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        toast.success("Usuarios importados!");
                        this.props.history.push("/product-list");
                        //window.location.reload();
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
    }
  
    onFileSelect = (event) => { 
      if (event.target.files.length > 0) {
       
      this.FileData = event.target.files[0];
      //let file = event.target.files[0];
      } else {
        this.FileData = null;
      }
    }

    render() {

        return (
            <Fragment>
                <Breadcrumb title="Usuarios Importados" parent="Digital" />
                <div className="container-fluid">
                    <Form
                        onSubmit={this.handleRegister} 
                        encType="multipart/form-data" 
                        method="POST"
                        ref={c => {this.form = c;
                    }}>
                    <div className="row product-adding">

                      
                        <div className="col-xl-12">
                        {this.importU.rowsDataOk.length == 0 
                            ? ''
                            : 
                            <div className="card">
                                <div className="card-header">
                                    <h5></h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation prod-block-right">
                                        <div className="form-group">
                                            <Fragment>
                                                <table className=" table table-striped table-no-bordered table-hover " id="wrapperTable" cellSpacing="0" width="100%" style={{  width:'100%'}} >
                                                  <thead>
                                                      <tr>
                                                        <th>Estado</th>
                                                        <th>Alias</th>
                                                        <th>Nombres</th>
                                                        <th>Apellidos</th>
                                                        <th>TipoDocumento</th>
                                                        <th>NroDocumento</th>
                                                        <th>Celular</th>
                                                        <th>Correo</th>
                                                        <th>Rol</th>
                                                        <th>Embajador</th>
                                                        <th>Av_calle</th>
                                                        <th>Direccion</th>
                                                        <th>Numero</th>
                                                        <th>Interior</th>
                                                        <th>Distrito</th>
                                                        <th>UrlGoogle</th>
                                                        <th>Delivery</th>
                                                      </tr>
                                                  </thead>
                                                  {
                                                    
                                                    this.importU.rowsDataOk.map((itemOk,i) => (
                                                      <tbody key={i}>
                                                          <tr>
                                                            <th>Ok</th>
                                                            <th>{itemOk.colAlias}</th>
                                                            <th>{itemOk.colNombres}</th>
                                                            <th>{itemOk.colApellidos}</th>
                                                            <th>{itemOk.colTipoDocumento}</th>
                                                            <th>{itemOk.colNroDocumento}</th>
                                                            <th>{itemOk.colCelular}</th>
                                                            <th>{itemOk.colCorreo}</th>
                                                            <th>{itemOk.colRol}</th>
                                                            <th>{itemOk.colEmbajador}</th>
                                                            <th>{itemOk.colAv_calle}</th>
                                                            <th>{itemOk.colDireccion}</th>
                                                            <th>{itemOk.colNumero}</th>
                                                            <th>{itemOk.colInterior}</th>
                                                            <th>{itemOk.colDistrito}</th>
                                                            <th>{itemOk.colUrlGoogle}</th>
                                                            <th>{itemOk.colDelivery}</th>
                                                          </tr>
                                                      </tbody>
                                                    ))  

                                                  }
                                                  
                                                </table>
                                                <ToastContainer />
                                            </Fragment>
                                          
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        }
                        {this.importU.rowsDataError.length == 0 
                            ? ''
                            : 
                            <div className="card">
                                <div className="card-header">
                                    <h5></h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation prod-block-right">
                                        <div className="form-group">
                                            <Fragment>
                                                <table className=" table table-striped table-no-bordered table-hover " id="wrapperTable" cellSpacing="0" width="100%" style={{  width:'100%'}} >
                                                  <thead>
                                                      <tr>
                                                        <th>Estado</th>
                                                        <th>Alias</th>
                                                        <th>Nombres</th>
                                                        <th>Apellidos</th>
                                                        <th>TipoDocumento</th>
                                                        <th>NroDocumento</th>
                                                        <th>Celular</th>
                                                        <th>Correo</th>
                                                        <th>Rol</th>
                                                        <th>Embajador</th>
                                                        <th>Av_calle</th>
                                                        <th>Direccion</th>
                                                        <th>Numero</th>
                                                        <th>Interior</th>
                                                        <th>Distrito</th>
                                                        <th>UrlGoogle</th>
                                                        <th>Delivery</th>
                                                      </tr>
                                                  </thead>
                                                  {
                                                    
                                                    this.importU.rowsDataError.map((itemError,i) => (
                                                      <tbody key={i}>
                                                          <tr>
                                                            <th>{itemError.error[0]}</th>
                                                            <th>{itemError.colAlias}</th>
                                                            <th>{itemError.colNombres}</th>
                                                            <th>{itemError.colApellidos}</th>
                                                            <th>{itemError.colTipoDocumento}</th>
                                                            <th>{itemError.colNroDocumento}</th>
                                                            <th>{itemError.colCelular}</th>
                                                            <th>{itemError.colCorreo}</th>
                                                            <th>{itemError.colRol}</th>
                                                            <th>{itemError.colEmbajador}</th>
                                                            <th>{itemError.colAv_calle}</th>
                                                            <th>{itemError.colDireccion}</th>
                                                            <th>{itemError.colNumero}</th>
                                                            <th>{itemError.colInterior}</th>
                                                            <th>{itemError.colDistrito}</th>
                                                            <th>{itemError.colUrlGoogle}</th>
                                                            <th>{itemError.colDelivery}</th>
                                                          </tr>
                                                      </tbody>
                                                    ))  

                                                  }
                                                  
                                                </table>
                                                <ToastContainer />
                                            </Fragment>
                                          
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        } 
                            
                        </div>
                      
                    </div>
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                    />
                    </Form>
                                       
                </div>
            </Fragment>
        )
    }
}

export default Ver_import_tabla_pro
