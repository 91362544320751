import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import { useParams, useHistory } from 'react-router-dom';
import VerAddressForm from './ver-address-form';
import Swal from 'sweetalert2';

const Ver_create_address = () => {
  const history = useHistory();
  const [token, setToken] = useState(null);
  const { userId, addressId } = useParams();
  const [address, setAddress] = useState(null);

  useEffect(() => {
    (async () => {
      let user = AuthService.getCurrentUser();
      let response = await ApiService.getUserAddress(addressId, user.token);
      setAddress(response);
      setToken(user.token);
    })();
  }, []);

  const onHandlerSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await ApiService.updateAddress(token, addressId, {
        ...values,
        user_id: userId,
      });

      setSubmitting(false);

      if (response.status == 422) {
        return setErrors(response.data);
      }

      Swal.fire(response.data.message, '', 'success');
    } catch (e) {
      setSubmitting(false);
      console.log(e);
      Swal.fire('Error', e.message, 'error');
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Modificar dirección" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">{/*<h5> Crear Usuario</h5>*/}</div>
              <div className="card-body">
                <VerAddressForm
                  tokenJwt={token}
                  onHandlerSubmit={onHandlerSubmit}
                  address={address}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Ver_create_address;
