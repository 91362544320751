import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/ver-category';
import VerAddressUserDatatable from '../common/ver-address-user-datatable';
import Modal from 'react-responsive-modal';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
registerLocale('es', es);

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

export class Ver_order_collection extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeDelivery = this.onChangeDelivery.bind(this);
    this.onChangeReference = this.onChangeReference.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handlerEdit = this.handlerEdit.bind(this);
    this.handlerSeeImage = this.handlerSeeImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      selectedCategOption: null,
      open: false,
      name: '',
      last_name: '',
      state: '',
      total: 0,
      total_payment: 0,
      pending_payment: 0,
      cash: 0,
      delivery: 0,
      id: '',
      amount: 0,
      errors: {},
      loading: false,
      message: '',
      results: [],
      current_credit: 0,
      delivery: 0,
      reference: '',
      value: 0,
      id_address: null,
      paymentDate: new Date(),
      file_image: null,
      voucher_image: '',
      openModal: false,
      charge: {},
      status_charge: ''
    };
  }

  handleChange(date) {
    this.setState({
      paymentDate: date,
    });
  }

  onChangeDelivery(e) {
    this.setState({
      delivery: e.target.value,
    });
  }

  onChangeReference(e) {
    this.setState({
      reference: e.target.value,
    });
  }

  onChangeValue(e) {
    this.setState({
      value: e.target.value,
    });
  }

  componentWillMount() {
    var id_purchase = this.props.match.params.id;
    this.setState({ id: id_purchase });
    if (id_purchase) {
      this.getDataPurchaseCharge(id_purchase);
    }
    console.log("hdhdhd")
  }

  getUser(id_user) {
    var user = AuthService.getCurrentUser();
    ApiService.getUserDetails(user.token, id_user).then(
      (res) => {
        //console.log(res)
        if (res && res.status === 'success') {
          //console.log(res.data);
          this.setState({ name: res.data.name ? res.data.name : '' });
          this.setState({
            last_name: res.data.last_name ? res.data.last_name : '',
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getDataPurchaseCharge(id_purchase) {
    var user = AuthService.getCurrentUser();
    ApiService.getDataPurchaseCharge(user.token, id_purchase).then(
      (res) => {
        if (res && res.status == 'success') {
          
          var totalPurchase = (res.data[0].delivery ? res.data[0].delivery : 0) * 1 + res.data[0].total * 1;

          var totalPayment = (res.data[0].payment_amount ? res.data[0].payment_amount : 0) * 1;

          this.setState({
            state: res.data[0].status_charge,
            name: res.data[0].User.full_name,
            status_charge: res.data[0].status_charge
          });
          this.setState({ results: [] });
          //console.log(res.data);
          const items = res.data[0].Charge.map(function (row) {
            let rowJson = row;
            rowJson.type = 'CHARGE';
            //totalPayment = totalPayment * 1 + (row.value ? row.value : 0) * 1;
            //totalPayment = totalPayment;
            return {
              Id: row.id,
              Monto: row.value ? row.value : '',
              Referencia: row.reference ? row.reference : '',
              ['Fecha Pago']: row.date_payment
                ? Moment(row.date_payment).format('DD/MM/YYYY')
                : '',
              ['Fuente']: row.source,
              //Delivery : row.delivery?row.delivery:'',
              data: rowJson,
            };
          });
          //var totalPending = totalPurchase - totalPayment;
          
          //var totalPending = res.data[0].total + res.data[0].delivery;
          var totalPending = res.data[0].pending_amount;

          this.setState({
            total: res.data[0].total,
            total_payment: totalPayment,
            pending_payment: totalPending,
          });
          this.setState({ cash: res.data[0].cash, delivery: res.data[0].delivery });
          this.setState({ results: items });
          //this.setState({current_credit: res.user.Internal_accounts_summary?res.user.Internal_accounts_summary.amount_cash:0});
          this.renderResults();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  clearInputs() {
    this.setState({
      //delivery: "",
      reference: '',
      value: '',
      file_image: null,
      //paymentDate: "",
      loading: false,
      message: '',
    });
  }

  setCharge = (charge) => {
    const newCharge = {
      ...charge,
      date_payment: Moment(charge.date_payment).toDate(),
    };

    this.setState({ charge: newCharge });
  };

  renderResults() {
    return this.state.results.length === 0 ? (
      ''
    ) : (
      <VerAddressUserDatatable
        multiSelectOption={false}
        myData={this.state.results}
        pageSize={
          this.state.results.length <= 10 ? this.state.results.length : 10
        }
        pagination={true}
        class="-striped -highlight"
        handler={this.handler}
        handlerEdit={this.handlerEdit}
        handlerSeeImage={this.handlerSeeImage}
        openModal={() => this.setState({ openModal: true })}
        setCharge={this.setCharge}
      />
    );
  }

  handlerEdit(data_row) {
    console.log('handlerEdit--------', data_row);
    //return false

    var user = AuthService.getCurrentUser();

    Swal.fire({
      title: 'Confirmar',
      text: '¿Desea eliminar el pago?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        ApiService.deletePurchaseCharge(
          user.token,
          data_row.original.data.id
        ).then(
          (res) => {
            if (res && res.status == 'success') {
              toast.success('Pago eliminado!');
              this.getDataPurchaseCharge(this.state.id);
              //this.props.history.push("/category");
              //window.location.reload();
            } else {
              console.log('---else');
              Swal.fire('Aviso!', res.message, 'error');
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            /*this.setState({
              loading: false,
              message: resMessage
            });*/
          }
        );
      }
    });
  }

  handlerSeeImage(data_row) {
    console.log('handlerSeeImage--------', data_row);
    this.setState({
      voucher_image: data_row.original.data.voucher_image,
      open: true,
    });
  }

  updateCharge = async () => {
    try {
      console.log(this.state.charge);

      var user = AuthService.getCurrentUser();

      const { data } = await ApiService.updatePurchaseCharge(
        user.token,
        this.state.charge.id,
        this.state.charge
      );

      if (data && data.status == 'success') {
        await this.getDataPurchaseCharge(this.state.id);
        this.setState({ openModal: false });
        //toast.success('Pago registrado!');
        Swal.fire('Pago modificado!', '', 'success');
        
      } else {
        Swal.fire('Aviso!', data.message, 'error');
      }

      
    } catch (e) {
      console.log(e);
    }
  };

  handleUpdate(e) {
    //e.preventDefault();

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (
      this.checkBtn.context._errors.length === 0 &&
      !this.state.errors.template
    ) {
      this.onCloseModal('VaryingMdo');
      var user = AuthService.getCurrentUser();
      let select_date = Moment(this.state.paymentDate).format('YYYY-MM-DD');
      console.log(select_date);
      let data_user = {
        id_purchase: this.state.id,
        //'id_user': this.state.id,
        value: this.state.value,
        reference: this.state.reference,
        date_payment: select_date,
        voucher_image: this.state.file_image,
      };

      let formdata = new FormData();
      formdata.append('id_purchase', this.state.id);
      formdata.append('date_payment', select_date);
      formdata.append('reference', this.state.reference);
      formdata.append('value', this.state.value);
      formdata.append('voucher_image', this.state.file_image);

      // console.log(data_user);
      // return true;

      ApiService.postPurchaseCharge(user.token, formdata).then(
        async (res) => {
          if (res && res.status == 'success') {
            console.log('---if');
            await this.getDataPurchaseCharge(this.state.id);
            //toast.success('Pago registrado!');
            Swal.fire('Pago registrado!', '', 'success');
            
          } else {
            console.log('---else');
            Swal.fire('Aviso!', res.message, 'error');
          }

          this.setState({
            loading: false,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps() {
    this.getDataPurchaseCharge(this.props.match.params.id);
  }

  handleChangeStatusCharge = async () => {

    this.setState({
      message: '',
      loading: true,
    });
      var user = AuthService.getCurrentUser();
      //let formdata = new FormData();
      //formdata.append('id_purchase', this.state.id);
      //formdata.append('status_charge', this.state.status_charge)

      const data = {
        status_charge: this.state.status_charge,
        id_purchase: this.state.id
      }

      // console.log(data_user);
      // return true;

      ApiService.updatePurchaseStatusCharge(user.token, data).then(
        async (res) => {
          if (res && res.status == 'success') {
            console.log('---if');
            await this.getDataPurchaseCharge(this.state.id);
            //toast.success('Pago registrado!');
            Swal.fire('Estado modificado!', '', 'success');
            //toast.success('Pago actualizado!');
            //to={`${process.env.PUBLIC_URL}/order-collections/${this.state.id}`}

            //this.clearInputs();
            //this.props.history.push("/category");
            //window.location.reload();
          } else {
            //console.log(res);
            Swal.fire('Aviso!', res ? res.message : 'Error', 'error');
          }

          this.setState({
            loading: false,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    
  }

  render() {
    const { open } = this.state;
    const { selectedTemplateOption } = this.state;
    const { selectedCategOption } = this.state;

    let url_image = `${process.env.REACT_APP_URL_FILES}/images/voucher_image/`;

    return (
      <Fragment>
        <Breadcrumb title={`Registro de Pagos`} parent="Digital" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className='row'>
          <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                <div className="form-group row">
                  <div className="col-md-5" style={{ display: 'grid' }}>
                    <label>Referencia</label>
                    <select
                      name="name"
                      value={this.state.status_charge}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          status_charge: e.target.value 
                        })
                      }}
                      //validations={[required]}
                    >
                      <option value="POR_COBRAR">Por cobrar</option>
                      <option value="CANCELADO">Pagado</option>
                    </select>
                  </div>
                  <div className="col-md-5" style={{ display: 'grid' }}>
                  <button
                    style={{ height: 'fit-content' }}
                    className="btn btn-primary"
                    //disabled={this.state.loading}
                    onClick={() => this.handleChangeStatusCharge()}
                  >
                    {/*this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )*/}
                    <span>Guardar</span>
                  </button>
                  </div>
                </div>
                </div>
            </div>
          </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="btn-popup pull-right">
                    <Modal open={open} onClose={this.onCloseModal}>
                      <div className="modal-header">
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          Voucher
                        </h5>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <img
                              src={url_image + this.state.voucher_image}
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.onCloseModal('VaryingMdo')}
                        >
                          Cancelar
                        </button>
                      </div>

                      {this.state.message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {this.state.message}
                          </div>
                        </div>
                      )}
                    </Modal>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <div className="clearfix">
                    <Link to={`${process.env.PUBLIC_URL}/collections`}>
                      <span>
                        <i
                          className="fa fa-arrow-left"
                          style={{
                            width: 35,
                            fontSize: 20,
                            marginBottom: '20px',
                            color: 'rgb(14 14 14)',
                          }}
                        ></i>
                      </span>
                    </Link>
                  </div>
                  <div id="basicScenario" className="product-physical">
                    <div style={{ marginBottom: '15px' }}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Nro Orden:
                              </label>
                              {this.props.match.params.id}
                            </div>
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Cliente:
                              </label>
                              {this.state.name}
                            </div>
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Total:
                              </label>
                              {this.state.total}
                            </div>
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Delivery:
                              </label>
                              {this.state.delivery}
                            </div>
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Cash:
                              </label>
                              {this.state.cash}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Estado:
                              </label>
                              {this.state.state}
                            </div>
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Total Pagado:
                              </label>
                              {this.state.total_payment}
                            </div>
                            <div className="col-md-12">
                              <label
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '700',
                                }}
                              >
                                Total Pendiente:
                              </label>
                              {this.state.pending_payment}
                            </div>
                          </div>
                        </div>
                      </div>

                     
                    </div>
                    <Form
                      id="frmCateg"
                      //onSubmit={this.state.register?this.handleUpdate:this.handleUpdate}
                      encType="multipart/form-data"
                      method="POST"
                      ref={(c) => {
                        this.form = c;
                      }}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="form-group row">
                        <div className="col-md-5" style={{ display: 'grid' }}>
                          <label>Referencia</label>
                          <Input
                            name="name"
                            value={this.state.reference}
                            type="text"
                            className="form-control"
                            onChange={this.onChangeReference}
                            //validations={[required]}
                          />
                        </div>
                        <div className="col-md-5" style={{ display: 'grid' }}>
                          <label>Valor</label>
                          <Input
                            name="name"
                            value={this.state.value}
                            type="number"
                            className="form-control"
                            onChange={this.onChangeValue}
                            validations={[required]}
                            min="1"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-5" style={{ display: 'grid' }}>
                          <label>Voucher</label>
                          <Input
                            name="url"
                            //value={this.state.file}
                            type="file"
                            className="form-control"
                            accept="image/*"
                            //validations={[required]}
                            //onChange={this.onChangeBackground}
                            //onChange={(e)=>this.handleFile(e)}
                            onChange={(e) => {
                              this.setState(
                                { file_image: e.target.files[0] },
                                () => {
                                  //console.log('state', this.state.file);
                                }
                              );
                            }}
                          />
                        </div>
                        <div className="col-md-3" style={{ display: 'grid' }}>
                          <label>Fecha de Pago</label>
                          <DatePicker
                            selected={this.state.paymentDate}
                            onChange={this.handleChange}
                            locale="es"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="col-md-2" style={{ display: 'grid' }}>
                          
                          <button
                            style={{ height: 'fit-content' }}
                            className="btn btn-primary"
                            disabled={this.state.loading}
                            onClick={() => this.handleUpdate()}
                          >
                            {this.state.loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Guardar</span>
                          </button>
                          {/* </Link> */}
                        </div>
                      </div>
                      {this.state.message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {this.state.message}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: 'none' }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />
                    </Form>
                    {this.renderResults()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
        >
          <div className="modal-header">
            <h5 className="modal-title f-w-600" id="exampleModalLabel2">
              Orden n°
            </h5>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div className="product-physical">
                        <div className="form-group row">
                          <div className="col-md-6" style={{ display: 'grid' }}>
                            <label>Referencia</label>
                            <input
                              name="name"
                              value={this.state.charge.reference}
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  charge: {
                                    ...this.state.charge,
                                    reference: e.target.value,
                                  },
                                });
                              }}
                              //validations={[required]}
                              autoFocus
                            />
                          </div>
                          <div className="col-md-6" style={{ display: 'grid' }}>
                            <label>Valor</label>
                            <input
                              name="name"
                              value={this.state.charge.value}
                              type="number"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({
                                  charge: {
                                    ...this.state.charge,
                                    value: e.target.value,
                                  },
                                });
                              }}
                              validations={[required]}
                              min="1"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-6" style={{ display: 'grid' }}>
                            <label>Fecha de Pago</label>
                            <DatePicker
                              selected={this.state.charge.date_payment}
                              onChange={(date) => {
                                this.setState({
                                  charge: {
                                    ...this.state.charge,
                                    date_payment: date,
                                  },
                                });
                              }}
                              locale="es"
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          <div className="col-md-6" style={{ display: 'grid' }}>
                            <label>Estado</label>
                            <select
                              className="form-control"
                              name="state"
                              value={this.state.charge.state}
                              onChange={(e) => {
                                this.setState({
                                  charge: {
                                    ...this.state.charge,
                                    state: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="ACTIVO">Activo</option>
                              <option value="PENDIENTE_VERIFICACION">
                                Pendiente de verificación
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-md-3" style={{ display: 'grid' }}>
                            <button
                              //to={``}
                              className="btn btn-primary"
                              onClick={() => this.updateCharge()}
                              style={{
                                height: 'fit-content',
                                marginTop: '2rem',
                              }}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Guardar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer"></div>
        </Modal>
      </Fragment>
    );
  }
}

export default Ver_order_collection;
