import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import { withRouter } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

export class VerLoginTabset extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            activeShow: true,
            startDate: new Date(),
            email: "",
            password: "",
            loading: false,
            message: ""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    onChangeUsername(e) {
        this.setState({
            email: e.target.value
        });
      }
    
      onChangePassword(e) {
        this.setState({
          password: e.target.value
        });
      }
    
      handleLogin(e) {
        e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
    
        if (this.checkBtn.context._errors.length === 0) {
          AuthService.login(this.state.email, this.state.password).then(
            (res) => {
              if(res && res.status == 'success' && res.data.role == 'ADMIN')
              {
                this.props.history.push("/category");
                window.location.reload();
              }else{
                localStorage.removeItem("user");
                this.props.history.push("/login");
                window.location.reload();
              }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    routeChange = () => {
      console.log('cambio ', process.env.PUBLIC_URL);
        this.props.history.push(`${process.env.PUBLIC_URL}/category`);
      }
    render() {
        return (
            <div>
                <Fragment>
                    <Tabs>
                        <TabList className="nav nav-tabs tab-coupon" >
                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User />Login</Tab>
                            {/*<Tab className="nav-link" onClick={(e) => this.clickActive(e)}><Unlock />Register</Tab>*/}
                        </TabList>

                        <TabPanel>
                            <Form 
                            className="form-horizontal auth-form" 
                            onSubmit={this.handleLogin} 
                            ref={c => {this.form = c;
                            }}>
                                <div className="form-group">
                                    <Input 
                                    name="email" 
                                    value={this.state.email} 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Usuario" 
                                    id="exampleInputEmail1" 
                                    onChange={this.onChangeUsername}
                                    validations={[required]}
                                    />
                                </div>
                                <div className="form-group">
                                    <Input 
                                    name="password" 
                                    type="password" 
                                    className="form-control" 
                                    placeholder="Contraseña" 
                                    value={this.state.password}
                                    onChange={this.onChangePassword}
                                    validations={[required]}
                                    />
                                </div>
                                {/*<div className="form-terms">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                        <label className="d-block">
                                                    <input className="checkbox_animated" id="chk-ani2" type="checkbox" />
                                                        Recordar <span className="pull-right"> <a href="#" className="btn btn-default forgot-pass p-0">lost your password</a></span>
                                                </label>
                                    </div>
                                </div>*/}
                                <div className="form-button">
                                    <button 
                                    className="btn btn-primary"  
                                    disabled={this.state.loading}
                                    >
                                    {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Iniciar Sesión</span>
                                    </button>
                                </div>
                                {this.state.message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                    {this.state.message}
                                    </div>
                                </div>
                                )}
                                <CheckButton
                                style={{ display: "none" }}
                                ref={c => {
                                    this.checkBtn = c;
                                }}
                                />
                                {/*<div className="form-footer">
                                    <span>Or Login up with social platforms</span>
                                    <ul className="social">
                                        <li><a className="fa fa-facebook" href=""></a></li>
                                        <li><a className="fa fa-twitter" href=""></a></li>
                                        <li><a className="fa fa-instagram" href=""></a></li>
                                        <li><a className="fa fa-pinterest" href=""></a></li>
                                    </ul>
                                </div>*/}
                            </Form>
                        </TabPanel>
                        {/*<TabPanel>
                            <form className="form-horizontal auth-form">
                                <div className="form-group">
                                    <input required="" name="login[username]" type="email" className="form-control" placeholder="Username" id="exampleInputEmail12" />
                                </div>
                                <div className="form-group">
                                    <input required="" name="login[password]" type="password" className="form-control" placeholder="Password" />
                                </div>
                                <div className="form-group">
                                    <input required="" name="login[password]" type="password" className="form-control" placeholder="Confirm Password" />
                                </div>
                                <div className="form-terms">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                        <label className="d-block">
                                            <input className="checkbox_animated" id="chk-ani2" type="checkbox" />
                                            I agree all statements in <span><a href="">Terms &amp; Conditions</a></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit" onClick={() => this.routeChange()}>Register</button>
                                </div>
                                <div className="form-footer">
                                    <span>Or Sign up with social platforms</span>
                                    <ul className="social">
                                        <li><a className="fa fa-facebook" href=""></a></li>
                                        <li><a className="fa fa-twitter" href=""></a></li>
                                        <li><a className="fa fa-instagram" href=""></a></li>
                                        <li><a className="fa fa-pinterest" href=""></a></li>
                                    </ul>
                                </div>
                            </form>
                        </TabPanel>*/}
                    </Tabs>
                </Fragment>
            </div>
        )
    }
}

export default withRouter(VerLoginTabset)

